import * as domain from "./domain";
import { HGStore } from "./store/types";
import { reaction, comparer, runInAction, IReactionDisposer } from "mobx";
import * as useCases from "./use-cases/use-cases";
import { HGConnection } from "./domain/connection";
import * as firebase from "./api/firebase";
import * as hubspot from "./api/hubspot-api";
import _, { cloneDeep } from "lodash";
import {
  hsPropertyRequiresResolvingValues,
  hsPropertyValueResolvingType,
  idForValueResolvingSubscription,
  isHGObjectValueResolvingSubscription,
  makeResolvingValueSubscription,
  requiredResolvingValueSubscriptionsForHGObject,
  ValueResolvingSubscription,
} from "./domain/resolved-values";
import * as db from "./store/db";
import { DEV_CACHE_ENABLED } from "./config";

// TODO: #cache improvements to cache layer
// - need to expire cach items somewhow - cache is currently unbounded
// - need to support updating items even if we already have them in-memory (refreshing)
// - ideally support debouncing updates to the cache
// - ideally prevent overfetching / refreshing based on a `lastFetched` timestamp or something like that

function log(msg: string, ...args: any[]) {
  // console.log(`data: ${msg}`, ...args);
}

function error(msg: string, ...args: any[]) {
  // console.error(`data: ${msg}`, ...args);
}

function subscribeToObjectsAndConnections(params: {
  store: HGStore;
}): () => void {
  const { store } = params;

  const disposer = reaction(
    () => {
      const requiredCanonicalIds = Object.values(store.hgObjectRefSubscriptions)
        .filter((subDef) => subDef.state === "awaiting-sub")
        .map((subDef) => domain.canonicalIdForHGObjectRef(subDef.hgObjectRef));
      // log("requiredCanonicalIds 4", requiredCanonicalIds);
      return requiredCanonicalIds;
    },
    async (value, previousValue, reaction) => {
      log("running side-effect 2", value, previousValue, reaction);
      const accessToken = store.auth.accessToken;
      if (!accessToken) {
        error("could not subscribe to objects, no access token");
        return;
      }
      const authState: domain.AuthState = { accessToken };
      const portalId = store.portalId;

      const subscriptionState = store.hgObjectRefSubscriptions;

      const neededObjectRefs: domain.HGObjectRef[] = [];
      for (const subDef of Object.values(subscriptionState)) {
        if (subDef.state === "awaiting-sub") {
          neededObjectRefs.push(subDef.hgObjectRef);
        }
      }

      log("neededObjectRefs", cloneDeep(neededObjectRefs));

      if (neededObjectRefs.length === 0) {
        log("nothing new to subscribe to, early exit");
        return;
      }

      neededObjectRefs.forEach((neededObjectRef) => {
        const hgObjectCanonicalId =
          domain.canonicalIdForHGObjectRef(neededObjectRef);
        const existingSubDef =
          store.hgObjectRefSubscriptions[
            domain.canonicalIdForHGObjectRef(neededObjectRef)
          ];
        store.hgObjectRefSubscriptions[hgObjectCanonicalId] = {
          ...existingSubDef,
          state: "fetching",
        };
        store.hgObjectsFetchingAssociations[hgObjectCanonicalId] = true;
      });

      const propertiesToFetchForObjectTypes =
        domain.calculatePropertiesToFetchForAllObjectTypes({
          displayProperties: store.portal?.["hg-display-properties"] || [],
          hgObjectSchemas: Object.values(store.hgObjectSchemas),
        });

      log("propertiesToFetchForObjectTypes", propertiesToFetchForObjectTypes);

      const fetchObjectsFirst = async () => {
        const hgObjects = await hubspot.fetchObjects({
          authState,
          propertiesToFetchForObjectTypes,
          objectRefs: neededObjectRefs,
        });
        runInAction(() => {
          db.upsertHGObjects(store, hgObjects);
        });
      };

      // this is side-effecting now - maybe we can put this in a settimeout to make that clear?
      const fetchFromCache = async () => {
        const connectionCacheItems = await firebase.fetchCachedHGConnections({
          portalId,
          involvingObjectRefs: neededObjectRefs,
        });

        const mentionedObjectRefs = _.chain(connectionCacheItems)
          .flatMap((item) => {
            return [item.hgConnection.objectRefA, item.hgConnection.objectRefB];
          })
          .uniqBy(domain.canonicalIdForHGObjectRef)
          .value();

        const objectCacheItems = await firebase.fetchCachedHGObjects({
          portalId,
          objectRefs: mentionedObjectRefs,
        });

        const hgConnections = connectionCacheItems.map(
          (item) => item.hgConnection,
        );
        const hgObjects = objectCacheItems.map((item) => item.hgObject);

        runInAction(() => {
          db.upsertHGConnections(store, hgConnections);
          db.upsertHGObjects(store, hgObjects);
          for (const hgObject of hgObjects) {
            store.hgObjectsFetchingAssociations[
              domain.canonicalIdForHGObjectRef(hgObject)
            ] = false;
          }
        });

        return { hgObjects, hgConnections };
      };

      const fetchFromRemote = async () => {
        const existingHGLabelPairs = Object.values(store.hgLabelPairs);
        const hgSchemas = Object.values(store.hgObjectSchemas);

        const propertiesToFetchForObjectTypes =
          domain.calculatePropertiesToFetchForAllObjectTypes({
            displayProperties: store.portal?.["hg-display-properties"] || [],
            hgObjectSchemas: Object.values(store.hgObjectSchemas),
          });

        log("would fetch object connections for object refs", neededObjectRefs);
        const { objects: fetchedHGObjects, connections: fetchedHGConnections } =
          await useCases.fetchObjectsConnections({
            authState,
            existingHGLabelPairs,
            hgSchemas,
            objectRefs: neededObjectRefs,
            propertiesToFetchForObjectTypes,
          });

        runInAction(() => {
          db.upsertHGConnections(store, fetchedHGConnections);
          db.upsertHGObjects(store, fetchedHGObjects);
          for (const hgObject of fetchedHGObjects) {
            store.hgObjectsFetchingAssociations[
              domain.canonicalIdForHGObjectRef(hgObject)
            ] = false;
          }
        });

        return {
          hgConnections: fetchedHGConnections,
          hgObjects: fetchedHGObjects,
        };
      };

      const updateCache = async ({
        hgConnections,
        hgObjects,
      }: {
        hgConnections: HGConnection[];
        hgObjects: domain.HGObject[];
      }): Promise<void> => {
        // add the newest remote results to the cache
        const connectionCacheItems = hgConnections.map((hgConnection) => {
          return firebase.makeHGConnectionCacheItem({
            portalId,
            hgConnection,
          });
        });
        const objectCacheItems = hgObjects.map((hgObject) => {
          return firebase.makeHGObjectCacheItem({
            portalId,
            hgObject,
          });
        });
        await firebase.persistCacheItems([
          ...connectionCacheItems,
          ...objectCacheItems,
        ]);
      };

      // TODO: decide if we want to even cache the `HGObject`s - pretty quick to fetch anyway
      // await fetchObjectsFirst();

      if (DEV_CACHE_ENABLED) {
        log("fetching from cache...");
        const cachedResults = await fetchFromCache();
        log("fetched from cache", cachedResults);
      }

      log("fetching from remote...");
      const fetchedResults = await fetchFromRemote();
      log("fetched from remote", fetchedResults);

      if (DEV_CACHE_ENABLED) {
        log("updating cache...");
        await updateCache(fetchedResults);
        log("updated cache");
      }
    },
    {
      equals: comparer.structural,
    },
  );

  return disposer;
}

function resolveHubSpotPropertyValueDependencies(params: {
  store: HGStore;
}): () => void {
  const { store } = params;

  const disposer = reaction(
    () => {
      // there are properties that we need to fetch stuff from HubSpot for in order
      // to render sensible labels in the UI (easy example - company account owner
      // is stored as an ID, but if we want to show the name of the user we need
      // to fetch that user from HubSpot API)
      //
      // we can be really clever about this and only fetch the exact values that
      // we need (e.g. see account owner 1234, fetch user 1234), but another way
      // we could handle this in a coarser way that will still work is "see that
      // we can render account owner as a property, fetch all owners from the API"
      const displayPropertyCanonicalIds =
        store.portal?.["hg-display-properties"]?.map((displayProperty) => {
          return domain.canonicalIdForHSProperty(displayProperty);
        }) || [];

      // log("displayPropertyCanonicalIds23123", displayPropertyCanonicalIds);

      const hsPropertiesRequiringResolve = Object.values(store.hgProperties)
        .filter((hsProperty) => {
          return displayPropertyCanonicalIds.includes(hsProperty.canonicalId);
        })
        .filter(hsPropertyRequiresResolvingValues);

      // log("properties requiring resolve", hsPropertiesRequiringResolve);

      const displayPropertyValueResolvingSubscriptions: {
        [id: string]: ValueResolvingSubscription;
      } = _.chain(hsPropertiesRequiringResolve)
        .map((hsProperty) => {
          const resolvingType = hsPropertyValueResolvingType(hsProperty);
          // we can only resolve values that do not require object ids etc if we are
          // calculating from display properties
          if (
            !resolvingType ||
            !(
              resolvingType === "AllDealPipelines" ||
              resolvingType === "AllOwners"
            )
          ) {
            return;
          }
          const subDef = makeResolvingValueSubscription({
            type: resolvingType,
          });
          return subDef;
        })
        .compact()
        .uniqBy((subDef) => subDef.id)
        .map((subDef) => [subDef.id, subDef])
        .fromPairs()
        .value();

      // log(
      //   "displayPropertyValueResolvingSubscriptions",
      //   displayPropertyValueResolvingSubscriptions,
      // );

      const displayPropertiesRequiringIndividualSubscriptions =
        hsPropertiesRequiringResolve.filter((hsProperty) => {
          // log("indiv type", {
          //   hsProperty: _.cloneDeep(hsProperty),
          //   type: hsPropertyValueResolvingType(hsProperty),
          // });
          return hsPropertyValueResolvingType(hsProperty) === "HGObject";
        });

      // log(
      //   "displayPropertiesRequiringIndividualSubscriptions",
      //   displayPropertiesRequiringIndividualSubscriptions,
      // );

      const hgObjectValueResolvingSubscriptions: {
        [id: string]: ValueResolvingSubscription;
      } = _.chain(store.hgObjects)
        .flatMap((hgObject) => {
          return requiredResolvingValueSubscriptionsForHGObject({
            hgObject,
            hsProperties: displayPropertiesRequiringIndividualSubscriptions,
          });
        })
        // also handles removing duplicates as they'll have the same `subDef.id`
        .map((subDef) => [subDef.id, subDef])
        .fromPairs()
        .value();

      // log(
      //   "hgObjectValueResolvingSubscriptions",
      //   hgObjectValueResolvingSubscriptions,
      // );

      const authState: domain.AuthState | undefined = store.auth.accessToken
        ? { accessToken: store.auth.accessToken }
        : undefined;

      return {
        valueResolvingSubscriptions: {
          ...displayPropertyValueResolvingSubscriptions,
          ...hgObjectValueResolvingSubscriptions,
        },
        authState,
      };
    },
    (value, previousValue, reaction) => {
      const { valueResolvingSubscriptions, authState } = value;
      log("running side-effect for resolveHubSpotPropertyValueDependencies", {
        value,
        previousValue,
        reaction,
      });

      if (!authState) {
        // no need to worry about having not done the work - when we get new
        // authState in the store the data function for this reaction will
        // re-run and we’ll try again here
        return;
      }

      function updateStoreSubscriptions(): ValueResolvingSubscription[] {
        const subscriptionState = store.valueResolvingSubscriptions;
        const neededValueSubscriptions: ValueResolvingSubscription[] = [];
        for (const subDef of Object.values(valueResolvingSubscriptions)) {
          if (!subscriptionState[subDef.id]) {
            neededValueSubscriptions.push(subDef);
          }
        }

        log("neededValueSubscriptions", neededValueSubscriptions);
        runInAction(() => {
          for (const valueResolvingSubscription of neededValueSubscriptions) {
            db.subscribeToResolveValueType(store, {
              valueResolvingSubscription,
            });
          }
        });
        return neededValueSubscriptions;
      }

      async function fetchOwners(authState: domain.AuthState): Promise<void> {
        const owners = await hubspot.fetchAllOwners({
          authState,
        });

        log("received all owners, updating db state...", owners);

        runInAction(() => {
          db.updateOwnersValueResolvingSubscription(store, { owners });
        });
      }

      async function fetchDealPipelines(
        authState: domain.AuthState,
      ): Promise<void> {
        const dealPipelines = await hubspot.fetchPipelines({
          authState,
          objectType: "deal",
        });

        log("received all deal pipelines, updating db state...", dealPipelines);

        runInAction(() => {
          db.updateDealPipelinesValueResolvingSubscription(store, {
            dealPipelines,
          });
        });
      }

      async function fetchAllRequiredValues(
        authState: domain.AuthState,
        neededValueSubscriptions: ValueResolvingSubscription[],
      ): Promise<void> {
        const fetches: (() => Promise<void>)[] = [];
        const needOwners = neededValueSubscriptions.some(
          (subDef) => subDef.type === "AllOwners",
        );
        const needDealPipelines = neededValueSubscriptions.some(
          (subDef) => subDef.type === "AllDealPipelines",
        );
        if (needOwners) {
          fetches.push(async () => {
            return await fetchOwners(authState);
          });
        }
        if (needDealPipelines) {
          log("adding deal pipeline fetch");
          fetches.push(async () => {
            return await fetchDealPipelines(authState);
          });
        }

        log("fetches length", fetches.length);

        const all = Promise.all(fetches.map((fetch) => fetch()));
        await all;
        return;
      }

      // update the store with subscription data for resolve types
      const neededValueResolvingSubscriptions = updateStoreSubscriptions();

      // actually perform the neccessary fetches
      fetchAllRequiredValues(authState, neededValueResolvingSubscriptions)
        .then(() => {
          log("all fetches for resovlve types done");
        })
        .catch(error);

      // sub to any `HGObject`s that we also need
      runInAction(() => {
        const hgObjectRefs = _.chain(neededValueResolvingSubscriptions)
          .filter(isHGObjectValueResolvingSubscription)
          .map((subDef) => subDef.hgObjectCanonicalId)
          .map((canonicalId) => {
            const [objectType, objectId] = canonicalId.split(":");
            return {
              objectType,
              objectId,
            };
          })
          .value();
        db.subscribeToHGObjects(store, {
          hgObjectRefs,
        });
      });
    },
    {
      equals: comparer.structural,
      // fireImmediately: true,
    },
  );

  return disposer;
}

export function createReactions(params: { store: HGStore }): () => void {
  const disposers: (() => void)[] = [
    subscribeToObjectsAndConnections(params),
    resolveHubSpotPropertyValueDependencies(params),
  ];

  return () => {
    // log("dispose all reactions");
    disposers.forEach((dispose) => dispose());
  };
}
