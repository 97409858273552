import Bottleneck from "bottleneck";
import * as ts from "io-ts";
import * as domain from "../domain";
import * as HubSpotAPITypes from "./hubspot-api-types";
import { API_BASE_URL, DEV_USE_V3_API_FOR_FETCH_CONNECTIONS } from "../config";
import _, { debounce } from "lodash";
import { assertNever, indexBy, parseOrThrow } from "../utils";
import * as connection from "../domain/connection";
import { HSProperty, HSPropertyGroup } from "../domain/property";
import { objectTypeForFullyQualifiedObjectName } from "../domain/object-types";

const baseUrl = `${API_BASE_URL}/api/hubspotproxy`;

const limiter = new Bottleneck({
  maxConcurrent: 3,
  minTime: (10 * 1000) / 100,
});

export type FilterGroup = {
  filters: { propertyName: string; operator: string; value: string }[];
};

export type Sort = {
  propertyName: string;
  direction: "ASCENDING" | "DESCENDING";
};

export type Pagination = {
  total: number;
  after?: string;
};

function baseHeaders(): { [k: string]: string } {
  return {
    "x-och-app": "och",
    "content-type": "application/json",
    accept: "application/json",
  };
}

function headersWithAuth(authState: domain.AuthState): { [k: string]: string } {
  return {
    authorization: `Bearer ${authState.accessToken}`,
    ...baseHeaders(),
  };
}

function fetchRateLimited(
  input: URL | RequestInfo,
  init?: RequestInit,
): ReturnType<typeof fetch> {
  return limiter.schedule(async () => {
    return await fetch(input, init);
  });
}

async function fetchAllObjectPropertyDefinitions(params: {
  authState: domain.AuthState;
  objectType: string;
}): Promise<HSProperty[]> {
  const { authState, objectType } = params;

  let apiResults: HubSpotAPITypes.HubSpotReadAllPropertiesAPIResponse["results"] =
    [];
  let after: string | undefined = "0";

  while (after) {
    const res = await fetchRateLimited(
      `${baseUrl}/crm/v3/properties/${objectType}`,
      {
        method: "GET",
        headers: {
          ...headersWithAuth(authState),
        },
      },
    );

    const body = await res.json();

    const decoded = parseOrThrow(
      HubSpotAPITypes.HubSpotReadAllPropertiesAPIResponse,
      body,
    );

    apiResults = [...apiResults, ...decoded.results];
    after = decoded.paging?.next?.after;
  }

  const hsProperties = _.map(apiResults, (apiProperty) => {
    return parseOrThrow(HSProperty, {
      ...apiProperty,
      objectType,
      canonicalId: domain.canonicalIdForHSProperty({
        objectType,
        name: apiProperty.name,
      }),
    });
  });

  return hsProperties;
}

async function fetchAllObjectPropertyGroupDefinitions(params: {
  authState: domain.AuthState;
  objectType: string;
}): Promise<HSPropertyGroup[]> {
  const { authState, objectType } = params;

  let apiResults: HubSpotAPITypes.HubSpotReadAllPropertyGroupsAPIResponse["results"] =
    [];
  let after: string | undefined = "0";

  while (after) {
    const res = await fetchRateLimited(
      `${baseUrl}/crm/v3/properties/${objectType}/groups`,
      {
        method: "GET",
        headers: {
          ...headersWithAuth(authState),
        },
      },
    );

    const body = await res.json();

    const decoded = parseOrThrow(
      HubSpotAPITypes.HubSpotReadAllPropertyGroupsAPIResponse,
      body,
    );

    apiResults = [...apiResults, ...decoded.results];
    after = decoded.paging?.next?.after;
  }

  const hsPropertyGroups = _.map(apiResults, (apiPropertyGroup) => {
    return parseOrThrow(HSPropertyGroup, {
      ...apiPropertyGroup,
      objectType,
      canonicalId: domain.canonicalIdForHSPropertyGroup({
        objectType,
        name: apiPropertyGroup.name,
      }),
    });
  });

  return hsPropertyGroups;
}

export async function fetchAllPropertyDefinitions(params: {
  objectTypes: string[];
  authState: domain.AuthState;
}): Promise<HSProperty[]> {
  const { objectTypes, authState } = params;

  let propertyDefinitions: HSProperty[] = [];

  for (const objectType of objectTypes) {
    const defs = await fetchAllObjectPropertyDefinitions({
      authState,
      objectType,
    });
    propertyDefinitions = [...propertyDefinitions, ...defs];
  }

  return propertyDefinitions;
}

export async function fetchAllPropertyGroupDefinitions(params: {
  objectTypes: string[];
  authState: domain.AuthState;
}): Promise<HSPropertyGroup[]> {
  const { objectTypes, authState } = params;

  let propertyGroupDefinitions: HSPropertyGroup[] = [];

  for (const objectType of objectTypes) {
    const defs = await fetchAllObjectPropertyGroupDefinitions({
      authState,
      objectType,
    });
    propertyGroupDefinitions = [...propertyGroupDefinitions, ...defs];
  }

  return propertyGroupDefinitions;
}

export async function fetchHubSpotAssociationLabels(params: {
  objectTypes: string[];
  authState: domain.AuthState;
}): Promise<HubSpotAPITypes.HubSpotAPIAssociationLabelWithFromToObjectTypes[]> {
  const { objectTypes, authState } = params;

  const fetches: Promise<
    HubSpotAPITypes.HubSpotAPIAssociationLabelWithFromToObjectTypes[]
  >[] = [];
  for (const fromObjectType of objectTypes) {
    for (const toObjectType of objectTypes) {
      const thunk = async () => {
        const ExpectedBody = ts.interface({
          results: ts.array(HubSpotAPITypes.HubSpotAPIAssociationLabel),
        });

        const res = await fetchRateLimited(
          `${baseUrl}/crm/v4/associations/${fromObjectType}/${toObjectType}/labels`,
          {
            method: "GET",
            headers: {
              ...headersWithAuth(authState),
            },
          },
        );

        const body = await res.json();

        const decoded = parseOrThrow(ExpectedBody, body);

        let hgAssociationLabels: HubSpotAPITypes.HubSpotAPIAssociationLabelWithFromToObjectTypes[] =
          _.map(decoded.results, (result) => {
            return {
              ...result,
              fromObjectType,
              toObjectType,
            };
          });

        if (
          (fromObjectType === toObjectType &&
            !(fromObjectType === "company" && toObjectType === "company")) ||
          DEV_USE_V3_API_FOR_FETCH_CONNECTIONS
        ) {
          // if we are looking for same-object type associations then we (currently) also need to fetch the v3 association labels so that we can match any paired labels based on their `name` field - that doesn't currently get expose on the v4 API which prevents us from being able to tell which label goes with which (e.g. Employee, Manager, Colleague, Sibling, LabelA, LabelB - which ones of these are pairs?)
          const ExpectedV3Body = ts.type({
            results: ts.array(
              ts.type({
                id: ts.string,
                name: ts.string,
              }),
            ),
          });
          const v3LabelDefinitionsRes = await fetchRateLimited(
            `${baseUrl}/crm/v3/associations/${fromObjectType}/${toObjectType}/types`,
            {
              method: "GET",
              headers: {
                ...headersWithAuth(authState),
              },
            },
          );
          const v3Body = await v3LabelDefinitionsRes.json();
          const v3Decoded = parseOrThrow(ExpectedV3Body, v3Body);

          // merge in the name for the same-object association types
          hgAssociationLabels = hgAssociationLabels.map((label) => {
            let v3Label:
              | {
                  id: string;
                  name: string;
                }
              | undefined;
            if (DEV_USE_V3_API_FOR_FETCH_CONNECTIONS) {
              const possibleHubSpotDefinedUnlabelledV3Names = [
                `${fromObjectType.toLowerCase()}_to_${toObjectType.toLowerCase()}_unlabeled`,
                `${fromObjectType.toLowerCase()}_to_${toObjectType.toLowerCase()}`,
              ];
              const possibleHubSpotDefinedPrimaryName = `${fromObjectType.toLowerCase()}_to_${toObjectType.toLowerCase()}`;

              if (
                (label.category === "HUBSPOT_DEFINED" &&
                  label.typeId ===
                    connection.CHILD_TO_PARENT_COMPANY_LABEL_HUBSPOT_TYPE_ID) ||
                (label.category === "HUBSPOT_DEFINED" &&
                  label.typeId ===
                    connection.PARENT_TO_CHILD_COMPANY_LABEL_HUBSPOT_TYPE_ID)
              ) {
                v3Label = v3Decoded.results.find((v3Label) => {
                  return v3Label.id === `${label.typeId}`;
                });
              } else if (label.category === "HUBSPOT_DEFINED" && !label.label) {
                v3Label = possibleHubSpotDefinedUnlabelledV3Names.reduce(
                  (acc, name) => {
                    if (acc) {
                      return acc;
                    }
                    return v3Decoded.results.find((v3Label) => {
                      return v3Label.name === name;
                    });
                  },
                  undefined as { id: string; name: string } | undefined,
                );
              } else if (
                label.category === "HUBSPOT_DEFINED" &&
                typeof label.label === "string"
              ) {
                v3Label = v3Decoded.results.find((v3Label) => {
                  return v3Label.name === possibleHubSpotDefinedPrimaryName;
                });
              } else {
                v3Label = v3Decoded.results.find(
                  (v3Label) =>
                    v3Label.id === `${label.typeId}` &&
                    ![
                      possibleHubSpotDefinedPrimaryName,
                      ...possibleHubSpotDefinedUnlabelledV3Names,
                    ].includes(v3Label.name),
                );
              }
            } else {
              v3Label = v3Decoded.results.find(
                (v3Label) => v3Label.id === `${label.typeId}`,
              );
            }

            if (v3Label) {
              return {
                ...label,
                name: v3Label.name,
              };
            } else {
              return label;
            }
          });
        }

        console.log("labels request finished", {
          fromObjectType,
          toObjectType,
        });

        return hgAssociationLabels;
      };

      fetches.push(thunk());
    }
  }

  // wait for all the label information for each object type pair
  const results = await Promise.all(fetches);

  const flattenedResults = results.flatMap((result) => result);

  return flattenedResults;
}

async function associationsList(params: {
  authState: domain.AuthState;
  fromObjectRef: domain.HGObjectRef;
  toObjectType: string;
  after?: string;
}): Promise<HubSpotAPITypes.HubSpotAssociationsListAPIResponse> {
  const { authState, fromObjectRef, toObjectType, after } = params;

  const queryParams = new URLSearchParams();
  queryParams.append("limit", "500");
  if (after) {
    queryParams.append("after", after);
  }
  const qs = queryParams.toString();

  console.log("HSAPI: making call associationsList...", {
    fromObjectRef,
    toObjectType,
    qs,
  });

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v4/objects/${fromObjectRef.objectType}/${fromObjectRef.objectId}/associations/${toObjectType}?${qs}`,
    {
      method: "GET",
      headers: {
        ...headersWithAuth(authState),
      },
    },
  );
  const body = await res.json();

  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotAssociationsListAPIResponse,
    body,
  );

  console.log("HSAPI: associationsListBatchRead finished", {
    response: decoded,
    fromObjectRef,
    toObjectType,
    qs,
  });

  return decoded;
}

async function associationsListAll(params: {
  authState: domain.AuthState;
  fromObjectRef: domain.HGObjectRef;
  toObjectType: string;
}): Promise<HubSpotAPITypes.HubSpotAPIAssociation[]> {
  console.log("associationsListAll...", params);

  let labelsByObjectId: Record<
    string,
    HubSpotAPITypes.HubSpotAPIAssociationLabel[]
  > = {};

  let hasMore: boolean = true;
  let after: string | undefined;
  while (hasMore) {
    const res = await associationsList({
      authState: params.authState,
      fromObjectRef: params.fromObjectRef,
      toObjectType: params.toObjectType,
      after,
    });

    for (const hsAssociation of res.results) {
      const id = `${hsAssociation.toObjectId}`;
      if (!labelsByObjectId[id]) {
        labelsByObjectId[id] = [];
      }
      labelsByObjectId[id] = [
        ...labelsByObjectId[id],
        ...hsAssociation.associationTypes,
      ];
    }

    // setup next loop
    const nextAfter = res?.paging?.next?.after;
    hasMore = typeof nextAfter === "string";
    after = nextAfter;
  }

  // collapse all associations to specific object types into one result, so we get an
  // array of containing HubSpotAPIAssociation items that describe each toObjectId
  const associations = Object.entries(labelsByObjectId).map(([id, labels]) => {
    const hsAPIAssociation: HubSpotAPITypes.HubSpotAPIAssociation = {
      toObjectId: parseInt(id, 10),
      associationTypes: labels,
    };
    return hsAPIAssociation;
  });

  console.log("associationsListAll finished", associations);

  return associations;
}

// TODO: implement paging
async function associationsListBatchRead(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  toObjectType: string;
  objectIds: string[];
}): Promise<HubSpotAPITypes.HubSpotAssociationsBatchReadAPIResponse> {
  const { authState, fromObjectType, toObjectType, objectIds } = params;

  console.log("HSAPI: making call associationsListBatchRead...", {
    fromObjectType,
    toObjectType,
    objectIds,
  });

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v4/associations/${fromObjectType}/${toObjectType}/batch/read`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify({
        inputs: _.map(objectIds, (objectId) => {
          return { id: objectId };
        }),
      }),
    },
  );
  const body = await res.json();

  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotAssociationsBatchReadAPIResponse,
    body,
  );

  console.log("HSAPI: associationsListBatchRead finished", {
    response: decoded,
    fromObjectType,
    toObjectType,
    objectIds,
  });

  return decoded;
}

async function fetchConnectionsForObjectRefViaV4API(params: {
  authState: domain.AuthState;
  hgLabelPairs: connection.HGLabelPair[];
  objectRef: domain.HGObjectRef;
  propertiesToFetchForObjectTypes: Record<string, string[]>;
}): Promise<{ connections: connection.HGConnection[]; object: undefined }> {
  let results: connection.HGConnection[] = [];

  const { authState, objectRef, hgLabelPairs } = params;

  const objectRefs = [objectRef];

  console.log("fetchAssociationsForObjectRefs", params);

  const mentionedFromObjectTypes: string[] = _.chain(objectRefs)
    .map((ref) => ref.objectType)
    .uniq()
    .value();

  console.log("mentionedFromObjectTypes", mentionedFromObjectTypes);

  const objectTypePairsToFetch: [
    fromObjectType: string,
    toObjectType: string,
  ][] = _.chain(hgLabelPairs)
    .flatMap((hgLabelPair) => {
      const hgLabels = connection
        .hsLabelsForLabelPair(hgLabelPair)
        .filter((hgLabel) => {
          return mentionedFromObjectTypes.includes(hgLabel.objectType);
        });

      return hgLabels.map((hgLabel) => {
        return [hgLabel.objectType, hgLabel.otherObjectType] as [
          fromObjectType: string,
          toObjectType: string,
        ];
      });
    })
    .compact()
    .uniqBy(
      ([fromObjectType, toObjectType]) => `${fromObjectType}:${toObjectType}`,
    )
    .value();

  console.log("objectTypePairsToFetch", objectTypePairsToFetch);

  const hgLabelPairByCanonicalId = indexBy(
    hgLabelPairs,
    (hgLabelPair) => hgLabelPair.canonicalId,
  );

  console.log("hgLabelPairByCanonicalId", hgLabelPairByCanonicalId);

  const objectRefsByObjectType = _.groupBy(objectRefs, (ref) => ref.objectType);

  for (const [fromObjectType, toObjectType] of objectTypePairsToFetch) {
    console.log("processing fromObjectType/toObjectType pair", [
      fromObjectType,
      toObjectType,
    ]);
    const objectRefs = objectRefsByObjectType[fromObjectType];
    const objectIdBatches = _.chunk(
      _.map(objectRefs, (ref) => ref.objectId),
      100,
    );

    for (const objectIds of objectIdBatches) {
      console.log("making api call for batch of ids", [
        fromObjectType,
        toObjectType,
        objectIdBatches,
      ]);
      let apiResponse = await associationsListBatchRead({
        authState,
        fromObjectType,
        toObjectType,
        objectIds,
      });

      const hgConnections = connection.makeConnections2FromHSAssociations({
        fromObjectType,
        toObjectType,
        hgLabelPairs,
        hsAssociations: apiResponse.results,
      });

      for (const hgConnection of hgConnections) {
        results.push(hgConnection);
      }
    }
  }

  return { connections: results, object: undefined };
}

async function fetchConnectionsForObjectRefViaV3API(params: {
  authState: domain.AuthState;
  hgSchemas: domain.HGObjectSchema[];
  hgLabelPairs: connection.HGLabelPair[];
  objectRef: domain.HGObjectRef;
  propertiesToFetchForObjectTypes: Record<string, string[]>;
}): Promise<{
  connections: connection.HGConnection[];
  object: domain.HGObject;
}> {
  let results: connection.HGConnection[] = [];

  const {
    authState,
    objectRef,
    hgLabelPairs,
    hgSchemas,
    propertiesToFetchForObjectTypes,
  } = params;

  console.log("fetchAssociationsForObjectRefV3", params);

  const fromObjectType = objectRef.objectType;

  console.log("formObjectType", fromObjectType);

  const toObjectTypesToFetch: string[] = _.chain(hgLabelPairs)
    .filter((hgLabelPair) => {
      return connection.labelPairInvolvesObjectType(
        hgLabelPair,
        fromObjectType,
      );
    })
    .map((hgLabelPair) => {
      if (hgLabelPair.hgLabels.hgLabelA.objectType === fromObjectType) {
        return hgLabelPair.hgLabels.hgLabelB.objectType;
      } else {
        return hgLabelPair.hgLabels.hgLabelA.objectType;
      }
    })
    .compact()
    .uniq()
    .value();

  console.log("toObjectTypesToFetch", toObjectTypesToFetch);

  console.log(
    "fetching connections between objectRef and toObjectTypesToFetch",
    [objectRef, toObjectTypesToFetch],
  );

  const objectFetchResult = await hubspotObjectGet({
    authState,
    objectType: objectRef.objectType,
    objectId: objectRef.objectId,
    properties: propertiesToFetchForObjectTypes[objectRef.objectType] || [],
    associations: toObjectTypesToFetch,
  });

  // for every association we have found, create a connection
  for (const [toObjectTypeFullyQualifiedName, associations] of Object.entries(
    objectFetchResult.associations || {},
  )) {
    const toObjectType = objectTypeForFullyQualifiedObjectName({
      hgSchemas,
      fullyQualifiedObjectName: toObjectTypeFullyQualifiedName,
    });
    if (!toObjectType) {
      console.warn("could not find object schema for fullQualifiedName", {
        toObjectTypeFullyQualifiedName,
      });
      continue;
    }

    if (associations?.paging?.next) {
      // abort using V3 API as it will most likely be quicker to fetch all the
      // associations for this object to this `objectType` via the V4 API with
      // its 1000 results paging
      const hsAssociations = await associationsListAll({
        authState,
        fromObjectRef: objectRef,
        toObjectType: toObjectType,
      });

      console.log(
        "making HGConnections via V4 results",
        _.cloneDeep({
          toObjectTypeFullyQualifiedName,
          toObjectType,
          hsAssociations,
        }),
      );

      const connections = connection.makeConnectionsFromHSAssociationLabels({
        fromObjectRef: objectRef,
        toObjectType,
        hgLabelPairs,
        hsAssociations,
      });

      results = [...results, ...connections];
    } else {
      const connections =
        connection.makeConnectionsFromObjectGetAssociationsResults({
          fromObjectRef: _.cloneDeep(objectRef),
          hgLabelPairs: _.cloneDeep(hgLabelPairs),
          objectGetAssociationsResults: associations?.results || [],
          toObjectType,
        });

      results = [...results, ...connections];
    }
  }

  const object: domain.HGObject = {
    canonicalId: domain.canonicalIdForHGObjectRef(objectRef),
    isFetched: true,
    objectId: objectRef.objectId,
    objectType: objectRef.objectType,
    properties: objectFetchResult.properties,
  };

  return { connections: results, object };
}

const fetchConnectionsForObjectRef = DEV_USE_V3_API_FOR_FETCH_CONNECTIONS
  ? fetchConnectionsForObjectRefViaV3API
  : fetchConnectionsForObjectRefViaV4API;

export async function fetchConnectionsForLevel0ObjectRef(params: {
  authState: domain.AuthState;
  hgSchemas: domain.HGObjectSchema[];
  hgLabelPairs: connection.HGLabelPair[];
  level0ObjectRef: domain.HGObjectRef;
  propertiesToFetchForObjectTypes: Record<string, string[]>;
}): Promise<{
  connections: connection.HGConnection[];
  level0Object?: domain.HGObject;
  level1ObjectRefs: domain.HGObjectRef[];
}> {
  const {
    authState,
    level0ObjectRef,
    hgLabelPairs,
    hgSchemas,
    propertiesToFetchForObjectTypes,
  } = params;

  // fetch all objects directly connected to this one through the valid associationLabels passed
  const { connections, object } = await fetchConnectionsForObjectRef({
    authState,
    hgLabelPairs,
    hgSchemas,
    objectRef: level0ObjectRef,
    propertiesToFetchForObjectTypes,
  });

  const level1ObjectRefs = _.chain(connections)
    .flatMap((hgConnection) => {
      return connection.hgConnectionObjectRefs(hgConnection);
    })
    .uniqBy(domain.canonicalIdForHGObjectRef)
    .filter((objectRef) => !domain.objectRefsEqual(objectRef, level0ObjectRef))
    .value();

  return { connections, level1ObjectRefs, level0Object: object };
}

async function hubspotObjectGet(params: {
  authState: domain.AuthState;
  objectType: string;
  objectId: string;
  properties?: string[];
  associations?: string[];
}): Promise<HubSpotAPITypes.HubSpotObjectsGetAPIResponse> {
  const { authState, objectType, objectId, properties, associations } = params;

  const searchParams = new URLSearchParams();
  searchParams.append("properties", ["id", ...(properties ?? [])].join(","));
  if (associations && associations.length > 0) {
    searchParams.append("associations", associations.join(","));
  }

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v3/objects/${objectType}/${objectId}?${searchParams.toString()}`,
    {
      method: "GET",
      headers: {
        ...headersWithAuth(authState),
      },
    },
  );
  const body = await res.json();

  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotObjectsGetAPIResponse,
    body,
  );

  return decoded;
}

async function hubspotObjectsBatchRead(params: {
  authState: domain.AuthState;
  objectType: string;
  ids: string[];
  properties: string[];
}): Promise<HubSpotAPITypes.HubSpotBatchObjectsReadAPIResponse> {
  const { authState, objectType, ids, properties } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v3/objects/${objectType}/batch/read`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify({
        properties,
        inputs: _.map(ids, (id) => {
          return { id };
        }),
      }),
    },
  );

  const ExpectedBody = ts.type({
    results: ts.array(
      ts.type({
        id: ts.string,
        properties: ts.record(ts.string, ts.union([ts.string, ts.null])),
      }),
    ),
  });

  const body = await res.json();

  const decoded1 = parseOrThrow(ExpectedBody, body);

  const withObjectType = {
    results: _.map(decoded1.results, (result) => {
      return {
        ...result,
        objectType,
      };
    }),
  };

  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotBatchObjectsReadAPIResponse,
    withObjectType,
  );

  return decoded;
}

export async function fetchObjects(params: {
  authState: domain.AuthState;
  objectRefs: domain.HGObjectRef[];
  propertiesToFetchForObjectTypes: Record<string, string[] | undefined>;
}): Promise<domain.HGObject[]> {
  const { authState, objectRefs, propertiesToFetchForObjectTypes } = params;

  const allResults: HubSpotAPITypes.HubSpotAPIObject[] = [];

  const byObjectType = _.groupBy(objectRefs, (o) => o.objectType);

  for (const [objectType, objectInputs] of Object.entries(byObjectType)) {
    const batchedIds = _.chunk(
      _.map(objectInputs, (o) => o.objectId),
      100,
    );

    for (const ids of batchedIds) {
      const apiRes = await hubspotObjectsBatchRead({
        authState,
        objectType,
        ids,
        properties: propertiesToFetchForObjectTypes[objectType] || [],
      });

      for (const result of apiRes.results) {
        const o: HubSpotAPITypes.HubSpotAPIObject = {
          id: result.id,
          objectType,
          properties: result.properties,
        };
        allResults.push(o);
      }
    }
  }

  // convert to HGObjects
  const hgObjects = _.map(allResults, (hsObject) => {
    const { objectType, id: objectId } = hsObject;
    const hgObject: domain.HGObject = {
      canonicalId: domain.canonicalIdForHGObjectRef({
        objectType,
        objectId,
      }),
      objectId,
      objectType,
      properties: hsObject.properties,
      isFetched: true,
    };
    return hgObject;
  });

  return hgObjects;
}

export async function searchObjects(params: {
  authState: domain.AuthState;
  objectType: string;
  query: string;
  after?: string;
  propertiesToFetch: string[];
  filterGroups?: FilterGroup[];
  sorts?: Sort[];
}): Promise<{
  objects: domain.HGObject[];
  pagination: Pagination;
}> {
  const {
    authState,
    query,
    after,
    objectType,
    propertiesToFetch,
    filterGroups,
    sorts,
  } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v3/objects/${objectType}/search`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify({
        query,
        after,
        properties: propertiesToFetch,
        limit: 20,
        filterGroups,
        sorts,
      }),
    },
  );

  const extras =
    objectType === "contact"
      ? {
          jobtitle: "Senior Inbound Sales Professor",
          notes_last_updated: "2022-06-10",
        }
      : {};

  const body = await res.json();

  const decoded1 = parseOrThrow(HubSpotAPITypes.HubSpotSearchAPIResponse, body);

  const hgObjects: domain.HGObject[] = _.map(decoded1.results, (result) => {
    const hgObject: domain.HGObject = {
      objectType,
      canonicalId: domain.canonicalIdForHGObjectRef({
        objectType,
        objectId: result.id,
      }),
      isFetched: true,
      objectId: result.id,
      properties: result.properties,
    };
    return hgObject;
  });

  console.log("search result", hgObjects);

  return {
    objects: hgObjects,
    pagination: { total: body.total, after: body.paging?.next?.after },
  };
}

export async function updateObject(params: {
  authState: domain.AuthState;
  objectType: string;
  objectId: string;
  properties: Record<string, string>;
}): Promise<domain.HGObject> {
  const { authState, objectType, objectId, properties } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v3/objects/${objectType}/${objectId}`,
    {
      method: "PATCH",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify({
        properties,
      }),
    },
  );

  const body = await res.json();

  const decoded = parseOrThrow(HubSpotAPITypes.HubSpotAPIObject, {
    ...body,
    objectType,
  });

  const hgObject: domain.HGObject = {
    objectId,
    objectType,
    canonicalId: domain.canonicalIdForHGObjectRef({
      objectType,
      objectId,
    }),
    isFetched: true,
    properties: decoded.properties,
  };

  return hgObject;
}

export const debouncedUpdateObject = debounce(updateObject, 500);

export async function createObject(params: {
  authState: domain.AuthState;
  objectType: string;
  properties: Record<string, string>;
}): Promise<domain.HGObject> {
  const { authState, objectType, properties } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v3/objects/${objectType}`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify({
        properties,
      }),
    },
  );

  const body = await res.json();

  const decoded = parseOrThrow(HubSpotAPITypes.HubSpotAPIObject, {
    ...body,
    objectType,
  });

  const hgObject: domain.HGObject = {
    objectId: decoded.id,
    objectType,
    canonicalId: domain.canonicalIdForHGObjectRef({
      objectType,
      objectId: decoded.id,
    }),
    isFetched: true,
    properties: decoded.properties,
  };

  return hgObject;
}

async function createAssociationHubSpotAPI(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  fromObjectId: string;
  toObjectType: string;
  toObjectId: string;
  associationCategory: connection.HGLabelCategory;
  associationTypeId: number;
}): Promise<void> {
  const {
    authState,
    fromObjectType,
    fromObjectId,
    toObjectType,
    toObjectId,
    associationCategory,
    associationTypeId,
  } = params;

  const body: {
    inputs: {
      from: { id: string };
      to: { id: string };
      types: {
        associationCategory: connection.HGLabelCategory;
        associationTypeId: number;
      }[];
    }[];
  } = {
    inputs: [
      {
        from: { id: fromObjectId },
        to: { id: toObjectId },
        types: [
          {
            associationCategory,
            associationTypeId,
          },
        ],
      },
    ],
  };

  console.log(
    "creating specific association between objects via Asssociations V4 batch API",
    {
      fromObjectType,
      toObjectType,
      body,
    },
  );

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v4/associations/${fromObjectType}/${toObjectType}/batch/create`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify(body),
    },
  );

  console.log("create specific association between objects res", res);

  return undefined;
}

export async function removeAssociation(params: {
  authState: domain.AuthState;
  fromObjectType: string;
  fromObjectId: string;
  toObjectType: string;
  toObjectId: string;
  associationCategory: connection.HGLabelCategory;
  associationTypeId: number;
}): Promise<void> {
  const {
    authState,
    fromObjectId,
    fromObjectType,
    toObjectId,
    toObjectType,
    associationCategory,
    associationTypeId,
  } = params;

  const existingAssociationsRes = await associationsListBatchRead({
    authState,
    fromObjectType,
    toObjectType,
    objectIds: [fromObjectId],
  });
  const existingAssociations = existingAssociationsRes.results;
  console.log("existing associations", existingAssociations);

  const body: {
    inputs: {
      from: { id: string };
      to: { id: string };
      types: {
        associationCategory: connection.HGLabelCategory;
        associationTypeId: number;
      }[];
    }[];
  } = {
    inputs: [
      {
        from: { id: fromObjectId },
        to: { id: toObjectId },
        types: [
          {
            associationCategory,
            associationTypeId,
          },
        ],
      },
    ],
  };

  console.log("sending archive for specific association between objects", {
    fromObjectType,
    toObjectType,
    body,
  });

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v4/associations/${fromObjectType}/${toObjectType}/batch/labels/archive`,
    {
      method: "POST",
      headers: {
        ...headersWithAuth(authState),
      },
      body: JSON.stringify(body),
    },
  );

  console.log("archiving specific association res", res);

  const existingAssociationsAfterRes = await associationsListBatchRead({
    authState,
    fromObjectType,
    toObjectType,
    objectIds: [fromObjectId],
  });
  const existingAssociationsAfter = existingAssociationsAfterRes.results;

  console.log("existing associations", existingAssociationsAfter);

  return;
}

export async function fetchOwner(params: {
  authState: domain.AuthState;
  userId: string;
}): Promise<domain.HSUser> {
  const { authState, userId } = params;

  try {
    const res = await fetchRateLimited(
      `${baseUrl}/crm/v3/owners/${userId}?idProperty=userId`,
      {
        method: "GET",
        headers: {
          ...headersWithAuth(authState),
        },
      },
    );

    const body = await res.json();

    const decoded = parseOrThrow(
      HubSpotAPITypes.HubSpotOwnersGetAPIResponse,
      body,
    );

    return decoded;
  } catch (e) {
    return {
      id: userId,
    };
  }
}

export async function fetchSchemas(params: {
  authState: domain.AuthState;
}): Promise<domain.HGObjectSchema[]> {
  const { authState } = params;

  const res = await fetchRateLimited(`${baseUrl}/crm/v3/schemas`, {
    method: "GET",
    headers: {
      ...headersWithAuth(authState),
    },
  });

  const body = await res.json();

  const decoded = parseOrThrow(HubSpotAPITypes.HubSpotGetSchemasResponse, body);

  const schemas = decoded.results.map((schema) => {
    const objectTypeId = schema.objectTypeId;
    return parseOrThrow(domain.HGObjectSchema, {
      ...schema,
      canonicalId: objectTypeId,
    });
  });

  return schemas;
}

export async function fetchCurrentScopes(params: {
  authState: domain.AuthState;
}): Promise<string[]> {
  const { authState } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/oauth/v1/access-tokens/$HUBSPOT_ACCESS_TOKEN`,
    {
      method: "GET",
      headers: {
        ...headersWithAuth(authState),
      },
    },
  );

  const body = await res.json();

  const ExpectedBody = ts.type({
    scopes: ts.array(ts.string),
  });

  const decoded = parseOrThrow(ExpectedBody, body);

  return decoded.scopes;
}

export async function discoverSupportedSchemas(params: {
  authState: domain.AuthState;
  currentScopes: string[];
}): Promise<domain.HGObjectSchema[]> {
  const { authState, currentScopes } = params;

  const customObjectsSupported = domain.customObjectsSupported(currentScopes);

  if (customObjectsSupported) {
    const schemas = await fetchSchemas({ authState });
    return schemas;
  } else {
    return [];
  }
}

export async function deleteAssociation(params: {
  authState: domain.AuthState;
  fromObjectRef: domain.HGObjectRef;
  toObjectRef: domain.HGObjectRef;
}): Promise<void> {
  const { authState, fromObjectRef, toObjectRef } = params;

  console.log("removing association between object refs", {
    fromObjectRef,
    toObjectRef,
  });

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v4/objects/${fromObjectRef.objectType}/${fromObjectRef.objectId}/associations/${toObjectRef.objectType}/${toObjectRef.objectId}`,
    {
      method: "DELETE",
      headers: {
        ...headersWithAuth(authState),
      },
    },
  );

  console.log("removed association between two objects refs", res);

  return undefined;
}

export async function applyAssociationPatches(params: {
  authState: domain.AuthState;
  patches: connection.ConnectionPatch[];
}): Promise<{
  appliedPatches: connection.ConnectionPatch[];
  failedPatches: connection.ConnectionPatch[];
}> {
  const { authState, patches } = params;

  let appliedPatches: connection.ConnectionPatch[] = [];
  let failedPatches: connection.ConnectionPatch[] = [];

  for (const patch of patches) {
    try {
      if (patch.action === "add-label") {
        await createAssociationHubSpotAPI({
          authState,
          fromObjectType: patch.fromObjectRef.objectType,
          toObjectType: patch.toObjectRef.objectType,
          fromObjectId: patch.fromObjectRef.objectId,
          toObjectId: patch.toObjectRef.objectId,
          associationTypeId: patch.typeId,
          associationCategory: patch.associationCategory,
        });
      } else if (patch.action === "remove-label") {
        await removeAssociation({
          authState,
          fromObjectType: patch.fromObjectRef.objectType,
          toObjectType: patch.toObjectRef.objectType,
          fromObjectId: patch.fromObjectRef.objectId,
          toObjectId: patch.toObjectRef.objectId,
          associationTypeId: patch.typeId,
          associationCategory: patch.associationCategory,
        });
      } else if (patch.action === "remove-connection") {
        await deleteAssociation({
          authState,
          fromObjectRef: patch.fromObjectRef,
          toObjectRef: patch.toObjectRef,
        });
      } else {
        assertNever(patch);
      }
    } catch (e) {
      failedPatches.push(patch);
    }
  }

  return {
    appliedPatches,
    failedPatches,
  };
}

async function fetchOwnersPage(params: {
  authState: domain.AuthState;
  after?: string;
}): Promise<HubSpotAPITypes.HubSpotOwnersIndexAPIResponse> {
  const { authState, after } = params;

  const searchParams = new URLSearchParams();
  searchParams.set("limit", "100");
  if (after) {
    searchParams.set("after", after);
  }

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v3/owners/?${searchParams.toString()}`,
    {
      method: "GET",
      headers: {
        ...headersWithAuth(authState),
      },
    },
  );

  const body = await res.json();

  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotOwnersIndexAPIResponse,
    body,
  );

  return decoded;
}

export async function fetchAllOwners(params: {
  authState: domain.AuthState;
}): Promise<domain.HSOwner[]> {
  const { authState } = params;

  let results: HubSpotAPITypes.HubSpotOwnersIndexAPIResponse["results"] = [];

  let hasMore: boolean = true;
  let after: string | undefined;
  while (hasMore) {
    const decoded = await fetchOwnersPage({ authState, after });
    results = [...results, ...decoded.results];
    const nextAfter = decoded?.paging?.next?.after;

    // setup next loop
    hasMore = typeof nextAfter === "string";
    after = nextAfter;
  }

  const owners: domain.HSOwner[] = results.map((apiOwner) => {
    const owner: domain.HSOwner = {
      id: apiOwner.id,
      email: apiOwner.email,
      firstName: apiOwner.firstName,
      lastName: apiOwner.lastName,
      teams: apiOwner.teams ? apiOwner.teams : [],
      userId: apiOwner.userId,
    };
    return owner;
  });

  return owners;
}

export async function fetchPipelines(params: {
  authState: domain.AuthState;
  objectType: string;
}): Promise<domain.HSPipeline[]> {
  const { authState, objectType } = params;

  const res = await fetchRateLimited(
    `${baseUrl}/crm/v3/pipelines/deals?includeArchived=false`,
    {
      method: "GET",
      headers: {
        ...headersWithAuth(authState),
      },
    },
  );

  const body = await res.json();
  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotPipelinesIndexAPIResponse,
    body,
  );

  const pipelines: domain.HSPipeline[] = decoded.results.map((apiPipeline) => {
    const stages: domain.HSPipelineStage[] = apiPipeline.stages.map(
      (apiStage) => {
        const stage: domain.HSPipelineStage = {
          canonicalId: domain.canonicalIdForPipelineStage({
            objectType,
            pipelineId: apiPipeline.id,
            id: apiStage.id,
          }),
          objectType,
          pipelineId: apiPipeline.id,
          id: apiStage.id,
          label: apiStage.label,
          displayOrder: apiStage.displayOrder,
          metadata: {
            probability: apiStage.metadata.probability,
          },
        };
        return stage;
      },
    );

    const pipeline: domain.HSPipeline = {
      canonicalId: domain.canonicalIdForPipeline({
        objectType,
        id: apiPipeline.id,
      }),
      objectType,
      id: apiPipeline.id,
      label: apiPipeline.label,
      displayOrder: apiPipeline.displayOrder,
      stages,
    };

    return pipeline;
  });

  return pipelines;
}

async function getAccountInfo(
  authState: domain.AuthState,
): Promise<HubSpotAPITypes.HubSpotAccountInfoResponse> {
  const res = await fetchRateLimited(`${baseUrl}/account-info/v3/details`, {
    method: "GET",
    headers: {
      ...headersWithAuth(authState),
    },
  });

  const body = await res.json();
  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotAccountInfoResponse,
    body,
  );

  return decoded;
}

export async function fetchAccountDetails(params: {
  authState: domain.AuthState;
}): Promise<domain.HGAccountDetails> {
  const { authState } = params;

  const res = await fetchRateLimited(`${baseUrl}/account-info/v3/details`, {
    method: "GET",
    headers: {
      ...headersWithAuth(authState),
    },
  });

  const body = await res.json();
  const decoded = parseOrThrow(
    HubSpotAPITypes.HubSpotAccountInfoResponse,
    body,
  );

  const hgAccountDetails: domain.HGAccountDetails = {
    portalId: `${decoded.portalId}`,
    timeZone: decoded.timeZone,
    companyCurrency: decoded.companyCurrency,
    utcOffsetMilliseconds: decoded.utcOffsetMilliseconds,
    utcOffset: decoded.utcOffset,
    uiDomain: decoded.uiDomain,
  };

  return hgAccountDetails;
}

export async function createProperty(params: {
  authState: domain.AuthState;

  objectType: string;
  // TODO: better type
  property: { name: string; label: string; type: string } & Record<
    string,
    unknown
  >;
}): Promise<void> {
  const { authState, objectType, property } = params;

  await fetchRateLimited(`${baseUrl}/crm/v3/properties/${objectType}`, {
    method: "POST",
    headers: {
      ...headersWithAuth(authState),
    },
    body: JSON.stringify(property),
  });
}

export async function createPropertyGroup(params: {
  authState: domain.AuthState;

  objectType: string;
  // TODO: better type
  group: { name: string; label: string };
}): Promise<void> {
  const { authState, objectType, group } = params;

  await fetchRateLimited(`${baseUrl}/crm/v3/properties/${objectType}/groups`, {
    method: "POST",
    headers: {
      ...headersWithAuth(authState),
    },
    body: JSON.stringify(group),
  });
}
