import React, {
  DependencyList,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import * as domain from "../domain";
import _ from "lodash";
import * as d3 from "d3";
import { assert, assertNever, isNotUndefined } from "../utils";
import { useStore } from "../hooks/hooks";
import { observer } from "mobx-react-lite";
import * as actions from "../actions";
import { CardShape, TDDocument } from "@orgcharthub/tldraw-tldraw";
import * as connection from "../domain/connection";
import { colors } from "../theme";
import {
  labelPointsForConnectionPath,
  shapeByObjectRef,
} from "../domain/canvas";
import { useTLApp } from "../hooks/hooks";
import {
  calculateLabelBoundingBoxes,
  LabelBoundsResult,
} from "../utils/place-labels";
import { measureLabelMemo } from "../utils/measure-label";

type Vector = [x: number, y: number];
type Size = [width: number, height: number];
type Rect = { x: number; y: number; width: number; height: number };

const useD3 = (
  renderFn: (
    selection: d3.Selection<SVGSVGElement, null, null, undefined>,
  ) => void,
  dependencies: DependencyList,
) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    return renderFn(d3.select(ref.current as SVGSVGElement));
  }, dependencies);

  return ref;
};

const d3CableRenderer = d3
  .line<{ x: number; y: number }>()
  .x((d) => d.x)
  .y((d) => d.y)
  .curve(d3.curveBasis);

const d3NonPhysicsCableRenderer = d3
  .line<{ x: number; y: number }>()
  .x((d) => d.x)
  .y((d) => d.y);

type CableLabelDef = {
  type: "sideA" | "sideB" | "middle";
  label: string;
  debug: {
    canonicalAssociationLabelId?: string;
    hgLabelPair: connection.HGLabelPair;
    hgLabel: connection.HGLabel;
  };
};

const InteractiveNonPhysicsCable: React.FC<{
  aPoint: Vector;
  bPoint: Vector;
  aSize?: Size;
  bSize?: Size;
  labelDefs?: CableLabelDef[];
  onClick: () => void;
}> = (props) => {
  const { aPoint, bPoint, labelDefs, onClick, aSize, bSize } = props;

  const middleLabels = (labelDefs || []).filter(
    (labelDef) => labelDef.type === "middle",
  );
  const sideALabels = (labelDefs || []).filter(
    (labelDef) => labelDef.type === "sideA",
  );
  const sideBLabels = (labelDefs || []).filter(
    (labelDef) => labelDef.type === "sideB",
  );

  const cableRef = useRef<d3.Selection<
    SVGGElement,
    null,
    null,
    undefined
  > | null>(null);

  const [cablePath, setCablePath] = useState<string | null>(null);

  const ref = useD3((selection) => {
    const cable = selection.append("g");

    const innerPath = cable
      .append("path")
      .attr("stroke-width", 5)
      .attr("stroke", colors.slate[500])
      .attr("stroke-linecap", "round")
      .attr("fill", "none")
      .attr("class", "drop-shadow-md")
      .attr("data-path-type", "inner");

    cable
      .append("path")
      .attr("stroke-width", 60)
      .attr("stroke", "transparent")
      .attr("fill", "none")
      .attr("class", "cursor-pointer pointer-events-auto")
      .attr("data-path-type", "outer")
      .on(
        "pointerdown",
        (e) => {
          e.stopPropagation();
          innerPath.transition().duration(100).attr("stroke-width", 16);
        },
        { capture: true },
      )
      .on("pointerup", () => {
        innerPath.transition().duration(100).attr("stroke-width", 10);
      })
      .on("click", () => {
        onClick();
      })
      .on("mouseover", () => {
        innerPath.transition().duration(100).attr("stroke-width", 10);
      })
      .on("mouseout", () => {
        innerPath.transition().duration(100).attr("stroke-width", 6);
      });

    cableRef.current = cable;

    return () => {
      cable.remove();
    };
  }, []);

  useEffect(() => {
    const cable = cableRef.current;
    if (cable) {
      const outerPath = cable.select("[data-path-type='outer']");
      const innerPath = cable.select("[data-path-type='inner']");

      const pathPoints = [
        { x: aPoint[0], y: aPoint[1] },
        { x: bPoint[0], y: bPoint[1] },
      ];
      innerPath.datum({ nodes: pathPoints });

      innerPath.attr("d", (_d) => {
        const d = _d as unknown as {
          nodes: { x: number; y: number }[];
        };
        return d3NonPhysicsCableRenderer(d.nodes);
      });

      outerPath.attr("d", innerPath.attr("d"));
      setCablePath(innerPath.attr("d"));
    }
  }, [aPoint[0], aPoint[1], bPoint[0], bPoint[1]]);

  let labelBoundsResult: LabelBoundsResult | undefined;
  if (aSize && bSize) {
    const sideALabelGroupSize =
      sideALabels.length > 0
        ? calculateAssociationLabelGroupSize(sideALabels)
        : null;
    const sideBLabelGroupSize =
      sideBLabels.length > 0
        ? calculateAssociationLabelGroupSize(sideBLabels)
        : null;
    const middleLabelGroupSize =
      middleLabels.length > 0
        ? calculateAssociationLabelGroupSize(middleLabels)
        : null;

    labelBoundsResult = calculateLabelBoundingBoxes({
      aBounds: {
        x: aPoint[0] - aSize[0] / 2,
        y: aPoint[1] - aSize[1] / 2,
        width: aSize[0],
        height: aSize[1],
      },
      bBounds: {
        x: bPoint[0] - bSize[0] / 2,
        y: bPoint[1] - bSize[1] / 2,
        width: bSize[0],
        height: bSize[1],
      },
      sideALabelGroupSize,
      sideBLabelGroupSize,
      middleLabelGroupSize,
    });
  }

  return (
    <g>
      <svg className="overflow-visible" ref={ref} />

      {labelBoundsResult && labelBoundsResult.type === "collapsed" && (
        <AssociationLabelGroupCollapsed
          point={[labelBoundsResult.center[0], labelBoundsResult.center[1]]}
        />
      )}

      {labelBoundsResult && labelBoundsResult.type === "placed" && (
        <>
          {labelBoundsResult.sideARect && (
            <>
              <AssociationLabelGroup
                labelDefs={sideALabels}
                point={[
                  labelBoundsResult.sideARect.x +
                    labelBoundsResult.sideARect.width / 2,
                  labelBoundsResult.sideARect.y +
                    labelBoundsResult.sideARect.height / 2,
                ]}
              />
            </>
          )}

          {labelBoundsResult.sideBRect && (
            <>
              <AssociationLabelGroup
                labelDefs={sideBLabels}
                point={[
                  labelBoundsResult.sideBRect.x +
                    labelBoundsResult.sideBRect.width / 2,
                  labelBoundsResult.sideBRect.y +
                    labelBoundsResult.sideBRect.height / 2,
                ]}
              />
            </>
          )}

          {labelBoundsResult.middleRect && (
            <>
              <AssociationLabelGroup
                labelDefs={middleLabels}
                point={[
                  labelBoundsResult.middleRect.x +
                    labelBoundsResult.middleRect.width / 2,
                  labelBoundsResult.middleRect.y +
                    labelBoundsResult.middleRect.height / 2,
                ]}
              />
            </>
          )}
        </>
      )}
    </g>
  );
};

const InteractiveCable: React.FC<{
  aPoint: Vector;
  bPoint: Vector;
  aSize?: Size;
  bSize?: Size;
  labelDefs?: CableLabelDef[];
  onClick: () => void;
}> = (props) => {
  const { aPoint, bPoint, labelDefs, onClick, aSize, bSize } = props;

  const middleLabels = (labelDefs || []).filter(
    (labelDef) => labelDef.type === "middle",
  );
  const sideALabels = (labelDefs || []).filter(
    (labelDef) => labelDef.type === "sideA",
  );
  const sideBLabels = (labelDefs || []).filter(
    (labelDef) => labelDef.type === "sideB",
  );

  const numberOfCableSegments = 5;
  const stepX = (bPoint[0] - aPoint[0]) / numberOfCableSegments;
  const stepY = (bPoint[1] - aPoint[1]) / numberOfCableSegments;

  type CableNode = {
    fx?: number;
    fy?: number;
    x?: number;
    y?: number;
  };

  const nodes: CableNode[] = _.map(
    _.range(0, numberOfCableSegments + 1),
    (n) => {
      return { x: aPoint[0] + stepX * n, y: aPoint[1] + stepY * n };
    },
  );

  // force to a point 100px away from the midpoint between the start and end y position
  const forceY = _.chain([aPoint, bPoint])
    .map((point) => point[1])
    .sum()
    .divide(2)
    .add(1000)
    .value();

  const cableRef = useRef<d3.Selection<
    SVGGElement,
    null,
    null,
    undefined
  > | null>(null);

  const [cablePath, setCablePath] = useState<string | null>(null);

  const ref = useD3((selection) => {
    const cable = selection.append("g");

    const innerPath = cable
      .append("path")
      .attr("stroke-width", 5)
      .attr("stroke", colors.slate[500])
      .attr("stroke-linecap", "round")
      .attr("fill", "none")
      .attr("class", "drop-shadow-md")
      .attr("data-path-type", "inner");

    const outerPath = cable
      .append("path")
      .attr("stroke-width", 60)
      .attr("stroke", "transparent")
      .attr("fill", "none")
      .attr("class", "cursor-pointer pointer-events-auto")
      .attr("data-path-type", "outer")
      .on(
        "pointerdown",
        (e) => {
          e.stopPropagation();
          innerPath.transition().duration(100).attr("stroke-width", 16);
        },
        { capture: true },
      )
      .on("pointerup", () => {
        innerPath.transition().duration(100).attr("stroke-width", 10);
      })
      .on("click", () => {
        onClick();
      })
      .on("mouseover", () => {
        innerPath.transition().duration(100).attr("stroke-width", 10);
      })
      .on("mouseout", () => {
        innerPath.transition().duration(100).attr("stroke-width", 6);
      });

    cableRef.current = cable;

    return () => {
      cable.remove();
    };
  }, []);

  useEffect(() => {
    const cable = cableRef.current;
    if (cable) {
      const outerPath = cable.select("[data-path-type='outer']");
      const innerPath = cable.select("[data-path-type='inner']");

      const pathPoints = [
        { x: aPoint[0], y: aPoint[1] },
        { x: bPoint[0], y: bPoint[1] },
      ];
      innerPath.datum({ nodes: pathPoints });

      innerPath.attr("d", (_d) => {
        const d = _d as unknown as {
          nodes: { x: number; y: number }[];
        };
        return d3NonPhysicsCableRenderer(d.nodes);
      });

      outerPath.attr("d", innerPath.attr("d"));
      setCablePath(innerPath.attr("d"));
    }
  }, [aPoint[0], aPoint[1], bPoint[0], bPoint[1]]);

  const labelPoints2 = useMemo(() => {
    if (!cablePath || !aSize || !bSize) {
      return;
    }

    const connectionNode = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );
    connectionNode.setAttribute("d", cablePath);

    const aRect = {
      x: aPoint[0] - aSize[0] / 2,
      y: aPoint[1] - aSize[1] / 2,
      width: aSize[0],
      height: aSize[1],
    };

    const bRect = {
      x: bPoint[0] - bSize[0] / 2,
      y: bPoint[1] - bSize[1] / 2,
      width: bSize[0],
      height: bSize[1],
    };

    const points = labelPointsForConnectionPath({
      connectionNode,
      aRect,
      bRect,
    });

    return points;
  }, [cablePath, aPoint, bPoint, aSize, bSize]);

  return (
    <g>
      <svg className="overflow-visible" ref={ref} />

      {labelPoints2 && labelPoints2.a && (
        <AssociationLabelGroup labelDefs={sideALabels} point={labelPoints2.a} />
      )}
      {labelPoints2 && labelPoints2.b && (
        <AssociationLabelGroup labelDefs={sideBLabels} point={labelPoints2.b} />
      )}
      {labelPoints2 && labelPoints2.midpoint && (
        <AssociationLabelGroup
          labelDefs={middleLabels}
          point={labelPoints2.midpoint}
        />
      )}
    </g>
  );
};

function makeAssociationLabelMeasurements(params: {
  position: [number, number];
  label: string;
}): {
  textRect: Rect;
  containerRect: Rect;
} {
  const { position, label } = params;

  const fontFamily =
    '"Lexend Deca", ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
  const textMetrics = measureLabelMemo(label, `400 16px/1 ${fontFamily}`);

  const labelPaddingX = 10;
  const labelPaddingY = 5;

  const textRect: Rect = {
    x: Math.ceil(position[0] - (textMetrics ? textMetrics.width / 2 : 0)),
    y: Math.ceil(position[1] - (textMetrics ? textMetrics.height / 2 : 0)) - 3,
    width: textMetrics ? textMetrics.width : 0,
    height: textMetrics ? textMetrics.height : 0,
  };

  const containerRect: Rect = {
    x: position[0] - textRect.width / 2 - labelPaddingX,
    y: position[1] - textRect.height / 2 - labelPaddingY,
    width: textRect.width + labelPaddingX * 2,
    height: textRect.height + labelPaddingY * 2,
  };

  return {
    textRect,
    containerRect: containerRect,
  };
}

const AssociationLabel = React.memo(
  (props: {
    position: Vector;
    label: string;
    fillColor: string;
    textColor: string;
  }) => {
    const { position, label, fillColor, textColor } = props;

    const { containerRect, textRect } = makeAssociationLabelMeasurements({
      position,
      label,
    });

    return (
      <g>
        <rect
          fill={fillColor}
          x={containerRect.x}
          y={containerRect.y}
          width={containerRect.width}
          height={containerRect.height}
          rx={3}
          className="drop-shadow"
        ></rect>
        <text
          x={textRect.x}
          y={textRect.y}
          width={textRect.width}
          height={textRect.height}
          dy={16}
          textAnchor="start"
          fill={textColor}
          fontWeight="400"
          fontSize={16}
        >
          {label}
        </text>
      </g>
    );
  },
  _.isEqual,
);

function AssociationLabelGroupCollapsed(props: { point: Vector }) {
  const { point } = props;

  const fillColor = colors.slate[500];
  const textColor = colors.slate[200];
  const width = 40;
  const height = 30;

  return (
    <g className="pointer-events-none select-none">
      <rect
        fill={fillColor}
        x={point[0] - width / 2}
        y={point[1] - height / 2}
        width={width}
        height={height}
        rx={3}
        className="drop-shadow"
      ></rect>
      <text
        x={point[0] - 10}
        y={point[1] - 12}
        dy={16}
        textAnchor="start"
        fill={textColor}
        fontWeight="400"
        fontSize={32}
      >
        ...
      </text>
    </g>
  );
}

function calculateAssociationLabelGroupSize(labels: CableLabelDef[]): Size {
  const labelSizes = labels.map((label) => {
    return makeAssociationLabelMeasurements({
      position: [0, 0],
      label: label.label,
    });
  });

  const labelMaxWidth = Math.max(
    ...labelSizes.map((measurement) => measurement.containerRect.width),
  );

  const labelYPadding = 6;
  const labelHeight = labelSizes
    .map((measurement, i) => {
      const heightWithPadding =
        measurement.containerRect.height +
        (i < labelSizes.length - 1 ? labelYPadding : 0);
      return heightWithPadding;
    })
    .reduce((a, b) => a + b, 0);

  return [labelMaxWidth, labelHeight];
}

function AssociationLabelGroup(props: {
  labelDefs: CableLabelDef[];
  point: Vector;
}) {
  const { labelDefs, point } = props;

  const sortedLabelDefs = _.sortBy(labelDefs, (labelDef) => labelDef.label);

  const groupSize = calculateAssociationLabelGroupSize(sortedLabelDefs);

  const singleLabelHeight = groupSize[1] / sortedLabelDefs.length;
  const groupHeight = groupSize[1];
  const groupOffset = groupHeight / 2 - singleLabelHeight / 2;

  return (
    <g className="pointer-events-none select-none">
      {sortedLabelDefs.map((labelDef, i) => {
        const { label } = labelDef;

        const yOffset = singleLabelHeight * i - groupOffset;

        const position: Vector = [point[0], point[1] + yOffset];

        return (
          <AssociationLabel
            key={i}
            position={position}
            label={label}
            fillColor={colors.slate[500]}
            textColor={colors.slate[200]}
          />
        );
      })}
    </g>
  );
}

function hgLablePairToCableLabelDefs(params: {
  appliedLabelPair: connection.HGAppliedLabelPair;
  hgLabelPair: connection.HGLabelPair;
}): CableLabelDef[] {
  const { appliedLabelPair, hgLabelPair } = params;
  assert(
    hgLabelPair.type === "primary" ||
      hgLabelPair.type === "single-label" ||
      hgLabelPair.type === "paired-label",
  );
  if (hgLabelPair.type === "single-label") {
    const singleLabel = connection.singleLabelDisplayLabel(hgLabelPair);
    return [
      {
        label: singleLabel,
        type: "middle",
        debug: {
          hgLabelPair,
          hgLabel: hgLabelPair.hgLabels.hgLabelA,
        },
      },
    ];
  } else if (hgLabelPair.type === "paired-label") {
    const sideAHGLabel =
      appliedLabelPair.objectATypeId === hgLabelPair.hgLabels.hgLabelA.typeId
        ? hgLabelPair.hgLabels.hgLabelA
        : hgLabelPair.hgLabels.hgLabelB;
    const sideBHGLabel =
      appliedLabelPair.objectATypeId === hgLabelPair.hgLabels.hgLabelA.typeId
        ? hgLabelPair.hgLabels.hgLabelB
        : hgLabelPair.hgLabels.hgLabelA;

    return [
      {
        label: sideAHGLabel.label,
        type: "sideA",
        debug: {
          hgLabelPair,
          hgLabel: sideAHGLabel,
        },
      },
      {
        label: sideBHGLabel.label,
        type: "sideB",
        debug: {
          hgLabelPair,
          hgLabel: sideBHGLabel,
        },
      },
    ];
  } else if (hgLabelPair.type === "primary") {
    return [
      {
        type: "middle",
        label: "Primary Company",
        debug: {
          hgLabelPair,
          hgLabel: hgLabelPair.hgLabels.hgLabelA,
        },
      },
    ];
  } else {
    assertNever(hgLabelPair);
  }
}

const CanvasConnectionEdge: React.FC<{
  hgConnection: connection.HGConnection;
  sourceShape: CardShape;
  targetShape: CardShape;
}> = observer((props) => {
  const { hgConnection, sourceShape, targetShape } = props;

  if (_.isEmpty(hgConnection.appliedLabelPairs)) {
    return null;
  }

  if (
    !domain.isCardShapeHubSpot(sourceShape) ||
    !domain.isCardShapeHubSpot(targetShape)
  ) {
    return null;
  }

  const store = useStore();

  const sourceSize = store.cardSizeCache[sourceShape.id] || [340, 410];
  const targetSize = store.cardSizeCache[targetShape.id] || [340, 410];

  const sourcePoint: Vector = useMemo(() => {
    return [
      sourceShape.point[0] + sourceSize[0] / 2,
      sourceShape.point[1] + sourceSize[1] / 2,
    ];
  }, [...sourceShape.point, ...sourceSize]);
  const targetPoint: Vector = useMemo(() => {
    return [
      targetShape.point[0] + targetSize[0] / 2,
      targetShape.point[1] + targetSize[1] / 2,
    ];
  }, [...targetShape.point, ...targetSize]);

  const labelTags = hgConnection.appliedLabelPairs
    .map((appliedLabelPair) => {
      const hgLabelPair = store.hgLabelPairs[
        appliedLabelPair.labelPairCanonicalId
      ] as connection.HGLabelPair | undefined;
      if (!hgLabelPair) {
        return undefined;
      }
      return { appliedLabelPair, hgLabelPair };
    })
    .filter(isNotUndefined)
    .filter(({ hgLabelPair }) => hgLabelPair.type !== "unlabelled")
    .map(({ appliedLabelPair, hgLabelPair }) => {
      return hgLablePairToCableLabelDefs({ appliedLabelPair, hgLabelPair });
    })
    .flatMap((cableLabelDef) => cableLabelDef);

  return (
    <InteractiveNonPhysicsCable
      onClick={() => {
        actions.startEditingConnection({
          shapeIdA: sourceShape.id,
          shapeIdB: targetShape.id,
        });
      }}
      aPoint={sourcePoint}
      bPoint={targetPoint}
      aSize={sourceSize}
      bSize={targetSize}
      labelDefs={labelTags}
    />
  );
});

export const CanvasConnectionsEdgeLayer = observer(() => {
  const store = useStore();
  const tlApp = useTLApp();

  const hgConnections = Object.values(store.hgConnections);

  // force a read of the document notify update state so we can move links based on document updates (e.g. shape moved)
  store.documentNotifyUpdate;

  const shapes = Object.values(
    (tlApp.document as TDDocument).pages.page_1.shapes,
  );

  return (
    <svg
      data-layer={"CanvasConnectionsEdgeLayer"}
      className="pointer-events-none"
      style={{
        position: "absolute",
        transform: "translate(0, 0)",
        width: "calc(0px + (var(--tl-padding) * 2))",
        height: "calc(0px + (var(--tl-padding) * 2))",
        transformOrigin: "0 0",
        contain: "layout style size",
        overflow: "visible",
      }}
    >
      {hgConnections.map((hgConnection) => {
        const objectRefA = hgConnection.objectRefA;
        const objectRefB = hgConnection.objectRefB;

        const sourceShape = shapeByObjectRef(shapes, objectRefA);
        const targetShape = shapeByObjectRef(shapes, objectRefB);

        if (!sourceShape || !targetShape) {
          return;
        }

        return (
          <React.Fragment key={hgConnection.canonicalId}>
            <CanvasConnectionEdge
              hgConnection={hgConnection}
              sourceShape={sourceShape}
              targetShape={targetShape}
            />
            {/* <DebugSurface /> */}
          </React.Fragment>
        );
      })}
    </svg>
  );
});
