import _ from "lodash";
import * as ts from "io-ts";
import reporters from "io-ts-reporters";
import { report, reportOne } from "io-ts-human-reporter";
import PrettyPrintIoTsErrors from "io-ts-better-union-error-reporter/dist/PrettyPrintIoTsErrors";
import { Left, isLeft } from "fp-ts/Either";
import {
  DEV_CACHE_ENABLED,
  IS_PROD,
  LOCALSTORAGE_APP_CACHE_VERSION,
} from "../config";

export function assertNever(value: never): never {
  throw Error(`Unexpected value '${value}'`);
}

export function assert(condition: any, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function indexBy<T>(
  coll: T[],
  keyFn: (item: T) => string,
): Record<string, T> {
  return _.reduce(
    coll,
    (acc, item) => {
      const id = keyFn(item);
      acc[id] = item;
      return acc;
    },
    {} as Record<string, T>,
  );
}

export function makeParseErrorGeneric<T>(result: Left<ts.Errors>) {
  console.error(PrettyPrintIoTsErrors(result.left));
  const messages = reporters.report(result).join("\n");
  throw new Error(`Could not parse: ${messages}`);
}

export function parseOrThrow<A, O = A>(
  from: ts.Type<A, O, unknown>,
  data: unknown,
): A {
  const decoded = from.decode(data);
  if (isLeft(decoded)) {
    throw makeParseErrorGeneric(decoded);
  }
  return decoded.right;
}

export async function withLocalDevCache<T>(
  cacheKey: string,
  fn: () => Promise<T>,
): Promise<T> {
  if (IS_PROD || !DEV_CACHE_ENABLED) {
    return await fn();
  }

  let versionedCacheKey = `${LOCALSTORAGE_APP_CACHE_VERSION}:${cacheKey}`;

  const cachedValue = localStorage.getItem(versionedCacheKey);
  if (cachedValue) {
    return JSON.parse(cachedValue) as T;
  } else {
    const v = await fn();
    localStorage.setItem(versionedCacheKey, JSON.stringify(v));
    return v;
  }
}

export function isNotUndefined<T>(i: T | undefined): i is T {
  return i !== undefined;
}

export function isNotNull<T>(i: T | null): i is T {
  return i !== null;
}

export function isInIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function closeHubSpotIframeModal(action: "DONE"): void {
  try {
    window.parent.postMessage(JSON.stringify({ action: action }), "*");
  } catch (e) {}
}

export function isNonEmptyString(v: string | null | undefined): v is string {
  return typeof v === "string" && v.trim() !== "";
}
