import _ from "lodash";

let melm: HTMLPreElement | undefined;

function getMeasurementDiv(): HTMLPreElement {
  // A div used for measurement
  document.getElementById("__textLabelMeasure")?.remove();

  const pre = document.createElement("pre");
  pre.id = "__textLabelMeasure";

  Object.assign(pre.style, {
    whiteSpace: "pre",
    width: "auto",
    border: "none",
    padding: "0",
    margin: "0px",
    letterSpacing: "0",
    opacity: "0",
    position: "absolute",
    top: "-500px",
    left: "0px",
    zIndex: "9999",
    pointerEvents: "none",
    userSelect: "none",
    alignmentBaseline: "mathematical",
    dominantBaseline: "mathematical",
  });

  pre.tabIndex = -1;

  document.body.appendChild(pre);
  return pre;
}

if (typeof window !== "undefined") {
  melm = getMeasurementDiv();
}

export function measureLabel(
  text: string,
  font: string,
): {
  width: number;
  height: number;
} {
  if (!text) {
    return { width: 16, height: 32 };
  }

  if (!melm) {
    return { width: 10, height: 10 };
  }

  if (!melm.parentElement) document.body.appendChild(melm);

  melm.textContent = text;
  melm.style.font = font;

  // In tests, offsetWidth and offsetHeight will be 0
  const width = melm.offsetWidth || 1;
  const height = melm.offsetHeight || 1;

  return {
    width,
    height,
  };
}

export const measureLabelMemo = _.memoize(measureLabel, (text, font) => {
  return `${text}::${font}`;
});
