import React, { useCallback } from "react";
import type { TldrawApp } from "@orgcharthub/tldraw-tldraw";
import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import {
  createRelationshipMapAsset,
  deleteRelationshipMapAsset,
} from "../api/firebase";
import _ from "lodash";

export function useAssetManagement(
  tlApp: TldrawApp,
  portalId: string,
  mapId: string,
) {
  const onAssetCreate = useCallback(
    async (tlApp: TDDraw.TldrawApp, file: File, id: string) => {
      console.log("onAssetCreate", { file, id });

      const asset = await createRelationshipMapAsset({
        file,
        portalId,
        mapId,
        assetId: id,
      });

      return asset.ref.getDownloadURL();
    },
    [],
  );

  const onAssetDelete = useCallback(
    async (tlApp: TDDraw.TldrawApp, assetId: string) => {
      console.log("onAssetDelete", { assetId });

      const asset = tlApp.assets.find((asset) => asset.id === assetId);

      if (asset) {
        const fileName: string =
          _.get(asset as unknown, "name") ||
          _.get(asset as unknown, "fileName");

        await deleteRelationshipMapAsset({
          portalId,
          mapId,
          assetId: asset.id,
          fileName,
        });
      }
    },
    [],
  );
  return { onAssetCreate, onAssetDelete };
}
