import {
  canonicalIdForHGObjectRef,
  HGObjectRef,
  HGObjectTypePair,
  objectRefsEqual,
} from "../domain";
import * as ts from "io-ts";
import _, { cloneDeep } from "lodash";
import { assert, assertNever, indexBy, isNotUndefined } from "../utils";
import {
  HubSpotAssociationsBatchReadAPIResponse,
  HubSpotObjectsGetAPIResponse,
  HubSpotAPIAssociationLabelWithFromToObjectTypes,
  HubSpotObjectsGetAPIResponseAssociationResult,
  HubSpotAPIAssociation,
} from "../api/hubspot-api-types";
import * as objectTypesDomain from "./object-types";

/* the typeId of the association applied to the 'parent' company in a parent/child association */
export const PARENT_TO_CHILD_COMPANY_LABEL_HUBSPOT_TYPE_ID = 13;
/* the typeId of the association applied to the 'child' company in a parent/child association */
export const CHILD_TO_PARENT_COMPANY_LABEL_HUBSPOT_TYPE_ID = 14;

// what's the identity of a label definition? (how do we know that we are talking about the same association label?)
//
// - obvious ones
//   - fromObjectType
//   - toObjectType
//
// - the label pair on the association (e.g. 'Primary' + 'Primary Company' = the built-in primary association)
// - probably category (e.g. 'HUBSPOT_DEFINED', 'USER_DEFINED', 'INTEGRATOR_DEFINED')

// - primary associations are a bit weird because you can only have one and you MUST have one
//   - 1-1 association + MUST have
// - that’s where it differs from parent/child associations
//   - 1 parent can have many childs
//   - 1 child can have many parents, but allowed to not have any

const HGLabelCategory = ts.union([
  ts.literal("HUBSPOT_DEFINED"),
  ts.literal("USER_DEFINED"),
  ts.literal("INTEGRATOR_DEFINED"),
]);
export type HGLabelCategory = ts.TypeOf<typeof HGLabelCategory>;

const HGLabelUnlabelled = ts.type({
  objectType: ts.string,
  otherObjectType: ts.string,
  category: HGLabelCategory,
  typeId: ts.number,
  label: ts.null,
  name: ts.union([ts.string, ts.undefined]),
});
type HGLabelUnlabelled = ts.TypeOf<typeof HGLabelUnlabelled>;
const HGLabelLabelled = ts.type({
  objectType: ts.string,
  otherObjectType: ts.string,
  category: HGLabelCategory,
  typeId: ts.number,
  label: ts.string,
  name: ts.union([ts.string, ts.undefined]),
});
type HGLabelLabelled = ts.TypeOf<typeof HGLabelLabelled>;
export const HGLabel = ts.union([HGLabelUnlabelled, HGLabelLabelled]);
export type HGLabel = ts.TypeOf<typeof HGLabel>;

const HGLabelPairUnlabelled = ts.type({
  canonicalId: ts.string,
  category: HGLabelCategory,
  type: ts.literal("unlabelled"),
  hgLabels: ts.type({
    hgLabelA: HGLabelUnlabelled,
    hgLabelB: HGLabelUnlabelled,
  }),
});
type HGLabelPairUnlabelled = ts.TypeOf<typeof HGLabelPairUnlabelled>;
const HGLabelPairPrimary = ts.type({
  canonicalId: ts.string,
  category: HGLabelCategory,
  type: ts.literal("primary"),
  hgLabels: ts.type({
    hgLabelA: HGLabelLabelled,
    hgLabelB: HGLabelLabelled,
  }),
});
type HGLabelPairPrimary = ts.TypeOf<typeof HGLabelPairPrimary>;
const HGLabelPairSingleLabel = ts.type({
  canonicalId: ts.string,
  category: HGLabelCategory,
  type: ts.literal("single-label"),
  hgLabels: ts.type({
    hgLabelA: HGLabelLabelled,
    hgLabelB: HGLabelLabelled,
  }),
});
type HGLabelPairSingleLabel = ts.TypeOf<typeof HGLabelPairSingleLabel>;
const HGLabelPairPairedLabel = ts.type({
  canonicalId: ts.string,
  category: HGLabelCategory,
  type: ts.literal("paired-label"),
  hgLabels: ts.type({
    hgLabelA: HGLabelLabelled,
    hgLabelB: HGLabelLabelled,
  }),
});
type HGLabelPairPairedLabel = ts.TypeOf<typeof HGLabelPairPairedLabel>;

export const HGLabelPair = ts.union([
  HGLabelPairUnlabelled,
  HGLabelPairPrimary,
  HGLabelPairSingleLabel,
  HGLabelPairPairedLabel,
]);
export type HGLabelPair = ts.TypeOf<typeof HGLabelPair>;

export function isSingleLabelLabelPair(
  hgLabelPair: HGLabelPair,
): hgLabelPair is HGLabelPairSingleLabel {
  return hgLabelPair.type === "single-label";
}

export function isPrimaryLabelPair(
  hgLabelPair: HGLabelPair,
): hgLabelPair is HGLabelPairPrimary {
  return hgLabelPair.type === "primary";
}

export function isSingleLabelOrPrimaryLabelPair(
  hgLabelPair: HGLabelPair,
): hgLabelPair is HGLabelPairSingleLabel | HGLabelPairPrimary {
  return isSingleLabelLabelPair(hgLabelPair) || isPrimaryLabelPair(hgLabelPair);
}

export function primaryLabelPairPrimaryCompanyHGLabel(
  hgLabelPair: HGLabelPairPrimary,
): HGLabelLabelled {
  return hgLabelPair.hgLabels.hgLabelA.objectType === "company"
    ? hgLabelPair.hgLabels.hgLabelA
    : hgLabelPair.hgLabels.hgLabelB;
}

export function labelPairMentionedObjectTypes(
  labelPair: HGLabelPair,
): HGObjectTypePair {
  return [
    labelPair.hgLabels.hgLabelA.objectType,
    labelPair.hgLabels.hgLabelB.objectType,
  ].sort() as HGObjectTypePair;
}

export function labelPairInvolvesObjectType(
  labelPair: HGLabelPair,
  objectType: string,
): boolean {
  return labelPairMentionedObjectTypes(labelPair).includes(objectType);
}

export function labelPairInvolvesBothObjectTypes(
  labelPair: HGLabelPair,
  objectTypePair: HGObjectTypePair,
): boolean {
  return objectTypesDomain.objectTypesListsEqual(
    labelPairMentionedObjectTypes(labelPair),
    objectTypePair,
  );
}

export function labelPairIsSameObjectLabelPair(
  labelPair: HGLabelPair,
  objectType: string,
): boolean {
  return (
    labelPair.hgLabels.hgLabelA.objectType === objectType &&
    labelPair.hgLabels.hgLabelB.objectType === objectType
  );
}

/**
 * Returns true if this is the `HGLabelPair` that represents the
 * unlabelled association between two object types.
 */
export function labelPairIsUnlabelledPairForObjectTypes(params: {
  labelPair: HGLabelPair;
  objectTypeA: string;
  objectTypeB: string;
}): boolean {
  const { labelPair, objectTypeA, objectTypeB } = params;
  return (
    labelPairInvolvesObjectType(labelPair, objectTypeA) &&
    labelPairInvolvesObjectType(labelPair, objectTypeB) &&
    labelPair.type === "unlabelled"
  );
}

export function oppositeTypeIdForHGLabelPair(
  hgLabelPair: HGLabelPair,
  typeId: number,
): number {
  const labelA = hgLabelPair.hgLabels.hgLabelA;
  const labelB = hgLabelPair.hgLabels.hgLabelB;
  return labelA.typeId === typeId ? labelB.typeId : labelA.typeId;
}

export function hsLabelsForLabelPair(
  labelPair: HGLabelPair,
): [HGLabel, HGLabel] {
  return [labelPair.hgLabels.hgLabelA, labelPair.hgLabels.hgLabelB];
}

export function singleLabelDisplayLabel(
  hgLabelPair: HGLabelPairSingleLabel,
): string {
  return hgLabelPair.hgLabels.hgLabelA.label;
}

export function displayLabelForObjectRef(params: {
  hgConnection: HGConnection;
  appliedLabelPair: HGAppliedLabelPair;
  hgObjectRef: HGObjectRef;
  hgLabelPairs: HGLabelPair[];
}): string | undefined {
  const { hgConnection, appliedLabelPair, hgObjectRef, hgLabelPairs } = params;

  const hgLabelPair = hgLabelPairs.find((hgLabelPair) => {
    return hgLabelPair.canonicalId === appliedLabelPair.labelPairCanonicalId;
  });

  if (!hgLabelPair || hgLabelPair.type === "unlabelled") {
    return;
  }

  const appliedTypeId = objectRefsEqual(hgConnection.objectRefA, hgObjectRef)
    ? appliedLabelPair.objectATypeId
    : appliedLabelPair.objectBTypeId;

  const hgLabel =
    hgLabelPair.hgLabels.hgLabelA.typeId === appliedTypeId
      ? hgLabelPair.hgLabels.hgLabelA
      : hgLabelPair.hgLabels.hgLabelB;

  return hgLabel.label;
}

type CanonicalIDForLabelPairParams = {
  objectTypePair: HGObjectTypePair;
} & (
  | { primary: true }
  | { unlabelled: true }
  | { label: string }
  | { labelPair: [string, string] }
);
export function canonicalIdForHGLabelPair(
  params: CanonicalIDForLabelPairParams,
): string {
  const objectTypePart = params.objectTypePair.sort().join("/");
  const primaryPart =
    "primary" in params && params.primary ? "[primary]" : "[not-primary]";
  const unlabledPart =
    "unlabelled" in params && params.unlabelled ? "[unlabelled]" : "[labelled]";
  let labelPart: string;
  if ("label" in params) {
    labelPart = [params.label, params.label].join("/");
  } else if ("labelPair" in params) {
    labelPart = params.labelPair.sort().join("/");
  } else {
    labelPart = "[unlabelled]";
  }
  return [
    "HGLabelPair",
    objectTypePart,
    primaryPart,
    unlabledPart,
    labelPart,
  ].join(":");
}

export const HGAppliedLabelPair = ts.type({
  objectATypeId: ts.number,
  objectBTypeId: ts.number,
  labelPairCanonicalId: ts.string,
});
export type HGAppliedLabelPair = ts.TypeOf<typeof HGAppliedLabelPair>;

export const HGConnection = ts.type({
  canonicalId: ts.string,
  objectRefA: HGObjectRef,
  objectRefB: HGObjectRef,
  appliedLabelPairs: ts.array(HGAppliedLabelPair),
});
export type HGConnection = ts.TypeOf<typeof HGConnection>;

export function canonicalIdForConnection(params: {
  objectRefA: HGObjectRef;
  objectRefB: HGObjectRef;
}): string {
  const { objectRefA, objectRefB } = params;
  const canonicalIdA = canonicalIdForHGObjectRef(objectRefA);
  const canonicalIdB = canonicalIdForHGObjectRef(objectRefB);
  return [canonicalIdA, canonicalIdB].sort().join("/");
}

function involvedObjectTypesForHGConnection(
  hgConnection: HGConnection,
): HGObjectTypePair {
  return [
    hgConnection.objectRefA.objectType,
    hgConnection.objectRefB.objectType,
  ].sort() as HGObjectTypePair;
}

export function hgConnectionObjectRefs(
  connection: HGConnection,
): [HGObjectRef, HGObjectRef] {
  return [connection.objectRefA, connection.objectRefB];
}

export function makeConnectionsFromV4APIResults(params: {
  fromObjectRef: HGObjectRef;
  toObjectType: string;
  hgLabelPairs: HGLabelPair[];
  hsAssociations: HubSpotAssociationsBatchReadAPIResponse["results"];
}): HGConnection[] {
  const { fromObjectRef, toObjectType, hgLabelPairs, hsAssociations } = params;

  let connections: HGConnection[] = [];

  for (const hsAssociation of hsAssociations) {
    for (const to of hsAssociation.to) {
      const toObjectId = `${to.toObjectId}`;

      const toObjectRef: HGObjectRef = {
        objectType: toObjectType,
        objectId: toObjectId,
      };

      const appliedLabelPairs = makeAppliedLabelsFromHSAssociations({
        associationTypes: to.associationTypes,
        fromObjectRef,
        toObjectRef,
        hgLabelPairs,
      });

      const connection: HGConnection = {
        canonicalId: canonicalIdForConnection({
          objectRefA: fromObjectRef,
          objectRefB: toObjectRef,
        }),
        objectRefA: fromObjectRef,
        objectRefB: toObjectRef,
        appliedLabelPairs: appliedLabelPairs,
      };

      connections.push(connection);
    }
  }

  return connections;
}

export function makeConnectionsFromObjectGetAssociationsResults(params: {
  fromObjectRef: HGObjectRef;
  toObjectType: string;
  hgLabelPairs: HGLabelPair[];
  objectGetAssociationsResults: HubSpotObjectsGetAPIResponseAssociationResult[];
}): HGConnection[] {
  const {
    fromObjectRef,
    toObjectType,
    hgLabelPairs,
    objectGetAssociationsResults,
  } = params;

  let connections: HGConnection[] = [];

  console.log("makeConnectionsFromObjectGetAssociationsResults", params);

  const objectTypePair: HGObjectTypePair = [
    fromObjectRef.objectType,
    toObjectType,
  ];

  const associationsByToObjectId = _.groupBy(
    objectGetAssociationsResults,
    (association) => {
      return association.id;
    },
  );

  for (const [toObjectId, associations] of Object.entries(
    associationsByToObjectId,
  )) {
    const toObjectRef: HGObjectRef = {
      objectType: toObjectType,
      objectId: toObjectId,
    };

    // `objectGetAssociationsResults` are V3 Associations (so a `type` field only, looking like 'employee_manager` or 'contact_to_contact', or 'child_to_parent_company', so we need to first find all the HGLabelPairs for these
    // before turning into HGConnections
    let appliedLabelPairs: HGAppliedLabelPair[] = [];
    for (const association of associations) {
      const hgLabelPair = hgLabelPairs.find((hgLabelPair) => {
        if (!labelPairInvolvesBothObjectTypes(hgLabelPair, objectTypePair)) {
          return;
        }

        const labelAName = hgLabelPair.hgLabels.hgLabelA.name;
        const labelBName = hgLabelPair.hgLabels.hgLabelB.name;

        if (!labelAName || !labelBName) {
          return;
        }

        return (
          labelAName === association.type || labelBName === association.type
        );
      });

      if (!hgLabelPair) {
        console.warn("can't find HGLabelPair for association", association);
        continue;
      } else {
      }

      assert(typeof hgLabelPair.hgLabels.hgLabelA.name === "string");
      assert(typeof hgLabelPair.hgLabels.hgLabelB.name === "string");

      const fromSideHSLabel =
        hgLabelPair.hgLabels.hgLabelA.objectType === fromObjectRef.objectType
          ? hgLabelPair.hgLabels.hgLabelA
          : hgLabelPair.hgLabels.hgLabelB;
      const toSideHSLabel =
        fromSideHSLabel === hgLabelPair.hgLabels.hgLabelA
          ? hgLabelPair.hgLabels.hgLabelB
          : hgLabelPair.hgLabels.hgLabelA;

      const appliedLabelPair: HGAppliedLabelPair = {
        labelPairCanonicalId: hgLabelPair.canonicalId,
        objectATypeId: fromSideHSLabel.typeId,
        objectBTypeId: toSideHSLabel.typeId,
      };

      appliedLabelPairs.push(appliedLabelPair);
    }

    if (appliedLabelPairs.length === 0) {
      return [];
    }

    const connection: HGConnection = {
      canonicalId: canonicalIdForConnection({
        objectRefA: fromObjectRef,
        objectRefB: toObjectRef,
      }),
      appliedLabelPairs,
      objectRefA: fromObjectRef,
      objectRefB: toObjectRef,
    };

    connections.push(connection);
  }

  return connections;
}

function makeAppliedLabelsFromHSAssociations(params: {
  fromObjectRef: HGObjectRef;
  toObjectRef: HGObjectRef;
  hgLabelPairs: HGLabelPair[];
  associationTypes: HubSpotAssociationsBatchReadAPIResponse["results"][0]["to"][0]["associationTypes"];
}): HGAppliedLabelPair[] {
  const { fromObjectRef, toObjectRef, associationTypes, hgLabelPairs } = params;

  // HubSpot association typeIds are only unique within a given object type pair and category
  const hgLabelPairsByCategoryAndTypeId: Record<
    string,
    HGLabelPair | undefined
  > = _.chain(hgLabelPairs)
    .filter((hgLabelPair) => {
      return objectTypesDomain.objectTypesListsEqual(
        [
          hgLabelPair.hgLabels.hgLabelA.objectType,
          hgLabelPair.hgLabels.hgLabelB.objectType,
        ],
        [fromObjectRef.objectType, toObjectRef.objectType],
      );
    })
    .reduce((acc, hgLabelPair) => {
      const labelATypeId = hgLabelPair.hgLabels.hgLabelA.typeId;
      const labelBTypeId = hgLabelPair.hgLabels.hgLabelB.typeId;
      acc[`${hgLabelPair.category}:${labelBTypeId}`] = hgLabelPair;
      acc[`${hgLabelPair.category}:${labelATypeId}`] = hgLabelPair;
      return acc;
    }, {} as Record<string, HGLabelPair | undefined>)
    .value();

  const appliedLabelPairs = associationTypes.map((typeDef) => {
    let label: HGConnection["appliedLabelPairs"][0];
    if (
      typeDef.category === "HUBSPOT_DEFINED" &&
      typeDef.label &&
      typeDef.label.toLowerCase().startsWith("primary")
    ) {
      const hgLabelPair =
        hgLabelPairsByCategoryAndTypeId[
          `${typeDef.category}:${typeDef.typeId}`
        ];

      if (!hgLabelPair) {
        throw new Error(
          "Cannot create primary association without a primary label definition",
        );
      }

      const companySideHGLabel =
        hgLabelPair.hgLabels.hgLabelA.objectType === "company"
          ? hgLabelPair.hgLabels.hgLabelA
          : hgLabelPair.hgLabels.hgLabelB;
      const otherSideHGLabel =
        hgLabelPair.hgLabels.hgLabelA.objectType === "company"
          ? hgLabelPair.hgLabels.hgLabelB
          : hgLabelPair.hgLabels.hgLabelA;

      label = {
        objectATypeId:
          fromObjectRef.objectType === "company"
            ? companySideHGLabel.typeId
            : otherSideHGLabel.typeId,
        objectBTypeId:
          fromObjectRef.objectType === "company"
            ? otherSideHGLabel.typeId
            : companySideHGLabel.typeId,
        labelPairCanonicalId: hgLabelPair.canonicalId,
      };
    } else if (
      typeDef.category === "HUBSPOT_DEFINED" &&
      fromObjectRef.objectType === "company" &&
      toObjectRef.objectType === "company" &&
      (typeDef.typeId === PARENT_TO_CHILD_COMPANY_LABEL_HUBSPOT_TYPE_ID ||
        typeDef.typeId === CHILD_TO_PARENT_COMPANY_LABEL_HUBSPOT_TYPE_ID)
    ) {
      const hgLabelPair =
        hgLabelPairsByCategoryAndTypeId[
          `${typeDef.category}:${typeDef.typeId}`
        ];

      if (!hgLabelPair) {
        throw new Error(
          "Cannot create parent/child connection without parent/child association present",
        );
      }

      const fromObjectRefIsParent =
        typeDef.typeId === PARENT_TO_CHILD_COMPANY_LABEL_HUBSPOT_TYPE_ID;

      label = {
        objectATypeId: fromObjectRefIsParent
          ? PARENT_TO_CHILD_COMPANY_LABEL_HUBSPOT_TYPE_ID
          : CHILD_TO_PARENT_COMPANY_LABEL_HUBSPOT_TYPE_ID,
        objectBTypeId: fromObjectRefIsParent
          ? CHILD_TO_PARENT_COMPANY_LABEL_HUBSPOT_TYPE_ID
          : PARENT_TO_CHILD_COMPANY_LABEL_HUBSPOT_TYPE_ID,
        labelPairCanonicalId: hgLabelPair.canonicalId,
      };
    } else {
      if (typeDef.label) {
        const hgLabelPair =
          hgLabelPairsByCategoryAndTypeId[
            `${typeDef.category}:${typeDef.typeId}`
          ];

        if (!hgLabelPair) {
          throw new Error("Could not find association label def for label");
        }

        const objectRefAObjectType = fromObjectRef.objectType;
        const objectRefBObjectType = toObjectRef.objectType;

        const objectRefAHGLabel =
          hgLabelPair.hgLabels.hgLabelA.objectType === objectRefAObjectType
            ? hgLabelPair.hgLabels.hgLabelA
            : hgLabelPair.hgLabels.hgLabelB;
        const objectRefBHGLabel =
          hgLabelPair.hgLabels.hgLabelA.objectType === objectRefBObjectType
            ? hgLabelPair.hgLabels.hgLabelA
            : hgLabelPair.hgLabels.hgLabelB;

        label = {
          objectATypeId: objectRefAHGLabel.typeId,
          objectBTypeId: objectRefBHGLabel.typeId,
          labelPairCanonicalId: hgLabelPair.canonicalId,
        };
      } else {
        const hgLabelPair =
          hgLabelPairsByCategoryAndTypeId[
            `${typeDef.category}:${typeDef.typeId}`
          ];

        if (!hgLabelPair) {
          throw new Error("Could not find association label def for label");
        }

        const objectRefAObjectType = fromObjectRef.objectType;
        const objectRefBObjectType = toObjectRef.objectType;

        const objectRefAHGLabel =
          hgLabelPair.hgLabels.hgLabelA.objectType === objectRefAObjectType
            ? hgLabelPair.hgLabels.hgLabelA
            : hgLabelPair.hgLabels.hgLabelB;
        const objectRefBHGLabel =
          hgLabelPair.hgLabels.hgLabelA.objectType === objectRefBObjectType
            ? hgLabelPair.hgLabels.hgLabelA
            : hgLabelPair.hgLabels.hgLabelB;

        label = {
          objectATypeId: objectRefAHGLabel.typeId,
          objectBTypeId: objectRefBHGLabel.typeId,
          labelPairCanonicalId: hgLabelPair.canonicalId,
        };
      }
    }
    return label;
  });
  return appliedLabelPairs;
}

export function makeConnectionsFromHSAssociationLabels(params: {
  fromObjectRef: HGObjectRef;
  toObjectType: string;
  hgLabelPairs: HGLabelPair[];
  hsAssociations: HubSpotAPIAssociation[];
}): HGConnection[] {
  const { fromObjectRef, toObjectType, hgLabelPairs, hsAssociations } = params;

  let connections: HGConnection[] = [];

  for (const hsAssociation of hsAssociations) {
    const toObjectRef: HGObjectRef = {
      objectType: toObjectType,
      objectId: `${hsAssociation.toObjectId}`,
    };

    const appliedLabelPairs = makeAppliedLabelsFromHSAssociations({
      associationTypes: hsAssociation.associationTypes,
      fromObjectRef,
      hgLabelPairs,
      toObjectRef,
    });

    const connection: HGConnection = {
      canonicalId: canonicalIdForConnection({
        objectRefA: fromObjectRef,
        objectRefB: toObjectRef,
      }),
      objectRefA: fromObjectRef,
      objectRefB: toObjectRef,
      appliedLabelPairs,
    };

    connections.push(connection);
  }

  return connections;
}

export function makeConnections2FromHSAssociations(params: {
  fromObjectType: string;
  toObjectType: string;
  hgLabelPairs: HGLabelPair[];
  hsAssociations: HubSpotAssociationsBatchReadAPIResponse["results"];
}): HGConnection[] {
  const { fromObjectType, toObjectType, hgLabelPairs, hsAssociations } = params;

  console.log("makeConnections2FromHSAssociations", cloneDeep(params));

  let connections: HGConnection[] = [];

  for (const hsAssociation of hsAssociations) {
    const fromObjectId = hsAssociation.from.id;
    for (const to of hsAssociation.to) {
      const toObjectId = `${to.toObjectId}`;

      const fromObjectRef: HGObjectRef = {
        objectType: fromObjectType,
        objectId: fromObjectId,
      };
      const toObjectRef: HGObjectRef = {
        objectType: toObjectType,
        objectId: toObjectId,
      };

      const appliedLabelPairs = makeAppliedLabelsFromHSAssociations({
        associationTypes: to.associationTypes,
        fromObjectRef,
        toObjectRef,
        hgLabelPairs,
      });

      const connection: HGConnection = {
        canonicalId: canonicalIdForConnection({
          objectRefA: fromObjectRef,
          objectRefB: toObjectRef,
        }),
        objectRefA: fromObjectRef,
        objectRefB: toObjectRef,
        appliedLabelPairs: appliedLabelPairs,
      };

      connections.push(connection);
    }
  }

  return connections;
}

function isPrimaryHGLabel(hgLabel: HGLabel): hgLabel is HGLabelLabelled {
  return (
    hgLabel.category === "HUBSPOT_DEFINED" &&
    !!hgLabel.label &&
    hgLabel.label.toLowerCase().startsWith("primary")
  );
}

function isPrimaryHGLabelPair(
  hgLabelPair: HGLabelPair,
): hgLabelPair is HGLabelPairPrimary {
  return (
    isPrimaryHGLabel(hgLabelPair.hgLabels.hgLabelA) &&
    isPrimaryHGLabel(hgLabelPair.hgLabels.hgLabelB)
  );
}

function isCompanySidePrimaryHGLabel(
  hgLabel: HGLabel,
): hgLabel is HGLabelLabelled {
  return isPrimaryHGLabel(hgLabel) && hgLabel.objectType === "company";
}

function isParentChildHGLabel(hgLabel: HGLabel): hgLabel is HGLabelLabelled {
  return (
    hgLabel.objectType === "company" &&
    hgLabel.otherObjectType === "company" &&
    (hgLabel.typeId === PARENT_TO_CHILD_COMPANY_LABEL_HUBSPOT_TYPE_ID ||
      hgLabel.typeId === CHILD_TO_PARENT_COMPANY_LABEL_HUBSPOT_TYPE_ID)
  );
}

function isParentSideHGLabel(hgLabel: HGLabel): hgLabel is HGLabelLabelled {
  return (
    isParentChildHGLabel(hgLabel) &&
    hgLabel.typeId === CHILD_TO_PARENT_COMPANY_LABEL_HUBSPOT_TYPE_ID
  );
}

function canonicalIdForHubSpotAPIAssociationLabel(hgLabel: HGLabel): string {
  const label = hgLabel.label;
  const objectType = hgLabel.objectType;
  const otherObjectType = hgLabel.otherObjectType;

  let labelName: string | undefined;
  if (hgLabel.label && hgLabel.name && typeof hgLabel.name === "string") {
    labelName = hgLabel.name;
  }

  const isParentChildAssociation = isParentChildHGLabel({
    ...hgLabel,
    objectType: hgLabel.objectType,
    otherObjectType: hgLabel.otherObjectType,
  });
  const isPrimary = isPrimaryHGLabel({
    ...hgLabel,
    objectType: hgLabel.objectType,
    otherObjectType: hgLabel.otherObjectType,
  });

  let idLabel: string;
  if (isPrimary) {
    idLabel = "primary";
  } else if (isParentChildAssociation) {
    idLabel = "parent-child";
  } else if (labelName) {
    idLabel = labelName;
  } else if (label) {
    idLabel = label;
  } else {
    idLabel = "unlabelled";
  }

  const idParts = [
    [objectType, otherObjectType].sort().join("/"),
    idLabel,
    isParentChildAssociation ? "parent-child" : "not-parent-child",
    isPrimary ? "is-primary" : "not-primary",
  ];

  return idParts.join(":");
}

export function makeHGLabelsFromHSAssociationLabels(
  hsAssociationLabels: HubSpotAPIAssociationLabelWithFromToObjectTypes[],
): HGLabel[] {
  const hgLabels = hsAssociationLabels.map((hsAssociationLabel) => {
    const hgLabel: HGLabel = {
      category: hsAssociationLabel.category,
      label: hsAssociationLabel.label,
      objectType: hsAssociationLabel.fromObjectType,
      otherObjectType: hsAssociationLabel.toObjectType,
      typeId: hsAssociationLabel.typeId,
      name: hsAssociationLabel.name,
    };
    return hgLabel;
  });

  return hgLabels;
}

// TODO: can probably remove nearly all the parent/child logic once we have the user-defined paired labels working well?
export function makeHGLabelPairsFromHSAssociationLabels(params: {
  hgLabels: HGLabel[];
}): HGLabelPair[] {
  const { hgLabels } = params;

  // we should only ever be able to find pairs of labels
  // - if we have one side of the association we need the other side as well,
  //   so if we don't have the matching pair we cannot use this label
  // - if we have more than two HubSpot labels grouped together then something
  //   else is wrong so we cannot use this label either
  let pairedHGLabels = _.groupBy(
    hgLabels,
    canonicalIdForHubSpotAPIAssociationLabel,
  );

  // TODO: do we want this approach - very hacky?
  // - adding in copies of hg labels for same-object unlabelled/single label associations as they
  //   are only made up of one label definition, unlike every other association type...
  pairedHGLabels = _.chain(pairedHGLabels)
    .map((hgLabels, canonicalId) => {
      const original: [canonicalId: string, hgLabels: HGLabel[]] = [
        canonicalId,
        _.sortBy(hgLabels, (hgLabel) => hgLabel.typeId),
      ];

      if (hgLabels.length !== 1) {
        return original;
      }

      const hgLabel = hgLabels[0];

      // if not a same object association, nothing we can do
      const isSameObjectAssociation =
        hgLabel.objectType === hgLabel.otherObjectType;
      if (!isSameObjectAssociation) {
        return original;
      }

      // can only create fake duplicate HGLabels if this same-object association is a single label or unlabelled. Paired labels should be handled in the normal way already.
      const isUnlabelledSameObjectAssociation =
        typeof hgLabel.label !== "string";
      const isLabelledSameObjectAssociation =
        typeof hgLabel.label === "string" &&
        "name" in hgLabel &&
        typeof hgLabel.name !== "undefined";

      if (
        !isUnlabelledSameObjectAssociation &&
        !isLabelledSameObjectAssociation
      ) {
        return original;
      }

      return [
        canonicalId,
        _.sortBy([{ ...hgLabel }, { ...hgLabel }], (hgLabel) => hgLabel.typeId),
      ];
    })
    .fromPairs()
    .value();

  const validPairedHGLabels = _.filter(
    pairedHGLabels,
    (hgLabels) => hgLabels.length === 2,
  );

  // for every pair, create the HGLabelPair that represents them
  const hgLabelPairs = _.chain(validPairedHGLabels)
    .map((hgLabels) => {
      const hgLabelA = hgLabels[0];
      const hgLabelB = hgLabels[1];

      const isPrimary =
        isPrimaryHGLabel(hgLabelA) && isPrimaryHGLabel(hgLabelB);
      const isParentChild =
        isParentChildHGLabel(hgLabelA) && isParentChildHGLabel(hgLabelB);
      const isUnlabelled = !hgLabelA.label && !hgLabelB.label;
      const isSingularLabel =
        !isUnlabelled &&
        !isParentChild &&
        !isPrimary &&
        hgLabelA.label === hgLabelB.label;
      const isPairedLabel =
        !isParentChild &&
        !isUnlabelled &&
        !isSingularLabel &&
        hgLabelA.label &&
        hgLabelB.label &&
        typeof hgLabelA.name !== "undefined" &&
        typeof hgLabelB.name !== "undefined" &&
        hgLabelA.name === hgLabelB.name;

      if (isParentChild) {
        const parentSideLabel = isParentSideHGLabel(hgLabelA)
          ? hgLabelA
          : hgLabelB;
        const childSideLabel = isParentSideHGLabel(hgLabelA)
          ? hgLabelB
          : hgLabelA;

        const parentSideDisplayLabel = parentSideLabel.label;
        const childSideDisplayLabel = childSideLabel.label;

        assert(
          parentSideDisplayLabel && childSideDisplayLabel,
          "parent-child labels must have display labels",
        );

        const hgLabelPair: HGLabelPairPairedLabel = {
          canonicalId: canonicalIdForHGLabelPair({
            objectTypePair: [hgLabelA.objectType, hgLabelA.otherObjectType],
            labelPair: [parentSideDisplayLabel, childSideDisplayLabel],
          }),
          category: "HUBSPOT_DEFINED",
          hgLabels: {
            hgLabelA: parentSideLabel,
            hgLabelB: childSideLabel,
          },
          type: "paired-label",
        };
        return hgLabelPair;
      } else if (isPairedLabel) {
        const displayLabelA = hgLabelA.label;
        const displayLabelB = hgLabelB.label;
        assert(
          displayLabelA && displayLabelB,
          "paired labels must have both display labels",
        );
        const hgLabelPair: HGLabelPairPairedLabel = {
          canonicalId: canonicalIdForHGLabelPair({
            objectTypePair: [hgLabelA.objectType, hgLabelA.otherObjectType],
            labelPair: [displayLabelA, displayLabelB],
          }),
          category: hgLabelA.category,
          hgLabels: {
            hgLabelA,
            hgLabelB,
          },
          type: "paired-label",
        };
        return hgLabelPair;
      } else if (isPrimary) {
        const companySideHGLabel = isCompanySidePrimaryHGLabel(hgLabelA)
          ? hgLabelA
          : hgLabelB;
        const otherSideHGLabel = isCompanySidePrimaryHGLabel(hgLabelA)
          ? hgLabelB
          : hgLabelA;

        const hgLabelPair: HGLabelPairPrimary = {
          canonicalId: canonicalIdForHGLabelPair({
            objectTypePair: [hgLabelA.objectType, hgLabelA.otherObjectType],
            primary: true,
          }),
          category: "HUBSPOT_DEFINED",
          hgLabels: {
            hgLabelA: companySideHGLabel,
            hgLabelB: otherSideHGLabel,
          },
          type: "primary",
        };
        return hgLabelPair;
      } else if (isUnlabelled) {
        assert(hgLabelA.label === null);
        assert(hgLabelB.label === null);
        const hgLabelPair: HGLabelPairUnlabelled = {
          canonicalId: canonicalIdForHGLabelPair({
            objectTypePair: [hgLabelA.objectType, hgLabelA.otherObjectType],
            unlabelled: true,
          }),
          category: hgLabelA.category,
          hgLabels: {
            hgLabelA,
            hgLabelB,
          },
          type: "unlabelled",
        };
        return hgLabelPair;
      } else if (isSingularLabel) {
        const displayLabelA = hgLabelA.label;
        const displayLabelB = hgLabelB.label;
        assert(
          displayLabelA && displayLabelB,
          "singular labels must have both display labels",
        );
        const hgLabelPair: HGLabelPairSingleLabel = {
          canonicalId: canonicalIdForHGLabelPair({
            objectTypePair: [hgLabelA.objectType, hgLabelA.otherObjectType],
            labelPair: [displayLabelA, displayLabelB],
          }),
          category: hgLabelA.category,
          hgLabels: {
            hgLabelA,
            hgLabelB,
          },
          type: "single-label",
        };
        return hgLabelPair;
      }
    })
    .compact()
    .value();

  return hgLabelPairs;
}

type ConnectionPatchAddLabel = {
  fromObjectRef: HGObjectRef;
  toObjectRef: HGObjectRef;
  typeId: number;
  associationCategory: HGLabelCategory;
  action: "add-label";
};
type ConnectionPatchRemoveLabel = {
  fromObjectRef: HGObjectRef;
  toObjectRef: HGObjectRef;
  typeId: number;
  associationCategory: HGLabelCategory;
  action: "remove-label";
};
type ConnectionPatchRemoveConnection = {
  fromObjectRef: HGObjectRef;
  toObjectRef: HGObjectRef;
  action: "remove-connection";
};
export type ConnectionPatch =
  | ConnectionPatchAddLabel
  | ConnectionPatchRemoveLabel
  | ConnectionPatchRemoveConnection;
export function makeConnectionPatchesForHubSpotAPIUpdate(params: {
  hgLabelPairs: HGLabelPair[];
  existingConnection: HGConnection | undefined;
  nextConnection: HGConnection;
  newPrimaryCompanyObjectRef?: HGObjectRef;
}): ConnectionPatch[] {
  const {
    hgLabelPairs: allHGLabelPairs,
    existingConnection,
    nextConnection,
    newPrimaryCompanyObjectRef,
  } = params;

  // a bit defensive, but make sure we only consider label pairs involving the two object
  // types for the connection
  const hgLabelPairs = allHGLabelPairs.filter((hgLabelPair) => {
    return objectTypesDomain.objectTypesListsEqual(
      involvedObjectTypesForHGConnection(nextConnection),
      labelPairMentionedObjectTypes(hgLabelPair),
    );
  });

  // when updating a connection on HubSpot we need to compare the current
  // HGLabelPairs they have and figure out which pairs we need to add
  // and which pairs we need to remove.
  //
  // we won't consider any unlabelled association labels here when we are
  // finding labels to remove, as they will be removed automatically by
  // hubspot when the last label is removed on their side

  const hgLabelPairsIndex = indexBy(
    hgLabelPairs,
    (hgLabelPair) => hgLabelPair.canonicalId,
  );

  function keepOnlyKnownHGLabelPairIds(canonicalId: string): boolean {
    const hgLabelPair = hgLabelPairsIndex[canonicalId] as
      | HGLabelPair
      | undefined;
    return !!hgLabelPair;
  }

  function keepOnlyLabelledHGLabelPairIds(canonicalId: string): boolean {
    const hgLabelPair = hgLabelPairsIndex[canonicalId] as
      | HGLabelPair
      | undefined;
    return !!hgLabelPair && hgLabelPair.type !== "unlabelled";
  }

  // the state of the labels before we started editing
  const existingHGLabelPairCanonicalIds = (
    existingConnection?.appliedLabelPairs ?? []
  )
    .map((appliedLabelPair) => appliedLabelPair.labelPairCanonicalId)
    .filter(keepOnlyKnownHGLabelPairIds);

  // the state of the labels we want
  const nextHGLabelPairCanonicalIds = nextConnection.appliedLabelPairs
    .map((appliedLabelPair) => appliedLabelPair.labelPairCanonicalId)
    .filter(keepOnlyKnownHGLabelPairIds);

  // new labels to add to HubSpot to get from existing->what-we-want
  const toAddLabelPairCanonicalIds = nextHGLabelPairCanonicalIds.filter(
    (canonicalId) => !existingHGLabelPairCanonicalIds.includes(canonicalId),
  );

  // labels to remove from HubSpot to get from existing->what-we-want
  const toRemoveLabelPairCanonicalIds = existingHGLabelPairCanonicalIds
    .filter(keepOnlyLabelledHGLabelPairIds)
    .filter(
      (canonicalId) => !nextHGLabelPairCanonicalIds.includes(canonicalId),
    );

  // labels we need to swap around (e.g. same-object parent/child labels) to
  // get from existing->what-we-want
  const toSwapAppliedLabelPairs = nextConnection.appliedLabelPairs.filter(
    (appliedLabelPair) => {
      const existingAppliedLabelPair =
        existingConnection?.appliedLabelPairs.find(
          (existingAppliedLabelPair) =>
            existingAppliedLabelPair.labelPairCanonicalId ===
            appliedLabelPair.labelPairCanonicalId,
        );

      if (!existingAppliedLabelPair) {
        return false;
      }

      return (
        existingAppliedLabelPair.objectATypeId !==
          appliedLabelPair.objectATypeId ||
        existingAppliedLabelPair.objectBTypeId !==
          appliedLabelPair.objectBTypeId
      );
    },
  );

  function addHGLabelPair(
    appliedLabelPair: HGAppliedLabelPair,
  ):
    | { hgLabelPair: HGLabelPair; appliedLabelPair: HGAppliedLabelPair }
    | undefined {
    const hgLabelPair = hgLabelPairsIndex[
      appliedLabelPair.labelPairCanonicalId
    ] as HGLabelPair | undefined;

    if (!hgLabelPair) {
      return;
    }

    return {
      hgLabelPair,
      appliedLabelPair,
    };
  }

  const addPatches: ConnectionPatchAddLabel[] = nextConnection.appliedLabelPairs
    .filter((appliedLabelPair) => {
      return toAddLabelPairCanonicalIds.includes(
        appliedLabelPair.labelPairCanonicalId,
      );
    })
    .map(addHGLabelPair)
    .filter(isNotUndefined)
    .map(({ hgLabelPair, appliedLabelPair }) => {
      const fromObjectRef = nextConnection.objectRefA;
      const toObjectRef = nextConnection.objectRefB;

      const patch: ConnectionPatch = {
        action: "add-label",
        fromObjectRef,
        toObjectRef,
        typeId: appliedLabelPair.objectATypeId,
        associationCategory: hgLabelPair.category,
      };

      return patch;
    });

  const removePatches: ConnectionPatchRemoveLabel[] = existingConnection
    ? existingConnection.appliedLabelPairs
        .filter((appliedLabelPair) => {
          return toRemoveLabelPairCanonicalIds.includes(
            appliedLabelPair.labelPairCanonicalId,
          );
        })
        .map(addHGLabelPair)
        .filter(isNotUndefined)
        .map(({ hgLabelPair, appliedLabelPair }) => {
          const fromObjectRef = existingConnection.objectRefA;
          const toObjectRef = existingConnection.objectRefB;

          const patch: ConnectionPatch = {
            action: "remove-label",
            fromObjectRef,
            toObjectRef,
            typeId: appliedLabelPair.objectATypeId,
            associationCategory: hgLabelPair.category,
          };

          return patch;
        })
    : [];

  const swapPatches: (ConnectionPatchAddLabel | ConnectionPatchRemoveLabel)[] =
    _.chain(toSwapAppliedLabelPairs)
      .map(addHGLabelPair)
      .filter(isNotUndefined)
      .flatMap(({ hgLabelPair, appliedLabelPair }) => {
        const fromObjectRef = nextConnection.objectRefA;
        const toObjectRef = nextConnection.objectRefB;

        const removePatch: ConnectionPatchRemoveLabel = {
          action: "remove-label",
          fromObjectRef,
          toObjectRef,
          typeId: appliedLabelPair.objectATypeId,
          associationCategory: hgLabelPair.category,
        };

        const addPatch: ConnectionPatchAddLabel = {
          action: "add-label",
          fromObjectRef,
          toObjectRef,
          typeId: appliedLabelPair.objectBTypeId,
          associationCategory: hgLabelPair.category,
        };

        return [addPatch, removePatch];
      })
      .value();

  let newPrimaryCompanyLabelPatch: ConnectionPatchAddLabel | undefined;
  if (newPrimaryCompanyObjectRef) {
    const companySidePrimaryHGLabel = _.chain(hgLabelPairs)
      .filter((hgLabelPair) => {
        return isPrimaryHGLabelPair(hgLabelPair);
      })
      .flatMap((hgLabelPair) => {
        return [hgLabelPair.hgLabels.hgLabelA, hgLabelPair.hgLabels.hgLabelB];
      })
      .filter((hgLabel) => {
        return isCompanySidePrimaryHGLabel(hgLabel);
      })
      .first()
      .value();

    if (companySidePrimaryHGLabel) {
      // build the patch for the new primary company
      const nonCompanyObjectRef =
        nextConnection.objectRefA.objectType === "company"
          ? nextConnection.objectRefB
          : nextConnection.objectRefA;
      const addPatch: ConnectionPatchAddLabel = {
        action: "add-label",
        associationCategory: companySidePrimaryHGLabel.category,
        fromObjectRef: newPrimaryCompanyObjectRef,
        toObjectRef: nonCompanyObjectRef,
        typeId: companySidePrimaryHGLabel.typeId,
      };
      newPrimaryCompanyLabelPatch = addPatch;
    }
  }

  // to be helpful return the patches in the order that the should
  // be performed on the HubSpot side. this will be removing
  // associations first before creating new ones. removing first
  // allows us to return the swaps in a sensible way - for example,
  // swapping the parent/child associations should involve removing
  // the label and then adding the new swapped around label.
  const allPatches = [
    ...addPatches,
    ...removePatches,
    ...swapPatches,
    ...(newPrimaryCompanyLabelPatch ? [newPrimaryCompanyLabelPatch] : []),
  ];

  // sort all the patches so that "remove-connection" is first, then
  // "remove-label", and then "add-label"
  const orderedPatches = _.sortBy(allPatches, (patch) => {
    if (patch.action === "remove-label") {
      return 0;
    } else if (patch.action === "add-label") {
      return 1;
    } else {
      assertNever(patch);
    }
  });

  return orderedPatches;
}

export function makeConnectionPatchesForHubSpotAPIRemove(params: {
  existingConnection: HGConnection;
}): ConnectionPatch {
  const { existingConnection } = params;

  const patch: ConnectionPatch = {
    action: "remove-connection",
    fromObjectRef: existingConnection.objectRefA,
    toObjectRef: existingConnection.objectRefB,
  };

  return patch;
}

/*
 * Returns a list of HGObjectRefs that we could move the "primary company"
 * connection to
 *
 * primary associations can only be removed if we have another
 * association to a company record that we can "move" the primary
 * association to.
 *
 * a contact has a "primary company", and must always have one. we
 * can only remove the existing "primary company" association if
 * we also have another company connection we can set as the
 * "Primary Company"
 */
export function primaryCompanyAssociationCandidates(params: {
  hgConnection: HGConnection;
  otherHGConnections: HGConnection[];
  hgLabelPairs: HGLabelPair[];
}): HGObjectRef[] {
  const { hgConnection, otherHGConnections, hgLabelPairs } = params;

  const objectAType = hgConnection.objectRefA.objectType;
  const objectBType = hgConnection.objectRefB.objectType;

  if (objectAType !== "company" && objectBType !== "company") {
    // can't have a primary company association if no companies are involved
    return [];
  } else if (objectAType === "company" && objectBType === "company") {
    // can't have a primary company association if both are companies
    return [];
  }

  const nonCompanyObjectRef =
    objectAType === "company"
      ? hgConnection.objectRefB
      : hgConnection.objectRefA;

  const nonCompanyObjectType = nonCompanyObjectRef.objectType;

  // primary company associations are only valid for contacts and deals (maybe tickets?)
  if (nonCompanyObjectType !== "contact" && nonCompanyObjectType !== "deal") {
    return [];
  }

  // make sure we have a primary association HGLabelPair between company and whatever
  // other object type we have
  const primaryHGLabelPair = hgLabelPairs.find((hgLabelPair) => {
    const labelObjectTypeA = hgLabelPair.hgLabels.hgLabelA.objectType;
    const labelObjectTypeB = hgLabelPair.hgLabels.hgLabelB.objectType;

    if (
      (labelObjectTypeA === "company" &&
        labelObjectTypeB === nonCompanyObjectType) ||
      (labelObjectTypeB === "company" &&
        labelObjectTypeA === nonCompanyObjectType)
    ) {
      return isPrimaryHGLabelPair(hgLabelPair);
    } else {
      return false;
    }
  });

  if (!primaryHGLabelPair) {
    return [];
  }

  const possibleOtherHGConnections = otherHGConnections
    .filter((otherHGConnection) => {
      // only consider connections involving our non-company object
      return hgConnectionInvolvesObjectRef(
        otherHGConnection,
        nonCompanyObjectRef,
      );
    })
    .filter((otherHGConnection) => {
      // only consider connections involving our non-company object and another company object
      return objectTypePairSupportsPrimaryCompanyAssociation([
        otherHGConnection.objectRefA.objectType,
        otherHGConnection.objectRefB.objectType,
      ]);
    })
    .filter((otherHGConnection) => {
      // if this connection is our current primary company association then we can't use it
      const isExistingPrimaryConnection =
        otherHGConnection.appliedLabelPairs.some((appliedLabelPair) => {
          return (
            appliedLabelPair.labelPairCanonicalId ===
            primaryHGLabelPair.canonicalId
          );
        });
      return !isExistingPrimaryConnection;
    })
    .map((otherHGConnection) => {
      const companyObjectRef = [
        otherHGConnection.objectRefA,
        otherHGConnection.objectRefB,
      ].find((objectRef) => {
        return objectRef.objectType === "company";
      });

      return companyObjectRef;
    })
    .filter(isNotUndefined);

  return possibleOtherHGConnections;
}

export function hgConnectionInvolvesObjectRef(
  hgConnection: HGConnection,
  objectRef: HGObjectRef,
): boolean {
  return (
    objectRefsEqual(hgConnection.objectRefA, objectRef) ||
    objectRefsEqual(hgConnection.objectRefB, objectRef)
  );
}

export function objectTypePairSupportsPrimaryCompanyAssociation(
  objectTypePair: [objectTypeA: string, objectTypeB: string],
): boolean {
  const companyCount = objectTypePair.filter(
    (objectType) => objectType === "company",
  ).length;

  if (companyCount === 0) {
    return false;
  }

  const otherObjectType = objectTypePair.filter(
    (objectType) => objectType !== "company",
  )[0];

  return otherObjectType === "contact" || otherObjectType === "deal";
}

export function connectionPrimaryCompany(params: {
  connection: HGConnection;
  hgLabelPairs: HGLabelPair[];
}): HGObjectRef | null {
  const { connection, hgLabelPairs } = params;

  const connectionObjectTypeList: [string, string] = [
    connection.objectRefA.objectType,
    connection.objectRefB.objectType,
  ];

  const objectTypesCanSupportPrimaryCompanyAssociation =
    objectTypePairSupportsPrimaryCompanyAssociation(connectionObjectTypeList);

  if (!objectTypesCanSupportPrimaryCompanyAssociation) {
    return null;
  }

  const companyObjectRef =
    connection.objectRefA.objectType === "company"
      ? connection.objectRefA
      : connection.objectRefB;

  const primaryHGLabelPair = hgLabelPairs.find((hgLabelPair) => {
    const labelPairObjectTypes = [
      hgLabelPair.hgLabels.hgLabelA.objectType,
      hgLabelPair.hgLabels.hgLabelB.objectType,
    ];
    const objectTypesEqual = objectTypesDomain.objectTypesListsEqual(
      connectionObjectTypeList,
      labelPairObjectTypes,
    );

    return objectTypesEqual && isPrimaryHGLabelPair(hgLabelPair);
  });

  if (!primaryHGLabelPair) {
    return null;
  }

  const primaryAppliedLabelPair = connection.appliedLabelPairs.find(
    (appliedLabelPair) => {
      return (
        appliedLabelPair.labelPairCanonicalId === primaryHGLabelPair.canonicalId
      );
    },
  );

  if (!primaryAppliedLabelPair) {
    return null;
  }

  return companyObjectRef;
}

export function connectionHasPrimaryCompany(params: {
  connection: HGConnection;
  hgLabelPairs: HGLabelPair[];
}): boolean {
  return !!connectionPrimaryCompany(params);
}

export function primaryCompanyObjectRefForObjectRef(params: {
  connections: HGConnection[];
  hgLabelPairs: HGLabelPair[];
  nonCompanyObjectRef: HGObjectRef;
}): HGObjectRef | undefined {
  const { connections, hgLabelPairs, nonCompanyObjectRef } = params;

  // find all conections involving this object ref
  const otherConnections = connections.filter((otherConnection) => {
    return hgConnectionInvolvesObjectRef(otherConnection, nonCompanyObjectRef);
  });

  // for all those connections find the one that has a primary company
  const companyObjectRef = _.chain(otherConnections)
    .filter((connection) => {
      return connectionHasPrimaryCompany({
        connection,
        hgLabelPairs,
      });
    })
    .map((connection) => {
      return connectionPrimaryCompany({ connection, hgLabelPairs });
    })
    .first()
    .value() as HGObjectRef | undefined;

  return companyObjectRef;
}

export function connectionReplacingExistingPrimaryCompany(params: {
  nonCompanyObjectConnections: HGConnection[];
  draftConnection: HGConnection;
  hgLabelPairs: HGLabelPair[];
}): boolean {
  const { nonCompanyObjectConnections, draftConnection, hgLabelPairs } = params;

  const draftNonCompanyObjectRef =
    draftConnection.objectRefA.objectType === "company"
      ? draftConnection.objectRefB
      : draftConnection.objectRefA;

  const existingCompanyObjectRef = primaryCompanyObjectRefForObjectRef({
    connections: nonCompanyObjectConnections,
    hgLabelPairs,
    nonCompanyObjectRef: draftNonCompanyObjectRef,
  });

  const nextConnectionCompanyObjectRef = connectionPrimaryCompany({
    connection: draftConnection,
    hgLabelPairs,
  });

  return (
    !!existingCompanyObjectRef &&
    !!nextConnectionCompanyObjectRef &&
    !objectRefsEqual(existingCompanyObjectRef, nextConnectionCompanyObjectRef)
  );
}

export function connectableHGObjectRefs(params: {
  objectARef: HGObjectRef;
  hgLabelPairs: HGLabelPair[];
  hgObjectRefs: HGObjectRef[];
}): HGObjectRef[] {
  const { objectARef, hgLabelPairs, hgObjectRefs } = params;

  const objectAType = objectARef.objectType;

  const objectBAllowableTypes = _.chain(hgLabelPairs)
    .filter((labelPair) => {
      return labelPairInvolvesObjectType(labelPair, objectAType);
    })
    .map((labelPair) => {
      return labelPair.hgLabels.hgLabelA.objectType === objectAType
        ? labelPair.hgLabels.hgLabelB.objectType
        : labelPair.hgLabels.hgLabelA.objectType;
    })
    .uniq()
    .value();

  const allowableObjectRefs = _.chain(hgObjectRefs)
    .filter((hgObjectRef) => {
      return objectBAllowableTypes.includes(hgObjectRef.objectType);
    })
    .value();

  return allowableObjectRefs;
}

export function firstLevelConnectionsForObjectRef(params: {
  rootObjectRef: HGObjectRef;
  hgConnections: HGConnection[];
  hgConnectionFilterFn: (hgConnection: HGConnection) => boolean;
}): HGConnection[] {
  const { rootObjectRef, hgConnections } = params;

  const firstLevelConnections = hgConnections
    .filter((hgConnection) => {
      return hgConnectionInvolvesObjectRef(hgConnection, rootObjectRef);
    })
    .filter(params.hgConnectionFilterFn);

  return firstLevelConnections;
}

export function hgConnectionsForDealContacts(params: {
  dealObjectRef: HGObjectRef;
  hgConnections: HGConnection[];
}): HGConnection[] {
  const { dealObjectRef, hgConnections } = params;

  return firstLevelConnectionsForObjectRef({
    rootObjectRef: dealObjectRef,
    hgConnections,
    hgConnectionFilterFn: (hgConnection) => {
      const otherObjectRef = otherObjectRefForConnection(
        hgConnection,
        dealObjectRef,
      );
      return otherObjectRef!.objectType === "contact";
    },
  });
}

export function otherObjectRefForConnection(
  connection: HGConnection,
  objectRef: HGObjectRef,
): HGObjectRef | null {
  if (!hgConnectionInvolvesObjectRef(connection, objectRef)) {
    return null;
  }
  if (objectRefsEqual(connection.objectRefA, objectRef)) {
    return connection.objectRefB;
  } else if (objectRefsEqual(connection.objectRefB, objectRef)) {
    return connection.objectRefA;
  } else {
    return null;
  }
}
