import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import * as domain from "../domain";
import * as CanvasProperties from "./PropertyComponents/CanvasProperties";
import * as icons from "@mui/icons-material";
import _ from "lodash";
import { Button, CircularProgress, Tooltip, TooltipProps } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as actions from "../actions";
import { canonicalObjectIdsOnMap } from "../computeds";
import * as computeds from "../computeds";
import * as connection from "../domain/connection";
import { useStore } from "../hooks/hooks";
import { assert, isNonEmptyString } from "../utils";
import { LineIcon } from "../components/primitives";

const bgColorClassesForObjectType: {
  [objectType: string]:
    | {
        dark: string;
        darker: string;
        darkest: string;
        light: string;
        darkBorder: string;
      }
    | undefined;
} = {
  contact: {
    darkBorder: "border-purple-400",
    darker: "bg-purple-300",
    darkest: "bg-purple-500",
    dark: "bg-purple-200",
    light: "bg-purple-50",
  },
  company: {
    darkBorder: "border-blue-400",
    darker: "bg-blue-300",
    darkest: "bg-blue-500",
    dark: "bg-blue-200",
    light: "bg-blue-50",
  },
  deal: {
    darkBorder: "border-emerald-400",
    darker: "bg-emerald-300",
    darkest: "bg-emerald-500",
    dark: "bg-emerald-200",
    light: "bg-emerald-50",
  },
};

const fgColorClassLightestForObjectType: {
  [objectType: string]: string | undefined;
} = {
  contact: "text-purple-50",
  company: "text-blue-50",
  deal: "text-emerald-50",
};

const fgColorClassForObjectType: {
  [objectType: string]: string | undefined;
} = {
  contact: "text-purple-900",
  company: "text-blue-900",
  deal: "text-emerald-900",
};

function fgColorClass(objectType: string): string {
  return fgColorClassForObjectType[objectType] || "text-slate-900";
}

function fgColorClassLightest(objectType: string): string {
  return fgColorClassLightestForObjectType[objectType] || "text-slate-50";
}

function bgColorClasses(objectType: string): {
  darkBorder: string;
  darker: string;
  darkest: string;
  dark: string;
  light: string;
} {
  return (
    bgColorClassesForObjectType[objectType] || {
      darkBorder: "border-slate-400",
      dark: "bg-slate-200",
      darker: "bg-slate-300",
      darkest: "bg-slate-400",
      light: "bg-slate-50",
    }
  );
}

export function ObjectTypeIcon(props: { objectType: string }) {
  const { objectType } = props;
  let iconNode: React.ReactNode | null;

  switch (objectType) {
    case "contact": {
      iconNode = <icons.Person color="inherit" fontSize="medium" />;
      break;
    }
    case "company": {
      iconNode = <icons.Business color="inherit" fontSize="medium" />;
      break;
    }
    case "deal": {
      iconNode = <icons.Handshake color="inherit" fontSize="medium" />;
      break;
    }
    case "note": {
      iconNode = <icons.Note color="inherit" fontSize="medium" />;
      break;
    }
  }

  return <>{iconNode}</>;
}

const TooltipSolid: React.FC<TooltipProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Tooltip
      {...otherProps}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "rgba(27,38,49,1)",
            fontSize: "0.875rem",
            fontWeight: "400",
            "& .MuiTooltip-arrow": {
              color: "rgba(27,38,49,1)",
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

const HGObjectCanvasNodeBottomBarButton = (props: {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon: React.ReactNode;
  disabled?: boolean;
}) => {
  const { label, icon, onClick, disabled } = props;

  return (
    <TooltipSolid
      title={label}
      enterDelay={1000}
      arrow={true}
      placement="bottom"
    >
      <div className="flex-1 flex">
        <button
          disabled={disabled}
          className={`flex flex-1 flex-col items-center justify-center transition-all text-slate-900 $hover:text-blue-500 hover:bg-blue-100 active:bg-blue-200 cursor-pointer text-[28px] disabled:opacity-10`}
          type="button"
          onClick={onClick}
          onPointerDown={(e) => e.stopPropagation()}
        >
          {icon}
        </button>
      </div>
    </TooltipSolid>
  );
};

const HGObjectCanvasNodeBottomBar = observer(
  (props: {
    hgObject: domain.HGObject;
    offMapConnectionsDefinition: {
      count: number;
      isFetchingAssociations: boolean;
      onStartAddingAssociatedObject: () => void;
    };
  }) => {
    const { hgObject, offMapConnectionsDefinition } = props;

    return (
      <div className="flex flex-row divide-x divide-slate-200 h-16">
        <HGObjectCanvasNodeBottomBarButton
          label="Show details"
          onClick={(e) => {
            actions.focusObject(hgObject);
          }}
          icon={
            <span className="text-[28px] leading-[0]">
              <icons.ReadMore fontSize="inherit" color="inherit" />
            </span>
          }
        />
        <HGObjectCanvasNodeBottomBarButton
          label="Open In HubSpot"
          onClick={(e) => {
            actions.openHSObjectInHubSpot({
              objectRef: hgObject,
            });
          }}
          icon={
            <span className="text-[24px] leading-[0]">
              <icons.OpenInNew fontSize="inherit" color="inherit" />
            </span>
          }
        />
        <HGObjectCanvasNodeBottomBarButton
          label="Add connections not on the map"
          onClick={(e) => {
            offMapConnectionsDefinition.onStartAddingAssociatedObject();
          }}
          disabled={
            offMapConnectionsDefinition.isFetchingAssociations ||
            offMapConnectionsDefinition.count === 0
          }
          icon={
            <span className="flex flex-row items-center justify-center space-x-2">
              <span className="text-[24px] leading-[0]">
                <icons.PersonAdd fontSize="inherit" color="inherit" />
              </span>
              <span className="text-lg font-medium text-slate-900">
                {offMapConnectionsDefinition.count}
              </span>
            </span>
          }
        />
        {/* <HGObjectCanvasNodeBottomBarButton
          onClick={(e) => {}}
          label="Connect"
          icon={
            <span className="h-[28px] w-[28px] leading-[0]">
              <LineIcon />
            </span>
          }
        /> */}
      </div>
    );
  },
);

const HGObjectCanvasNodeHeader: React.FC<{
  objectType: string;
}> = observer((props) => {
  const { objectType } = props;

  const Tag = (props: { label: string }) => {
    return (
      <div
        className={`px-2 py-0.5 rounded-md bg-white bg-opacity-70 ${fgColorClass(
          objectType,
        )} text-lg font-semibold`}
      >
        {props.label}
      </div>
    );
  };

  // const dispatch = useAppDispatch();

  const displayLabel = computeds.displayLabelForObjectTypeSingular(objectType);

  return (
    <div
      className={`${bgColorClasses(objectType).dark} border-b-2 ${
        bgColorClasses(objectType).darkBorder
      } flex flex-row`}
    >
      <div className="flex flex-row px-2 py-3 space-x-2 flex flex-row items-center">
        <span className={`${fgColorClass(objectType)} ml-1`}>
          <ObjectTypeIcon objectType={objectType} />
        </span>
        <Tag label={displayLabel} />
      </div>
    </div>
  );
});

const HGObjectAvatarSection: React.FC<{
  hgObject: domain.HGObject;
}> = observer((props) => {
  const { hgObject } = props;

  const displayName = domain.objectDisplayName(hgObject);
  const displayInitials = domain.maybeObjectDisplayInitials(hgObject);

  const sectionBackgroundColorClass = bgColorClasses(hgObject.objectType).light;
  const avatarBackgroundColorClass = bgColorClasses(
    hgObject.objectType,
  ).darkest;
  const avatarTextColorClass = fgColorClassLightest(hgObject.objectType);

  return (
    <div className={`flex flex-row p-4 py-6 ${sectionBackgroundColorClass}`}>
      <div className="flex-none h-16 w-16">
        <div
          className={`flex items-center justify-center h-full w-full rounded-full ${avatarBackgroundColorClass} text-3xl relative`}
        >
          <span
            className={`leading-none ${avatarTextColorClass} relative tracking-tight font-semibold leading-[0]`}
          >
            {displayInitials || "??"}
          </span>
        </div>
      </div>

      <div className="grow flex flex-col justify-center text-2xl text-slate-800 font-medium tracking-tight px-4">
        {displayName}
      </div>
    </div>
  );
});

const HGObjectCanvasNodeCompanyTopSection = observer(
  (props: { hgObject: domain.HGObject }) => {
    const { hgObject } = props;

    const domainName = hgObject.properties?.domain;
    const displayName = domain.objectDisplayName(hgObject);

    const sectionBackgroundColorClass = bgColorClasses(
      hgObject.objectType,
    ).light;
    const avatarBackgroundColorClass = "bg-blue-200";
    const avatarTextColorClass = "text-blue-800";

    return (
      <div className={`flex flex-row p-4 py-6 ${sectionBackgroundColorClass}`}>
        <div className="flex-none flex flex-col items-center justify-center">
          <div className="h-16 w-16">
            <div
              className={`flex items-center justify-center h-full w-full rounded-full ${avatarBackgroundColorClass} ${avatarTextColorClass} text-[32px] relative`}
            >
              <icons.Business color="inherit" fontSize="inherit" />
            </div>
          </div>
        </div>

        <div className="grow flex flex-col px-4 justify-center space-y-1">
          <div className="text-2xl text-slate-800 font-semibold">
            {displayName}
          </div>

          {isNonEmptyString(displayName) &&
            isNonEmptyString(domainName) &&
            displayName !== domainName && (
              <div className="text-xl text-slate-900 font-medium">
                {domainName}
              </div>
            )}
        </div>
      </div>
    );
  },
);

const HGObjectCanvasNodeDealTopSection = observer(
  (props: { hgObject: domain.HGObject }) => {
    const { hgObject } = props;

    const displayName = domain.objectDisplayName(hgObject);

    const sectionBackgroundColorClass = bgColorClasses(
      hgObject.objectType,
    ).light;
    const avatarBackgroundColorClass = "bg-emerald-200";
    const avatarTextColorClass = "text-emerald-800";

    return (
      <div className={`flex flex-row p-4 py-6 ${sectionBackgroundColorClass}`}>
        <div className="flex-none flex flex-col items-center justify-center">
          <div className="h-16 w-16">
            <div
              className={`flex items-center justify-center h-full w-full rounded-full ${avatarBackgroundColorClass} ${avatarTextColorClass} text-[32px] relative`}
            >
              <icons.Handshake color="inherit" fontSize="inherit" />
            </div>
          </div>
        </div>

        <div className="grow flex flex-col px-4 justify-center space-y-1">
          <div className="text-2xl text-slate-900 font-semibold">
            {displayName}
          </div>
        </div>
      </div>
    );
  },
);

const HGObjectCanvasNodeContactTopSection = observer(
  (props: { hgObject: domain.HGObject }) => {
    const { hgObject } = props;

    const store = useStore();

    const email = hgObject.properties?.email;
    const firstname = hgObject.properties?.firstname;
    const lastname = hgObject.properties?.lastname;
    const jobTitle = hgObject.properties?.jobtitle;
    const displayName = [firstname, lastname]
      .filter(isNonEmptyString)
      .join(" ");
    const displayInitials = domain.maybeObjectDisplayInitials(hgObject);

    const primaryCompanyId = hgObject.properties?.associatedcompanyid;
    let hgObjectPrimaryCompany: domain.HGObject | undefined;
    if (isNonEmptyString(primaryCompanyId)) {
      const primaryCompanyCanonicalId = domain.canonicalIdForHGObjectRef({
        objectType: "company",
        objectId: primaryCompanyId,
      });
      hgObjectPrimaryCompany = store.hgObjects[primaryCompanyCanonicalId] as
        | domain.HGObject
        | undefined;
    }
    const companyDisplayName =
      hgObjectPrimaryCompany &&
      domain.objectDisplayName(hgObjectPrimaryCompany);

    const sectionBackgroundColorClass = bgColorClasses(
      hgObject.objectType,
    ).light;
    const avatarBackgroundColorClass = "bg-purple-200";
    const avatarTextColorClass = "text-purple-800";

    return (
      <div className={`flex flex-row p-4 py-6 ${sectionBackgroundColorClass}`}>
        <div className="flex-none flex flex-col items-center justify-center">
          <div className="h-16 w-16">
            <div
              className={`flex items-center justify-center h-full w-full rounded-full ${avatarBackgroundColorClass} text-3xl relative`}
            >
              <span
                className={`leading-none ${avatarTextColorClass} relative tracking-tight font-semibold leading-[0]`}
              >
                {displayInitials || "??"}
              </span>
            </div>
          </div>
        </div>

        <div
          data-displayName="true"
          className="flex-1 flex flex-col px-4 justify-center space-y-1 overflow-hidden"
        >
          <span className="text-2xl text-slate-900 font-semibold truncate">
            {isNonEmptyString(displayName) ? displayName : email}
          </span>

          {isNonEmptyString(jobTitle) && (
            <div className="text-xl text-slate-800 font-medium">
              {jobTitle}{" "}
              {isNonEmptyString(companyDisplayName)
                ? ` at ${companyDisplayName}`
                : ""}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export const HGObjectCanvasNode: React.FC<{
  node: domain.HGCanvasObjectNodeHubSpot;
}> = observer((props) => {
  const { node } = props;

  const canonicalId = domain.canonicalIdForHGObjectRef({
    objectId: node.objectId,
    objectType: node.objectType,
  });

  const store = useStore();

  let hgObject = store.hgObjects[canonicalId] as domain.HGObject | undefined;

  const objectRef: domain.HGObjectRef = {
    objectId: node.objectId,
    objectType: node.objectType,
  };

  const hgConnections = Object.values(store.hgConnections).filter(
    (hgConnection) => {
      return connection.hgConnectionInvolvesObjectRef(hgConnection, objectRef);
    },
  );

  const connectedObjectRefsNotOnMap = hgConnections
    .map((hgConnection) => {
      const otherObjectRef = domain.objectRefsEqual(
        hgConnection.objectRefA,
        objectRef,
      )
        ? hgConnection.objectRefB
        : hgConnection.objectRefA;
      return otherObjectRef;
    })
    .filter((otherObjectRef) => {
      return !canonicalObjectIdsOnMap.get()[
        domain.canonicalIdForHGObjectRef(otherObjectRef)
      ];
    });

  // TODO: test code just so we can render a fake hubspot object
  if (!hgObject) {
    hgObject = {
      canonicalId: domain.canonicalIdForHGObjectRef({
        objectId: node.objectId,
        objectType: node.objectType,
      }),
      objectId: node.objectId,
      objectType: node.objectType,
      properties: {},
      isFetched: true,
    };
  }

  if (!hgObject) {
    return <div>Empty placeholder for ${canonicalId}</div>;
  }

  let extraAssociationsMessage: string | undefined;
  if (connectedObjectRefsNotOnMap.length > 0) {
    if (connectedObjectRefsNotOnMap.length > 1) {
      extraAssociationsMessage = `Has ${connectedObjectRefsNotOnMap.length} additional associations not shown on this map.`;
    } else {
      extraAssociationsMessage =
        "Has 1 additional association not shown on this map.";
    }
  }

  const isFetchingAssociations =
    store.hgObjectsFetchingAssociations[hgObject.canonicalId];

  console.log(_.cloneDeep(hgObject));

  let topSection: React.ReactNode;
  if (hgObject.objectType === "contact") {
    topSection = <HGObjectCanvasNodeContactTopSection hgObject={hgObject} />;
  } else if (hgObject.objectType === "company") {
    topSection = <HGObjectCanvasNodeCompanyTopSection hgObject={hgObject} />;
  } else if (hgObject.objectType === "deal") {
    topSection = <HGObjectCanvasNodeDealTopSection hgObject={hgObject} />;
  } else {
    topSection = <HGObjectAvatarSection hgObject={hgObject} />;
  }

  return (
    <div
      data-och-canonicalid={hgObject.canonicalId}
      className={`flex flex-col w-[384px] min-w-[340px] max-w-[500px] ${
        bgColorClasses(hgObject.objectType).darkBorder
      } rounded-xl bg-white overflow-hidden user-select-none shadow-xl`}
    >
      <HGObjectCanvasNodeHeader objectType={hgObject.objectType} />

      {topSection}

      {/* <div className="px-4 py-2">
        <div>
          <span className="font-semibold">Object Id:</span> {hgObject.objectId}
        </div>
        <div>
          <span className="font-semibold">Object Type:</span> {hgObject.objectType}
        </div>
      </div> */}

      {isFetchingAssociations && (
        <div className="bg-red-50 p-4 flex flex-col space-y-2 border-y border-red-300">
          <CircularProgress color={"secondary"} size={32} />
        </div>
      )}

      {/* {!isFetchingAssociations &&
        !_.isEmpty(connectedObjectRefsNotOnMap) &&
        extraAssociationsMessage && (
          <div>
            <div className="bg-red-50 px-4 pt-3 pb-2 flex flex-col space-y-2 border-y border-red-300">
              <div className="pl-2">
                <span className="text-red-900 font-semibold text-base leading-tight">
                  {extraAssociationsMessage}
                </span>
              </div>
              <div className="flex">
                <Button
                  style={{ color: theme.colors.red[800] }}
                  variant="text"
                  onPointerDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    if (!hgObject) {
                      return;
                    }
                    actions.startAddingAssociatedObject({
                      objectType: hgObject.objectType,
                      objectId: hgObject.objectId,
                    });
                  }}
                >
                  View{" "}
                  {connectedObjectRefsNotOnMap.length > 1
                    ? "associations"
                    : "association"}
                </Button>
              </div>
            </div>
          </div>
        )} */}

      <CanvasProperties.PropertyRows hgObject={hgObject} />

      {/* <div className="p-4">
        <Button
          style={{
            textTransform: "none",
          }}
          variant="contained"
          onPointerDown={(e) => e.stopPropagation()}
          onClick={() => {
            console.log("focus object");
            actions.focusObject({ objectType, objectId });
          }}
        >
          Show details
        </Button>
      </div> */}

      <div className="border-t-2 border-slate-200">
        <HGObjectCanvasNodeBottomBar
          hgObject={hgObject}
          offMapConnectionsDefinition={{
            count: connectedObjectRefsNotOnMap.length,
            isFetchingAssociations: false,
            onStartAddingAssociatedObject: () => {
              assert(hgObject);
              actions.startAddingAssociatedObject({
                objectType: hgObject.objectType,
                objectId: hgObject.objectId,
              });
            },
          }}
        />
      </div>

      {/* <div className="p-4">
      <span className="font-semibold">Object Properties:</span>{" "}
      {JSON.stringify(hgObject.properties, null, 2)}
      </div> */}
    </div>
  );
});

const HGObjectCollectionCanvasNodeHeader: React.FC<{
  objectType: string;
}> = observer((props) => {
  const { objectType } = props;

  const displayLabelPlural =
    computeds.displayLabelForObjectTypePlural(objectType);

  let iconNode: React.ReactNode | undefined;
  switch (objectType) {
    case "contact": {
      iconNode = <icons.Person color="inherit" fontSize="medium" />;
      break;
    }
    case "company": {
      iconNode = <icons.Business color="inherit" fontSize="medium" />;
      break;
    }
    case "deal": {
      iconNode = <icons.Handshake color="inherit" fontSize="medium" />;
      break;
    }
  }

  const Tag = (props: { label: string }) => {
    return (
      <div
        className={`px-2 py-0.5 rounded-md bg-white bg-opacity-70 ${fgColorClass(
          objectType,
        )} text-base font-semibold`}
      >
        {props.label}
      </div>
    );
  };

  return (
    <div
      className={`p-2 py-2.5 ${bgColorClasses(objectType).dark} border-b-2 ${
        bgColorClasses(objectType).darkBorder
      } flex flex-row items-center space-x-2`}
    >
      <span className={`${fgColorClass(objectType)} ml-1`}>
        {iconNode}
        {iconNode}
      </span>
      <Tag label={displayLabelPlural} />
    </div>
  );
});

const HSNoteEditor: React.FC<{
  initialValue: string;
  onSave: (newValue: string) => void;
  onCancel: () => void;
}> = (props) => {
  const { initialValue, onSave, onCancel } = props;

  const [value, setValue] = useState<string>(initialValue);

  const saveDisabled = value === initialValue || value === "";
  const cancelDisabled = false;

  const refTextarea = useRef<HTMLTextAreaElement>(null);
  useLayoutEffect(() => {
    if (refTextarea.current) {
      refTextarea.current.select();
    }
  }, []);

  return (
    <div className="w-full flex flex-col space-y-4">
      <textarea
        ref={refTextarea}
        onPointerDown={(e) => {
          e.stopPropagation();
        }}
        onWheel={(e) => {
          e.stopPropagation();
        }}
        className="flex-1 resize-none p-2 text-lg leading-normal overflow-y-scroll rounded-md outline outline-2 outline-violet-600 w-full"
        placeholder="Start typing to leave a note..."
        value={value}
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
        autoFocus={true}
      />
      <div className="flex flex-row space-x-2">
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          onPointerDownCapture={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            // setValue(initialValue);
            onSave(value);
          }}
          disabled={saveDisabled}
        >
          save
        </Button>
        <Button
          style={{ textTransform: "none" }}
          onPointerDownCapture={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            onCancel();
          }}
          disabled={cancelDisabled}
        >
          cancel
        </Button>
      </div>
    </div>
  );
};

export const HGObjectNoteNode: React.FC<{
  node: domain.HGCanvasObjectNode;
}> = observer((props) => {
  const { node } = props;

  const store = useStore();

  const isLocalNoteObject = node.type === "objectHubGraph";

  const [editing, setEditing] = useState<boolean>(isLocalNoteObject);
  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = useCallback(
    async (value: string) => {
      setSaving(true);

      if (isLocalNoteObject) {
        await actions.createNoteInHubSpot({
          nodeId: node.id,
          content: value,
        });
      } else {
        await actions.updateNoteInHubSpot({
          nodeId: node.id,
          content: value,
        });
      }

      setSaving(false);
      setEditing(false);
    },
    [node.id, isLocalNoteObject],
  );

  const canonicalId = isLocalNoteObject
    ? null
    : domain.canonicalIdForHGObjectRef({
        objectType: node.objectType,
        objectId: node.objectId,
      });

  const hgObject = canonicalId ? store.hgObjects[canonicalId] : null;
  const objectType = node.objectType;

  console.log("note hgObject", hgObject);

  return (
    <div
      className={`h-full w-full min-w-[480px] min-h-[320px] border-4 border-transparent rounded-xl overflow-hidden user-select-none flex items-stretch bg-slate-300`}
    >
      <div className="w-full flex flex-col flex-1">
        <HGObjectCanvasNodeHeader objectType={objectType} />

        {isLocalNoteObject && <div>IS LOCAL NOTE</div>}
        {!isLocalNoteObject && <div>IS REMOTE NOTE</div>}

        {editing && (
          <div
            className={`flex-1 flex items-stretch p-4 ${
              saving ? "opacity-50" : ""
            }`}
          >
            <HSNoteEditor
              initialValue={hgObject?.properties?.["hs_note_body"] || ""}
              onCancel={() => {
                setEditing(false);
              }}
              onSave={(value) => {
                console.log("new value", value);
                handleSave(value);
              }}
            />
          </div>
        )}

        {!editing && (
          <div className="flex-1 flex items-stretch p-4">
            <div className="flex-1 flex flex-col space-y-4">
              <p className="flex-1 p-4 bg-white bg-opacity-40 whitespace-pre-wrap ">
                {hgObject?.properties?.["hs_note_body"] || ""}
              </p>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ textTransform: "none" }}
                  onPointerDownCapture={(e) => {
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditing(true);
                  }}
                >
                  edit
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
