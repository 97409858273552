import React from "react";
import { Menu as MenuIcon } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { useStore } from "../hooks/hooks";
import {
  HGObject,
  canonicalIdForHGObjectRef,
  objectDisplayName,
} from "../domain";
import * as actions from "../actions";

export const RelationshipMapSwitcherToolbar = observer(() => {
  const store = useStore();

  const mapCompanyHGObjectRef = store.initialObject;

  const companyHGObject = store.hgObjects[
    canonicalIdForHGObjectRef(mapCompanyHGObjectRef)
  ] as HGObject | undefined;

  const displayName = companyHGObject && objectDisplayName(companyHGObject);

  return (
    <div className="bg-white shadow-vlg rounded-md flex flex-row pointer-events-auto divide-x divide-slate-200">
      <div className="flex flex-row divide-x-2">
        <div className="flex flex-col items-center justify-center">
          <button
            onClick={() => {
              actions.setSwitchingRelationshipMap(true);
            }}
            className="flex flex-col items-center justify-center hover:text-purple-500 transition-all active:bg-purple-100 h-[40px] w-[40px] "
          >
            <MenuIcon></MenuIcon>
          </button>
        </div>
        <div className="flex items-center justify-center px-4 select-none">
          {displayName || mapCompanyHGObjectRef.objectId}
        </div>
      </div>
    </div>
  );
});
