type Vector = number[];
type Size = [width: number, height: number];
type Rect = { x: number; y: number; width: number; height: number };

import Vec from "@tldraw/vec";
import * as Intersect from "@tldraw/intersect";
import { assert, isNotNull } from "./index";

interface LabelRenderContext {
  aBounds: Rect;
  bBounds: Rect;
  sideALabelGroupSize: Size | null;
  sideBLabelGroupSize: Size | null;
  middleLabelGroupSize: Size | null;
}

interface LineSegmentDetails {
  lineSegment: Vector;
  midPoint: Vector;
  sideAIntersection: Vector;
  sideBIntersection: Vector;
}

interface DesiredPlacementResult {
  aBounds: Rect;
  bBounds: Rect;
  sideALabelRect: Rect | null;
  sideBLabelRect: Rect | null;
  middleLabelRect: Rect | null;
  lineSegmentDetails: LineSegmentDetails | null;
}

function rectanglesIntersecting(rectangle1: Rect, rectangle2: Rect): boolean {
  const x1 = rectangle1.x;
  const y1 = rectangle1.y;
  const x2 = rectangle1.x + rectangle1.width;
  const y2 = rectangle1.y + rectangle1.height;

  const x3 = rectangle2.x;
  const y3 = rectangle2.y;
  const x4 = rectangle2.x + rectangle2.width;
  const y4 = rectangle2.y + rectangle2.height;

  if (
    x2 < x3 || // Rectangle 1 is completely to the left of Rectangle 2
    x1 > x4 || // Rectangle 1 is completely to the right of Rectangle 2
    y2 < y3 || // Rectangle 1 is completely above Rectangle 2
    y1 > y4 // Rectangle 1 is completely below Rectangle 2
  ) {
    return false; // Rectangles do not intersect
  }

  return true; // Rectangles intersect
}

function calculateIntersectionPoint(
  direction: Vector,
  rectangle: Rect,
  point: Vector,
): Vector | null {
  // Calculate the inverse of the direction vector
  const invDirection: Vector = [1 / direction[0], 1 / direction[1]];

  // Calculate the initial values for tMin and tMax
  const tXMin = (rectangle.x - point[0]) * invDirection[0];
  const tXMax = (rectangle.x + rectangle.width - point[0]) * invDirection[0];
  const tYMin = (rectangle.y - point[1]) * invDirection[1];
  const tYMax = (rectangle.y + rectangle.height - point[1]) * invDirection[1];

  // Determine the entering and exiting t values
  const tMin = Math.max(Math.min(tXMin, tXMax), Math.min(tYMin, tYMax));
  const tMax = Math.min(Math.max(tXMin, tXMax), Math.max(tYMin, tYMax));

  // Check if the ray intersects the rectangle
  if (tMin > tMax || tMax < 0) return null;

  // Calculate the intersection point
  const intersectionX = point[0] + tMin * direction[0];
  const intersectionY = point[1] + tMin * direction[1];

  return [intersectionX, intersectionY];
}

function distanceToRectBounds(
  point: Vector,
  rect: Rect,
  direction: Vector,
): number | null {
  const intersectionPoint = calculateIntersectionPoint(direction, rect, point);

  if (!intersectionPoint) return null;

  const intersectionVec = Vec.vec(point, intersectionPoint);

  return Vec.len(intersectionVec);
}

export function placeLabelRect(params: {
  boxA: Rect;
  labelSize: Size;
  direction: number[];
}): Rect {
  const { boxA, labelSize, direction } = params;

  // ensure direction is normalised
  const normalizedDirection = Vec.normalize(direction);

  // place the labelRect initially in the centre of boxA
  const initialLabelRect = {
    x: boxA.x + boxA.width / 2 - labelSize[0] / 2,
    y: boxA.y + boxA.height / 2 - labelSize[1] / 2,
    width: labelSize[0],
    height: labelSize[1],
  };

  // for every corner measure the distance between the labelRect and
  // the corner along the direction vector
  const labelRectCorners = [
    [initialLabelRect.x, initialLabelRect.y],
    [initialLabelRect.x + initialLabelRect.width, initialLabelRect.y],
    [
      initialLabelRect.x + initialLabelRect.width,
      initialLabelRect.y + initialLabelRect.height,
    ],
    [initialLabelRect.x, initialLabelRect.y + initialLabelRect.height],
  ];

  const distancesToBoxAEdge = labelRectCorners
    .map((corner) => {
      const distance = distanceToRectBounds([corner[0], corner[1]], boxA, [
        normalizedDirection[0],
        normalizedDirection[1],
      ]);
      return distance;
    })
    .filter((distance) => distance !== null) as number[];

  // we need to translate the labelRect by this much in the direction vector
  const largestDistance = Math.max(...distancesToBoxAEdge) || 0;

  // add some padding so that the label is a little bit away from the boxA
  const padding = 50;

  const nextLabelPosition = Vec.add(
    [initialLabelRect.x, initialLabelRect.y],
    Vec.mul(normalizedDirection, largestDistance + padding),
  );

  const nextLabelRect: Rect = {
    x: nextLabelPosition[0],
    y: nextLabelPosition[1],
    width: labelSize[0],
    height: labelSize[1],
  };

  return nextLabelRect;
}

function calculateDesiredBoundingBoxes(
  labelRenderContext: LabelRenderContext,
): DesiredPlacementResult {
  const {
    aBounds,
    bBounds,
    sideALabelGroupSize,
    sideBLabelGroupSize,
    middleLabelGroupSize,
  } = labelRenderContext;

  const aRect = {
    x: aBounds.x,
    y: aBounds.y,
    width: aBounds.width,
    height: aBounds.height,
  };
  const bRect = {
    x: bBounds.x,
    y: bBounds.y,
    width: bBounds.width,
    height: bBounds.height,
  };

  if (rectanglesIntersecting(aRect, bRect)) {
    return {
      aBounds,
      bBounds,
      sideALabelRect: null,
      sideBLabelRect: null,
      middleLabelRect: null,
      lineSegmentDetails: null,
    };
  }

  const centerA = [
    aBounds.x + aBounds.width / 2,
    aBounds.y + aBounds.height / 2,
  ];
  const centerB = [
    bBounds.x + bBounds.width / 2,
    bBounds.y + bBounds.height / 2,
  ];

  let sideARect: Rect | null = null;
  let sideBRect: Rect | null = null;
  let middleRect: Rect | null = null;

  const lineSegment = Vec.sub(
    [aBounds.x + aBounds.width / 2, aBounds.y + aBounds.height / 2],
    [bBounds.x + bBounds.width / 2, bBounds.y + bBounds.height / 2],
  );

  const lineSegmentDirectionAToB = Vec.normalize(lineSegment);

  const lineSegmentSideAIntersection = calculateIntersectionPoint(
    lineSegmentDirectionAToB,
    aRect,
    centerA,
  );

  const lineSegmentSideBIntersection = calculateIntersectionPoint(
    Vec.mul(lineSegmentDirectionAToB, -1),
    bRect,
    centerB,
  );

  assert(lineSegmentSideAIntersection);
  assert(lineSegmentSideBIntersection);

  const midPoint = Vec.med(
    lineSegmentSideAIntersection,
    lineSegmentSideBIntersection,
  );

  if (sideALabelGroupSize) {
    sideARect = placeLabelRect({
      boxA: aRect,
      labelSize: [sideALabelGroupSize[0], sideALabelGroupSize[1]],
      direction: Vec.vec(
        [aBounds.x + aBounds.width / 2, aBounds.y + aBounds.height / 2],
        [bBounds.x + bBounds.width / 2, bBounds.y + bBounds.height / 2],
      ),
    });
  }

  if (sideBLabelGroupSize) {
    sideBRect = placeLabelRect({
      boxA: bRect,
      labelSize: [sideBLabelGroupSize[0], sideBLabelGroupSize[1]],
      direction: Vec.vec(
        [bBounds.x + bBounds.width / 2, bBounds.y + bBounds.height / 2],
        [aBounds.x + aBounds.width / 2, aBounds.y + aBounds.height / 2],
      ),
    });
  }

  if (middleLabelGroupSize) {
    middleRect = {
      x: midPoint[0] - middleLabelGroupSize[0] / 2,
      y: midPoint[1] - middleLabelGroupSize[1] / 2,
      width: middleLabelGroupSize[0],
      height: middleLabelGroupSize[1],
    };
  }

  return {
    aBounds,
    bBounds,
    sideALabelRect: sideARect,
    sideBLabelRect: sideBRect,
    middleLabelRect: middleRect,
    lineSegmentDetails: {
      lineSegment,
      midPoint,
      sideAIntersection: lineSegmentSideAIntersection,
      sideBIntersection: lineSegmentSideBIntersection,
    },
  };
}

function extendRect(rect: Rect, x: number, y: number): Rect {
  return {
    x: rect.x - x,
    y: rect.y - y,
    width: rect.width + x * 2,
    height: rect.height + y * 2,
  };
}

function hasIntersectingBounds(
  desiredPlacementResult: DesiredPlacementResult,
): boolean {
  const { aBounds, bBounds, sideALabelRect, sideBLabelRect, middleLabelRect } =
    desiredPlacementResult;

  const aRect = {
    x: aBounds.x,
    y: aBounds.y,
    width: aBounds.width,
    height: aBounds.height,
  };
  const bRect = {
    x: bBounds.x,
    y: bBounds.y,
    width: bBounds.width,
    height: bBounds.height,
  };

  const cardRects = [aRect, bRect];
  const labelRects = [sideALabelRect, sideBLabelRect, middleLabelRect].filter(
    isNotNull,
  );

  const hasIntersect = (rectA: Rect, rectB: Rect): boolean => {
    const intersects = Intersect.intersectRectangleRectangle(
      [rectA.x, rectA.y],
      [rectA.width, rectA.height],
      [rectB.x, rectB.y],
      [rectB.width, rectB.height],
    );
    return intersects.length > 0;
  };

  const hasLabelToCardIntersect = labelRects.some((labelRect) => {
    return cardRects
      .map((cardRect) => extendRect(cardRect, 8, 8))
      .some((cardRect) => {
        return hasIntersect(labelRect, cardRect);
      });
  });

  if (hasLabelToCardIntersect) {
    return true;
  }

  const hasCardToCardIntersect = cardRects.some((rectA) => {
    return cardRects
      .filter((rectB) => rectB !== rectA)
      .some((rectB) => {
        return hasIntersect(rectA, rectB);
      });
  });

  if (hasCardToCardIntersect) {
    return true;
  }

  const hasLabelToLabelIntersect = labelRects.some((rectA) => {
    const labelPadding = { x: 5, y: 2 };
    return labelRects
      .filter((rectB) => rectB !== rectA)
      .some((rectB) => {
        return hasIntersect(
          extendRect(rectA, labelPadding.x, labelPadding.y),
          extendRect(rectB, labelPadding.x, labelPadding.y),
        );
      });
  });

  if (hasLabelToLabelIntersect) {
    return true;
  }

  return false;
}

function hasLabelsPlacedCloserToOtherSide(
  desiredPlacementResult: DesiredPlacementResult & {
    lineSegmentDetails: LineSegmentDetails;
  },
): boolean {
  const { sideALabelRect, sideBLabelRect, lineSegmentDetails } =
    desiredPlacementResult;

  // if the distance of any of the side labels is closer to their opposite
  // side, then we’ll want to collapse the labels down as we could be making
  // it look like the label is applied to the other object instead
  if (sideALabelRect) {
    const centerA = [
      sideALabelRect.x + sideALabelRect.width / 2,
      sideALabelRect.y + sideALabelRect.height / 2,
    ];

    const distanceToA = Vec.dist(lineSegmentDetails.sideAIntersection, centerA);
    const distanceToB = Vec.dist(lineSegmentDetails.sideBIntersection, centerA);

    if (distanceToB <= distanceToA) {
      return true;
    }
  }

  if (sideBLabelRect) {
    const centerB = [
      sideBLabelRect.x + sideBLabelRect.width / 2,
      sideBLabelRect.y + sideBLabelRect.height / 2,
    ];

    const distanceToA = Vec.dist(lineSegmentDetails.sideAIntersection, centerB);
    const distanceToB = Vec.dist(lineSegmentDetails.sideBIntersection, centerB);

    if (distanceToA <= distanceToB) {
      return true;
    }
  }

  return false;
}

interface LabelBoundsResultCollapsed {
  type: "collapsed";
  center: Vector;
}
interface LabelBoundsResultPlaced {
  type: "placed";
  sideARect: Rect | null;
  sideBRect: Rect | null;
  middleRect: Rect | null;
}
interface LabelBoundsResultNoPlacement {
  type: "no-placement";
}
export type LabelBoundsResult =
  | LabelBoundsResultCollapsed
  | LabelBoundsResultPlaced
  | LabelBoundsResultNoPlacement;

export function calculateLabelBoundingBoxes(
  labelRenderContext: LabelRenderContext,
): LabelBoundsResult {
  // figure out where we would ideally place them
  const desiredPlacements = calculateDesiredBoundingBoxes(labelRenderContext);
  const lineSegmentDetails = desiredPlacements.lineSegmentDetails;

  if (!lineSegmentDetails) {
    return {
      type: "no-placement",
    };
  }

  // check for a bunch of conditions that we don't want to allow,
  // for example if there are overlaps between labels we want to
  // render a single collapsed "more details" label instead
  const boundsIntersect = hasIntersectingBounds(desiredPlacements);

  const labelsPlacedCloserToOtherSide = lineSegmentDetails
    ? hasLabelsPlacedCloserToOtherSide({
        ...desiredPlacements,
        lineSegmentDetails,
      })
    : false;

  if (boundsIntersect || labelsPlacedCloserToOtherSide) {
    return {
      type: "collapsed",
      center: lineSegmentDetails.midPoint,
    };
  } else {
    return {
      type: "placed",
      sideARect: desiredPlacements.sideALabelRect,
      sideBRect: desiredPlacements.sideBLabelRect,
      middleRect: desiredPlacements.middleLabelRect,
    };
  }
}
