import type { HGObjectRef } from "../domain";
import * as hubspot from "../api/hubspot-api";
import * as firebaseApi from "../api/firebase";
import * as domain from "../domain";
import {
  HSPropertyGroup,
  HSProperty,
  PROPERTY_GROUP_OCH,
  PROPERTY_OCH_HAS_RELATIONSHIP_MAP_DEFINITION,
  PROPERTY_OCH_RELATIONSHIP_MAP_CREATED_AT_DEFINITION,
  PROPERTY_OCH_RELATIONSHIP_MAP_LAST_UPDATED_AT_DEFINITION,
} from "../domain/property";
import _ from "lodash";
import { delay, parseOrThrow } from "../utils";
import * as connection from "../domain/connection";
import { makeNewRelationshipMapDoc } from "../domain/document";
import { TDDocument } from "@orgcharthub/tldraw-tldraw";
import * as ts from "io-ts";
import { DEV, LOCALSTORAGE_APP_CACHE_VERSION } from "../config";
import { detailedDiff } from "deep-object-diff";

export async function createInitialRelationshipMap(params: {
  authState: domain.AuthState;
  companyObjectRef: HGObjectRef;
}): Promise<{
  doc: TDDocument;
  hgObjects: domain.HGObject[];
  hgConnections: connection.HGConnection[];
}> {
  const { companyObjectRef, authState } = params;

  const doc = makeNewRelationshipMapDoc({
    id: companyObjectRef.objectId,
    companyObjectRef,
  });

  return {
    doc,
    hgObjects: [],
    hgConnections: [],
  };
}

export async function fetchObjectsConnections(params: {
  authState: domain.AuthState;
  objectRefs: HGObjectRef[];
  hgSchemas: domain.HGObjectSchema[];
  existingHGLabelPairs: connection.HGLabelPair[];
  propertiesToFetchForObjectTypes: Record<string, string[]>;
}): Promise<{
  connections: connection.HGConnection[];
  objects: domain.HGObject[];
}> {
  console.log("use-cases/fetchObjectsConnections", params);

  const {
    authState,
    objectRefs,
    existingHGLabelPairs,
    hgSchemas,
    propertiesToFetchForObjectTypes,
  } = params;

  console.log("existingHGLabelPairs", existingHGLabelPairs);

  let level0FetchedHGObjects: domain.HGObject[] = [];
  let objectRefsToFetch: HGObjectRef[] = [];
  let allConnections: connection.HGConnection[] = [];

  // TODO: these requests can be made in parallel?
  for (const objectRef of objectRefs) {
    const { connections, level1ObjectRefs, level0Object } =
      await hubspot.fetchConnectionsForLevel0ObjectRef({
        authState,
        level0ObjectRef: objectRef,
        hgSchemas,
        hgLabelPairs: existingHGLabelPairs,
        propertiesToFetchForObjectTypes,
      });

    allConnections = [...allConnections, ...connections];
    objectRefsToFetch = [...objectRefsToFetch, objectRef, ...level1ObjectRefs];
    if (level0Object) {
      level0FetchedHGObjects.push(level0Object);
    }
  }

  const fetchedCanonicalIds = level0FetchedHGObjects.map(
    (hgObject) => hgObject.canonicalId,
  );
  const uniqueObjectRefsToFetch = _.chain(objectRefsToFetch)
    .uniqBy(domain.canonicalIdForHGObjectRef)
    .filter((objectRef) => {
      const id = domain.canonicalIdForHGObjectRef(objectRef);
      return !fetchedCanonicalIds.includes(id);
    })
    .value();

  const hgObjects = await hubspot.fetchObjects({
    authState,
    objectRefs: uniqueObjectRefsToFetch,
    propertiesToFetchForObjectTypes,
  });

  const result = {
    objects: [...level0FetchedHGObjects, ...hgObjects],
    connections: allConnections,
  };

  console.log("use-cases/fetchObjectsConnections", { params, result });

  return result;
}

async function withHubSpotSchemaCache<T extends any[]>(params: {
  runtimeType: ts.Type<T>;
  key: string;
  fetch: () => Promise<T>;
  forceRefresh: boolean;
}): Promise<{ value: T; fromRemote: boolean }> {
  const { runtimeType, key, fetch, forceRefresh } = params;

  const serialize = (v: T) => {
    const parsed = parseOrThrow(runtimeType, runtimeType.encode(v));
    return JSON.stringify(parsed);
  };

  const parse = (v: string) => {
    return parseOrThrow(runtimeType, v);
  };

  const fetchFromRemoteAndCache = async (): Promise<T> => {
    if (DEV) {
      // await delay(1000 * 30);
    }

    console.log("schema-cache: fetching", { key });
    const remoteValue = await fetch();
    console.log("schema-cache: fetched", { remoteValue, key });

    localStorage.setItem(key, serialize(remoteValue));
    console.log("schema-cache: setItem", { key });

    return remoteValue;
  };

  if (forceRefresh) {
    const value = await fetchFromRemoteAndCache();
    return {
      value,
      fromRemote: true,
    };
  } else {
    const cachedValue = localStorage.getItem(key);
    console.log("schema-cache: cachedValue", { cachedValue, key });
    let parsedCachedValue: T | undefined;
    if (cachedValue) {
      try {
        console.log("schema-cache: parsing cachedValue", { cachedValue, key });
        const parsed = parse(JSON.parse(cachedValue));
        console.log("schema-cache: parsed cachedValue", { parsed, key });
        parsedCachedValue = parsed;
      } catch (e) {
        console.error("Failed to parse cached value", e);
        // attempt to remove if we know the item is invalid
        try {
          localStorage.removeItem(key);
        } catch (e) {}
      }
    }

    if (parsedCachedValue) {
      return {
        value: parsedCachedValue,
        fromRemote: false,
      };
    } else {
      const value = await fetchFromRemoteAndCache();
      return {
        value,
        fromRemote: true,
      };
    }
  }
}

type HSSchemaDependencyReportItem<
  DepType extends HSSchemaDepType,
  ValueType,
> = {
  type: DepType;
  value: ValueType;
  wasRefreshed: boolean;
};

type HSSchemaDependencyReport = {
  HGAccountDetails: HSSchemaDependencyReportItem<
    "HGAccountDetails",
    domain.HGAccountDetails[]
  >;
  Schemas: HSSchemaDependencyReportItem<"Schemas", domain.HGObjectSchema[]>;
  HGLabelPairs: HSSchemaDependencyReportItem<
    "HGLabelPairs",
    connection.HGLabelPair[]
  >;
  HSPropertyGroups: HSSchemaDependencyReportItem<
    "HSPropertyGroups",
    HSPropertyGroup[]
  >;
  HSProperties: HSSchemaDependencyReportItem<"HSProperties", HSProperty[]>;
};

type HSSchemaDepType = keyof HSSchemaDependencyReport;

function hubspotSchemaDependencyCacheKey(
  portalId: string,
  type: HSSchemaDepType,
): string {
  return [
    LOCALSTORAGE_APP_CACHE_VERSION,
    "hubspotSchemaDependency",
    portalId,
    type,
  ].join(":");
}

const HSSchemaDepRuntimeTypes: {
  [DepType in HSSchemaDepType]: ts.Type<
    HSSchemaDependencyReport[DepType]["value"]
  >;
} = {
  HGAccountDetails: ts.array(domain.HGAccountDetails),
  Schemas: ts.array(domain.HGObjectSchema),
  HGLabelPairs: ts.array(connection.HGLabelPair),
  HSPropertyGroups: ts.array(HSPropertyGroup),
  HSProperties: ts.array(HSProperty),
};

const HSSchemaDepIdFns: {
  [DepType in HSSchemaDepType]: (
    v: HSSchemaDependencyReport[DepType]["value"][number],
  ) => string;
} = {
  HGAccountDetails: (v) => v.portalId,
  Schemas: (v) => v.objectTypeId,
  HGLabelPairs: (v) => v.canonicalId,
  HSPropertyGroups: (v) => v.canonicalId,
  HSProperties: (v) => v.canonicalId,
};

export async function ensureHubSpotSchemaDependencies(params: {
  portalId: string;
  accessToken: string;
  currentScopes: string[];
  initialHSSchemaDependencyReport?: HSSchemaDependencyReport;
}): Promise<HSSchemaDependencyReport> {
  const {
    portalId,
    accessToken,
    currentScopes,
    initialHSSchemaDependencyReport,
  } = params;

  console.log("withHubSpotSchemaCache2", {
    initialHSSchemaDependencyReport,
  });

  function makeFetcher<DepType extends HSSchemaDepType>(params: {
    depType: DepType;
    fetch: () => Promise<HSSchemaDependencyReport[DepType]["value"]>;
  }): () => Promise<{
    value: HSSchemaDependencyReport[DepType]["value"];
    fromRemote: boolean;
  }> {
    const { depType, fetch } = params;

    return () => {
      // if we have a value from the initial report and it was already
      // refreshed then we can use that without going to cache or remote
      const initialReportItem =
        initialHSSchemaDependencyReport &&
        initialHSSchemaDependencyReport[depType];
      if (initialReportItem && initialReportItem.wasRefreshed) {
        const reportItem = initialHSSchemaDependencyReport[depType];
        return Promise.resolve({
          value: reportItem.value,
          fromRemote: false,
        });
      }

      // we must refresh if we already read from the cache, otherwise we
      // are free to read from the cache (e.g. on app open)
      const forceRefresh = !!(
        initialReportItem && !initialReportItem.wasRefreshed
      );
      return withHubSpotSchemaCache({
        key: hubspotSchemaDependencyCacheKey(portalId, depType),
        runtimeType: HSSchemaDepRuntimeTypes[depType],
        forceRefresh,
        fetch,
      });
    };
  }

  // fetch account details so we can determine uiDomain, time zone
  // settings and currency settings
  const hgAccountDetailsFetcher = makeFetcher({
    depType: "HGAccountDetails",
    fetch: async () => {
      const accountDetails = await hubspot.fetchAccountDetails({
        authState: { accessToken },
      });
      return [accountDetails];
    },
  });

  // figure out which schemas we can support
  const schemasFetcher = makeFetcher({
    depType: "Schemas",
    fetch: async () => {
      return await hubspot.discoverSupportedSchemas({
        authState: { accessToken },
        currentScopes,
      });
    },
  });

  console.log("fetching hgAccountDetails...");
  console.log("discovering supported object schemas...");
  const [hgAccountDetailsReport, schemasReport] = await Promise.all([
    hgAccountDetailsFetcher(),
    schemasFetcher(),
  ]);

  const supportedObjectTypes = domain.calculateSupportedObjectTypes(
    schemasReport.value,
  );

  const labelPairsFetcher = makeFetcher({
    depType: "HGLabelPairs",
    fetch: async () => {
      const hsAssociationLabels = await hubspot.fetchHubSpotAssociationLabels({
        objectTypes: supportedObjectTypes,
        authState: { accessToken },
      });
      const hgLabels =
        connection.makeHGLabelsFromHSAssociationLabels(hsAssociationLabels);
      const labelPairs = connection.makeHGLabelPairsFromHSAssociationLabels({
        hgLabels,
      });
      return labelPairs;
    },
  });

  const propertiesFetcher = makeFetcher({
    depType: "HSProperties",
    fetch: async () => {
      return await hubspot.fetchAllPropertyDefinitions({
        objectTypes: supportedObjectTypes,
        authState: { accessToken },
      });
    },
  });

  const propertyGroupsFetcher = makeFetcher({
    depType: "HSPropertyGroups",
    fetch: async () => {
      return await hubspot.fetchAllPropertyGroupDefinitions({
        objectTypes: supportedObjectTypes,
        authState: { accessToken },
      });
    },
  });

  console.log("fetching label pairs...");
  console.log("fetching/loading hubspot properties and property groups...");
  const [hgLabelPairsReport, propertiesReport, propertyGroupsReport] =
    await Promise.all([
      labelPairsFetcher(),
      propertiesFetcher(),
      propertyGroupsFetcher(),
    ]);

  return {
    HGAccountDetails: {
      type: "HGAccountDetails",
      value: hgAccountDetailsReport.value,
      wasRefreshed: hgAccountDetailsReport.fromRemote,
    },
    Schemas: {
      type: "Schemas",
      value: schemasReport.value,
      wasRefreshed: schemasReport.fromRemote,
    },
    HGLabelPairs: {
      type: "HGLabelPairs",
      value: hgLabelPairsReport.value,
      wasRefreshed: hgLabelPairsReport.fromRemote,
    },
    HSPropertyGroups: {
      type: "HSPropertyGroups",

      value: propertyGroupsReport.value,
      wasRefreshed: propertyGroupsReport.fromRemote,
    },
    HSProperties: {
      type: "HSProperties",
      value: propertiesReport.value,
      wasRefreshed: propertiesReport.fromRemote,
    },
  };
}

export async function maybeRefreshHubSpotSchemaDependencies(params: {
  portalId: string;
  accessToken: string;
  currentScopes: string[];
  initialHSSchemaDependencyReport: HSSchemaDependencyReport;
}): Promise<Partial<HSSchemaDependencyReport>> {
  const {
    portalId,
    accessToken,
    currentScopes,
    initialHSSchemaDependencyReport,
  } = params;

  // potentially refresh the schema dependencies depending on
  // what happened on for the initial `ensureHubSpotSchemaDependencies`
  // call by passing in the report we received from that
  const refreshedHSSchemaDependencyReport =
    await ensureHubSpotSchemaDependencies({
      accessToken,
      currentScopes,
      portalId,
      initialHSSchemaDependencyReport,
    });

  // compare the values in the initial report to the refresh report, and
  // return information about which dependencies were updated. this will
  // allow the consumer to decide what they need to refresh based on which
  // schema dependencies were updated on HubSpot (e.g. if properties
  // have changed then maybe we need to re-fetch objects that are on the
  // relationship map)
  const makeRefreshReportItem = <DepType extends HSSchemaDepType>(
    depType: DepType,
  ): HSSchemaDependencyReport[DepType] => {
    const initialReport = initialHSSchemaDependencyReport[depType];
    const refreshedReport = refreshedHSSchemaDependencyReport[depType];

    const areEqual = (
      initial: HSSchemaDependencyReport[DepType]["value"],
      refreshed: HSSchemaDependencyReport[DepType]["value"],
    ) => {
      const cachedById = _.keyBy(initial, HSSchemaDepIdFns[depType]);
      const remoteById = _.keyBy(refreshed, HSSchemaDepIdFns[depType]);

      const areEqual = _.isEqual(cachedById, remoteById);
      console.log("schema-cache: checking equality", {
        depType,
        cachedById,
        remoteById,
        areEqual,
        diff: detailedDiff(cachedById, remoteById),
      });
      return areEqual;
    };

    return {
      type: depType,
      value: refreshedReport.value,
      wasRefreshed: !areEqual(initialReport.value, refreshedReport.value),
    } as HSSchemaDependencyReport[DepType];
  };

  return {
    HGAccountDetails: makeRefreshReportItem("HGAccountDetails"),
    Schemas: makeRefreshReportItem("Schemas"),
    HGLabelPairs: makeRefreshReportItem("HGLabelPairs"),
    HSPropertyGroups: makeRefreshReportItem("HSPropertyGroups"),
    HSProperties: makeRefreshReportItem("HSProperties"),
  };
}

/**
 * we require a few property definitions to be present for company
 * objects so that we can search/switch between relationship maps,
 * so we’ll create those on the portal if the property doesn't exist
 *
 * will throw if we can't create the properties/groups
 */
export async function ensureHubSpotHasRequiredDependencies(params: {
  portalId: string;
  accessToken: string;
  propertyGroups: HSPropertyGroup[];
  properties: HSProperty[];
}): Promise<void> {
  const { portalId, accessToken, propertyGroups, properties } = params;

  const authState = { accessToken };

  // ensure we have all the property groups we need
  const neededPropertyGroups = [PROPERTY_GROUP_OCH];
  for (const group of neededPropertyGroups) {
    const existing = propertyGroups.find((g) => g.name === group.name);
    if (!existing) {
      // make property group
      await hubspot.createPropertyGroup({
        authState,
        group,
        objectType: "company",
      });
    }
  }

  // ensure we have all the properties we need
  const neededProperties = [
    PROPERTY_OCH_HAS_RELATIONSHIP_MAP_DEFINITION,
    PROPERTY_OCH_RELATIONSHIP_MAP_CREATED_AT_DEFINITION,
    PROPERTY_OCH_RELATIONSHIP_MAP_LAST_UPDATED_AT_DEFINITION,
  ];
  for (const property of neededProperties) {
    const existing = properties.find((p) => p.name === property.name);
    if (!existing) {
      // create property
      await hubspot.createProperty({
        authState,
        property,
        objectType: "company",
      });
    }
  }
}

export async function maybeAddDefaultDisplayProperties(params: {
  portalId: string;
}): Promise<void> {
  return await firebaseApi.addDefaultDisplayPropertiesIfRequired(params);
}
