import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { useStore } from "../hooks/hooks";
import * as actions from "../actions";
import { useDebounce } from "usehooks-ts";
import { useQuery } from "@tanstack/react-query";
import * as hubspotApi from "../api/hubspot-api";
import * as computeds from "../computeds";
import { HGObject } from "../domain";
import * as domain from "../domain";
import { SortAscendingIcon } from "./primitives/SortAscendingIcon";
import { SortDescendingIcon } from "./primitives/SortDescendingIcon";

const RelationshipMapSwitcherHeaderRow: React.FC<{
  sort: hubspotApi.Sort;
  setSort: (value: React.SetStateAction<hubspotApi.Sort>) => void;
}> = observer((props) => {
  const { sort, setSort } = props;
  const oppositeDirection =
    sort.direction === "ASCENDING" ? "DESCENDING" : "ASCENDING";

  const Header: React.FC<{
    label: string;
    propertyName?: string;
    width: string;
  }> = (props) => {
    let sortIcon: JSX.Element | null = null;
    if (sort.propertyName === props.propertyName) {
      sortIcon =
        sort.direction === "ASCENDING" ? (
          <SortAscendingIcon />
        ) : (
          <SortDescendingIcon />
        );
    }

    const onClick = () => {
      if (typeof props.propertyName === "undefined") {
        return;
      }

      if (sort.propertyName === props.propertyName) {
        setSort({
          propertyName: props.propertyName,
          direction: oppositeDirection,
        });
      } else {
        setSort({
          propertyName: props.propertyName,
          direction: "ASCENDING",
        });
      }
    };

    return (
      <h4 className={"cursor-pointer " + props.width} onClick={onClick}>
        {props.label}
        {sortIcon}
      </h4>
    );
  };

  return (
    <div className="flex py-2 bg-gray-100">
      <Header label="Company Name" propertyName="name" width="w-1/2" />
      <Header
        label="Created At"
        propertyName="orgcharthub_relationship_map_created_at"
        width="w-1/5"
      />
      <Header
        label="Updated At"
        propertyName="orgcharthub_relationship_map_last_updated_at"
        width="w-1/5"
      />
      <Header label="" width="w-1/5" />
    </div>
  );
});

const RelationMapSwitcherRow: React.FC<{
  hgObject: HGObject;
  openMap: (hgObject: HGObject) => void;
}> = observer((props) => {
  const { hgObject, openMap } = props;
  return (
    <div className="flex py-2">
      <h4 className="w-1/2">{domain.objectDisplayName(hgObject)}</h4>
      <h4 className="w-1/5">
        {hgObject.properties?.orgcharthub_relationship_map_created_at
          ? domain.toShortDate(
              hgObject.properties?.orgcharthub_relationship_map_created_at,
            )
          : null}
      </h4>
      <h4 className="w-1/5">
        {hgObject.properties?.orgcharthub_relationship_map_last_updated_at
          ? domain.toShortDate(
              hgObject.properties?.orgcharthub_relationship_map_last_updated_at,
            )
          : null}
      </h4>
      <div className="w-1/5">
        <Button
          className="float-right"
          variant="contained"
          style={{ textTransform: "none" }}
          onClick={(e) => {
            openMap(hgObject);
          }}
        >
          {hgObject.properties?.orgcharthub_has_relationship_map === "true"
            ? "Open "
            : "Create "}
          Relationship Map
        </Button>
      </div>
    </div>
  );
});

const RelationshipMapPaginationBar: React.FC<{
  pagination: hubspotApi.Pagination;
  setPagination: (value: React.SetStateAction<hubspotApi.Pagination>) => void;
}> = observer((props) => {
  const { pagination, setPagination } = props;
  const numberOfPages = Math.trunc(pagination.total / 20) + 1;
  const currentPage =
    typeof pagination.after !== "undefined"
      ? parseInt(pagination.after) / 20
      : 1;

  const nextPageButton: JSX.Element | null =
    currentPage < numberOfPages ? (
      <span
        className="cursor-pointer"
        onClick={(e) => {
          setPagination({
            ...pagination,
            after:
              typeof pagination.after !== "undefined"
                ? `${parseInt(pagination.after)}`
                : "0",
          });
        }}
      >
        Next
      </span>
    ) : null;

  const prevPageButton: JSX.Element | null =
    currentPage > 1 ? (
      <span
        className="cursor-pointer"
        onClick={(e) => {
          setPagination({
            ...pagination,
            after:
              typeof pagination.after !== "undefined" &&
              parseInt(pagination.after) >= 40
                ? `${parseInt(pagination.after) - 40}`
                : "0",
          });
        }}
      >
        Prev
      </span>
    ) : null;

  return (
    <div className="bg-slate-300 p-6 flex flex-row justify-between items-center">
      <span></span>
      <div className="flex flex-row justify-between items-center">
        <span className="px-2">
          Page {currentPage} of {numberOfPages}
        </span>
        <span className="px-2">{prevPageButton}</span>
        <span className="px-2">{nextPageButton}</span>
      </div>
    </div>
  );
});

export const RelationshipMapSwitcherInner = observer(
  (props: { onClose: () => void }) => {
    const { onClose } = props;

    const store = useStore();

    const [query, setQuery] = useState<{
      companyName: string;
      hasMap: boolean;
    }>({
      companyName: "",
      hasMap: true,
    });
    const actualQuery = useDebounce(query, 500);

    const [sort, setSort] = useState<hubspotApi.Sort>({
      propertyName: "name",
      direction: "ASCENDING",
    });
    const actualSort = useDebounce(sort, 500);

    const [pagination, setPagination] = useState<hubspotApi.Pagination>({
      total: 0,
    });
    const actualPagination = useDebounce(pagination, 500);

    const accessToken = store.auth.accessToken;

    const { data, isError, isLoading } = useQuery(
      [
        "search",
        { actualQuery, actualSort, actualPagination },
        "company",
        accessToken,
      ],
      async () => {
        if (!accessToken) {
          return;
        }

        let filterGroups: hubspotApi.FilterGroup[] = [];

        if (actualQuery.hasMap) {
          filterGroups = [
            {
              filters: [
                {
                  propertyName: "orgcharthub_has_relationship_map",
                  operator: "EQ",
                  value: "true",
                },
              ],
            },
          ];
        } else {
          filterGroups = [
            {
              filters: [
                {
                  propertyName: "orgcharthub_has_relationship_map",
                  operator: "NEQ",
                  value: "true",
                },
              ],
            },
          ];
        }

        const propertiesToFetch = [
          ...computeds.propertiesToFetchForObjectType("company"),
          "orgcharthub_has_relationship_map",
          "orgcharthub_relationship_map_created_at",
          "orgcharthub_relationship_map_last_updated_at",
        ];

        return await hubspotApi.searchObjects({
          authState: { accessToken },
          objectType: "company",
          query: actualQuery.companyName,
          propertiesToFetch,
          filterGroups,
          sorts: [actualSort],
          after: actualPagination.after,
        });
      },
    );

    return (
      <div className="grow flex flex-col">
        <div className="flex-none bg-slate-300 p-6 flex flex-row justify-between items-center">
          <span className="text-slate-800 font-semibold text-lg leading-none">
            Switch Relationship Map
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="grow flex flex-col p-6 space-y-8 overflow-y-scroll">
          <div className="px-2 pt-2 pb-3 bg-slate-100">
            <div className="mb-3">
              <TextField
                autoFocus={true}
                autoComplete="off"
                value={query.companyName}
                onChange={(e) => {
                  setQuery({ ...query, companyName: e.target.value });
                  setPagination({ total: 0 });
                }}
                label={`Search Companies`}
                variant="filled"
                size="small"
                fullWidth={false}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={query.hasMap}
                    onChange={(e) => {
                      setQuery({ ...query, hasMap: e.target.checked });
                      setPagination({ total: 0 });
                    }}
                  />
                }
                label="Has Relationship Map"
              />
            </div>
          </div>

          {typeof data !== "undefined" && !_.isEmpty(data.objects) && (
            <RelationshipMapSwitcherHeaderRow sort={sort} setSort={setSort} />
          )}

          <div className="overflow-y-scroll flex flex-col">
            {isError && <div>Error!</div>}

            {isLoading && !isError && <div className="p-4">Loading...</div>}

            {!_.isEmpty(data) && !isLoading && !isError && (
              <div className="divide-y-2 border-y-2 border-slate-300">
                {_.map(data?.objects, (hgObject) => {
                  return (
                    <RelationMapSwitcherRow
                      key={domain.canonicalIdForHGObjectRef(hgObject)}
                      hgObject={hgObject}
                      openMap={() => {
                        actions.openRelationshipMap(hgObject.objectId);
                      }}
                    />
                  );
                })}
              </div>
            )}

            {typeof data !== "undefined" &&
              _.isEmpty(data.objects) &&
              !isLoading &&
              !isError && (
                <div className="px-4 pb-4">
                  <div className="p-4 rounded-md bg-slate-100 flex items-center justify-center text-slate-700 font-medium text-sm">
                    No results found
                  </div>
                </div>
              )}
          </div>
        </div>

        {typeof data !== "undefined" && !_.isEmpty(data.objects) && (
          <div className="flex-none">
            <RelationshipMapPaginationBar
              pagination={
                typeof data !== "undefined" ? data.pagination : pagination
              }
              setPagination={setPagination}
            />
          </div>
        )}
      </div>
    );
  },
);

export const RelationshipMapSwitcher: React.FC<{}> = observer((props) => {
  const store = useStore();

  const switchingRelationshipMap = store.switchingRelationshipMap;

  const onClose = () => {
    actions.setSwitchingRelationshipMap(false);
  };

  return (
    <Drawer
      anchor="left"
      style={{ zIndex: 9999 }}
      open={switchingRelationshipMap}
      onClose={onClose}
    >
      <div className="h-full flex flex-col w-screen overflow-x-scroll max-w-[80rem]">
        <RelationshipMapSwitcherInner onClose={onClose} />
      </div>
    </Drawer>
  );
});
