import * as ts from "io-ts";

const APIPaging = ts.exact(
  ts.partial({
    next: ts.exact(
      ts.partial({
        after: ts.string,
        link: ts.string,
      }),
    ),
  }),
);

const HubSpotObjectProperties = ts.record(
  ts.string,
  ts.union([ts.string, ts.null]),
);

export const HubSpotAPIObject = ts.strict({
  id: ts.string,
  objectType: ts.string,
  properties: HubSpotObjectProperties,
});
export type HubSpotAPIObject = ts.TypeOf<typeof HubSpotAPIObject>;

export const HubSpotBatchObjectsReadAPIResponse = ts.strict({
  results: ts.array(HubSpotAPIObject),
});
export type HubSpotBatchObjectsReadAPIResponse = ts.TypeOf<
  typeof HubSpotBatchObjectsReadAPIResponse
>;

export const HubSpotObjectsGetAPIResponseAssociationResult = ts.strict({
  id: ts.string,
  type: ts.string,
});
export type HubSpotObjectsGetAPIResponseAssociationResult = ts.TypeOf<
  typeof HubSpotObjectsGetAPIResponseAssociationResult
>;

export const HubSpotObjectsGetAPIResponse = ts.intersection([
  ts.strict({
    properties: HubSpotObjectProperties,
  }),
  ts.exact(
    ts.partial({
      associations: ts.record(
        ts.string,
        ts.union([
          ts.undefined,
          ts.intersection([
            ts.strict({
              results: ts.array(HubSpotObjectsGetAPIResponseAssociationResult),
            }),
            ts.exact(
              ts.partial({
                paging: APIPaging,
              }),
            ),
          ]),
        ]),
      ),
    }),
  ),
]);
export type HubSpotObjectsGetAPIResponse = ts.TypeOf<
  typeof HubSpotObjectsGetAPIResponse
>;

export const HubSpotAPIAssociationLabel = ts.strict({
  category: ts.union([
    ts.literal("HUBSPOT_DEFINED"),
    ts.literal("USER_DEFINED"),
    ts.literal("INTEGRATOR_DEFINED"),
  ]),
  typeId: ts.number,
  label: ts.union([ts.string, ts.null]),
});

export type HubSpotAPIAssociationLabel = ts.TypeOf<
  typeof HubSpotAPIAssociationLabel
>;

export const HubSpotAPIAssociation = ts.strict({
  toObjectId: ts.number,
  associationTypes: ts.array(HubSpotAPIAssociationLabel),
});

export type HubSpotAPIAssociation = ts.TypeOf<typeof HubSpotAPIAssociation>;

export const HubSpotBatchAPIAssociation = ts.strict({
  from: ts.strict({ id: ts.string }),
  to: ts.array(
    ts.strict({
      toObjectId: ts.number,
      associationTypes: ts.array(HubSpotAPIAssociationLabel),
    }),
  ),
});
export type HubSpotBatchAPIAssociation = ts.TypeOf<
  typeof HubSpotBatchAPIAssociation
>;

export const HubSpotAssociationsListAPIResponse = ts.intersection([
  ts.strict({
    results: ts.array(
      ts.strict({
        toObjectId: ts.number,
        associationTypes: ts.array(HubSpotAPIAssociationLabel),
      }),
    ),
  }),
  ts.exact(
    ts.partial({
      paging: APIPaging,
    }),
  ),
]);
export type HubSpotAssociationsListAPIResponse = ts.TypeOf<
  typeof HubSpotAssociationsListAPIResponse
>;

export const HubSpotAssociationsBatchReadAPIResponse = ts.intersection([
  ts.strict({
    results: ts.array(HubSpotBatchAPIAssociation),
  }),
  ts.exact(
    ts.partial({
      paging: APIPaging,
    }),
  ),
]);
export type HubSpotAssociationsBatchReadAPIResponse = ts.TypeOf<
  typeof HubSpotAssociationsBatchReadAPIResponse
>;

export const HubSpotSearchAPIResponse = ts.strict({
  total: ts.number,
  results: ts.array(
    ts.strict({
      id: ts.string,
      properties: ts.record(ts.string, ts.union([ts.string, ts.null])),
    }),
  ),
  // TODO: add paging when we need support for it
});

export const HubSpotReadAllPropertyGroupsAPIResponse = ts.intersection([
  ts.strict({
    results: ts.array(
      ts.strict({
        name: ts.string,
        label: ts.string,
        displayOrder: ts.number,
      }),
    ),
  }),
  ts.exact(
    ts.partial({
      paging: APIPaging,
    }),
  ),
]);
export type HubSpotReadAllPropertyGroupsAPIResponse = ts.TypeOf<
  typeof HubSpotReadAllPropertyGroupsAPIResponse
>;

const HubSpotAPIProperty = ts.intersection([
  ts.strict({
    groupName: ts.string,
    hidden: ts.boolean,
    name: ts.string,
    displayOrder: ts.number,
    label: ts.string,
    type: ts.string,
    fieldType: ts.string,
  }),
  ts.exact(
    ts.partial({
      referencedObjectType: ts.string,
      description: ts.string,
      externalOptions: ts.boolean,
      showCurrencySymbol: ts.boolean,
      options: ts.array(
        ts.intersection([
          ts.strict({
            label: ts.string,
            value: ts.string,
            displayOrder: ts.number,
            hidden: ts.boolean,
          }),
          ts.exact(
            ts.partial({
              description: ts.string,
            }),
          ),
        ]),
      ),
    }),
  ),
]);

export const HubSpotReadAllPropertiesAPIResponse = ts.intersection([
  ts.strict({
    results: ts.array(HubSpotAPIProperty),
  }),
  ts.exact(
    ts.partial({
      paging: APIPaging,
    }),
  ),
]);
export type HubSpotReadAllPropertiesAPIResponse = ts.TypeOf<
  typeof HubSpotReadAllPropertiesAPIResponse
>;

export type HubSpotSearchAPIResponse = ts.TypeOf<
  typeof HubSpotSearchAPIResponse
>;

export const HubSpotOwnersGetAPIResponse = ts.intersection([
  ts.strict({
    id: ts.string,
    userId: ts.number,
  }),
  ts.exact(
    ts.partial({
      firstName: ts.string,
      lastName: ts.string,
      email: ts.string,
    }),
  ),
]);
export type HubSpotOwnersGetAPIResponse = ts.TypeOf<
  typeof HubSpotOwnersGetAPIResponse
>;

export const HubSpotOwnersIndexAPIResponse = ts.intersection([
  ts.strict({
    results: ts.array(
      ts.intersection([
        ts.strict({
          id: ts.string,
        }),
        ts.exact(
          ts.partial({
            email: ts.string,
            firstName: ts.string,
            lastName: ts.string,
            userId: ts.number,
            teams: ts.array(
              ts.strict({
                id: ts.string,
                name: ts.string,
                primary: ts.boolean,
              }),
            ),
          }),
        ),
      ]),
    ),
  }),
  ts.exact(
    ts.partial({
      paging: APIPaging,
    }),
  ),
]);
export type HubSpotOwnersIndexAPIResponse = ts.TypeOf<
  typeof HubSpotOwnersIndexAPIResponse
>;

export const HubSpotGetSchemasResponse = ts.strict({
  results: ts.array(
    ts.strict({
      id: ts.string,
      objectTypeId: ts.string,
      // properties: ts.array(HubSpotAPIProperty),
      // associations: ts.array(
      //   ts.strict({
      //     id: ts.string,
      //     fromObjectTypeId: ts.string,
      //     toObjectTypeId: ts.string,
      //     name: ts.string,
      //   }),
      // ),
      labels: ts.strict({
        singular: ts.string,
        plural: ts.string,
      }),
      primaryDisplayProperty: ts.string,
      secondaryDisplayProperties: ts.array(ts.string),
      searchableProperties: ts.array(ts.string),
      requiredProperties: ts.array(ts.string),
      name: ts.string,
      fullyQualifiedName: ts.string,
    }),
  ),
});
export type HubSpotGetSchemasResponse = ts.TypeOf<
  typeof HubSpotGetSchemasResponse
>;

export const HubSpotPipelinesIndexAPIResponse = ts.strict({
  results: ts.array(
    ts.strict({
      label: ts.string,
      displayOrder: ts.number,
      id: ts.string,
      stages: ts.array(
        ts.strict({
          label: ts.string,
          displayOrder: ts.number,
          id: ts.string,
          metadata: ts.strict({
            probability: ts.string,
          }),
        }),
      ),
    }),
  ),
});
export type HubSpotPipelinesIndexAPIResponse = ts.TypeOf<
  typeof HubSpotPipelinesIndexAPIResponse
>;

export const HubSpotAccountInfoResponse = ts.strict({
  portalId: ts.number,
  companyCurrency: ts.string,
  uiDomain: ts.string,
  timeZone: ts.string,
  utcOffset: ts.string,
  utcOffsetMilliseconds: ts.number,
});
export type HubSpotAccountInfoResponse = ts.TypeOf<
  typeof HubSpotAccountInfoResponse
>;

export type HubSpotAPIAssociationLabelWithFromToObjectTypes =
  HubSpotAPIAssociationLabel & {
    fromObjectType: string;
    toObjectType: string;
    name?: string;
  };
