import _ from "lodash";
import React from "react";
import { canonicalIdForHSProperty, HGObject } from "../../domain";
import { HSProperty, HSPropertyEnumeration } from "../../domain/property";
import { useStore } from "../../hooks/hooks";
import * as computeds from "../../computeds";
import { observer } from "mobx-react-lite";
import {
  resolvePropertyValue,
  optionValueToLabel,
  formatValueForDisplay,
} from "./helpers";
import { hsPropertyCanSelfResolveValues } from "../../domain/resolved-values";
import * as db from "../../store/db";

const PropertyRowLabel = (props: { children: string }) => {
  return (
    <div className="shrink-0 font-normal text-base text-slate-700">
      {props.children}
    </div>
  );
};

const PropertyRowNull: React.FC<{
  hsProperty: HSProperty;
  value: null;
}> = (props) => {
  const { hsProperty } = props;

  return (
    <div className="flex flex-col space-y-1">
      <PropertyRowLabel>{hsProperty.label}</PropertyRowLabel>
    </div>
  );
};

const PropertyRowDefault: React.FC<{
  hsProperty: HSProperty;
  hgObject: HGObject;
  value: string;
}> = (props) => {
  const { hsProperty, hgObject, value } = props;

  const store = useStore();

  const resolvedValue = resolvePropertyValue({
    store,
    hsProperty,
    hgObject,
    value: value,
  });

  const formattedValue = formatValueForDisplay({
    hgAccountDetails: db.getAccountDetails(store),
    hsProperty,
    hgObject,
    value: resolvedValue,
  });

  return (
    <div className="flex flex-row space-x-2 items-center">
      <div className="shrink-0 font-normal text-base text-slate-700">
        {hsProperty.label}
      </div>
      <div className="grow truncate hg-privacy blur text-base text-slate-800">
        {formattedValue}
      </div>
    </div>
  );
};

const PropertyRowEnumeration: React.FC<{
  hsProperty: HSPropertyEnumeration;
  hgObject: HGObject;
  value: string;
}> = observer((props) => {
  const { hsProperty, hgObject, value } = props;

  const store = useStore();

  const values = value.split(";");

  let optionsForRender: { label: string; value: string }[] = [];
  if (hsPropertyCanSelfResolveValues(hsProperty)) {
    optionsForRender = _.map(values, (optionValue) => {
      const valueLabel = optionValueToLabel({
        hsProperty,
        value: optionValue,
      });
      return { label: valueLabel || value, value: optionValue };
    });
  } else {
    optionsForRender = _.map(values, (optionValue) => {
      const valueLabel = resolvePropertyValue({
        store,
        hsProperty,
        hgObject,
        value: optionValue,
      });
      return {
        label: valueLabel,
        value: optionValue,
      };
    });
  }

  const Tag = (props: { label: string }) => {
    return (
      <div className="px-2 py-1 bg-slate-200 rounded-md flex items-center justify-center hg-privacy blur">
        {props.label}
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-1">
      <div className="shrink-0 text-base leading-normal font-normal text-slate-700">
        {hsProperty.label}
      </div>
      <div className="grow flex flex-col space-y-1 items-start text-base leading-normal font-normal text-slate-800">
        {_.map(optionsForRender, (option) => {
          return <Tag key={option.value} label={option.label} />;
        })}
      </div>
    </div>
  );
});

const PropertyRowDateOrDateTime: React.FC<{
  hsProperty: HSProperty;
  hgObject: HGObject;
  value: string;
}> = observer((props) => {
  const { hsProperty, hgObject, value } = props;

  const store = useStore();

  const formattedValue = formatValueForDisplay({
    hgAccountDetails: db.getAccountDetails(store),
    hsProperty,
    hgObject,
    value,
  });

  return (
    <div className="flex flex-row space-x-2 items-center">
      <div className="shrink-0 text-base leading-normal font-normal text-slate-700">
        {hsProperty.label}
      </div>
      <div className="grow truncate hg-privacy blur text-base leading-normal text-slate-800">
        {formattedValue}
      </div>
    </div>
  );
});

export const PropertyRow: React.FC<{
  hgObject: HGObject;
  propertyName: string;
  propertyValue: string | null;
}> = (props) => {
  const { hgObject, propertyName, propertyValue } = props;

  const store = useStore();

  const hsProperty = store.hgProperties[
    canonicalIdForHSProperty({
      objectType: hgObject.objectType,
      name: propertyName,
    })
  ] as HSProperty | undefined;

  if (typeof hsProperty === "undefined") {
    return <div>can't find property: {propertyName}</div>;
  }

  let reactNode: React.ReactNode;

  if (propertyValue === null) {
    reactNode = (
      <PropertyRowNull hsProperty={hsProperty} value={propertyValue} />
    );
  } else {
    switch (hsProperty.type) {
      case "datetime": {
        reactNode = (
          <PropertyRowDateOrDateTime
            hsProperty={hsProperty}
            hgObject={hgObject}
            value={propertyValue}
          />
        );
        break;
      }

      case "date": {
        reactNode = (
          <PropertyRowDateOrDateTime
            hsProperty={hsProperty}
            hgObject={hgObject}
            value={propertyValue}
          />
        );
        break;
      }

      case "enumeration": {
        reactNode = (
          <PropertyRowEnumeration
            hsProperty={hsProperty}
            hgObject={hgObject}
            value={propertyValue}
          />
        );
        break;
      }

      default: {
        reactNode = (
          <PropertyRowDefault
            hsProperty={hsProperty}
            hgObject={hgObject}
            value={propertyValue}
          />
        );
      }
    }
  }

  return <div className="px-4 py-2">{reactNode}</div>;
};

export const PropertyRows: React.FC<{
  hgObject: HGObject;
}> = observer((props) => {
  const { hgObject } = props;

  const displayProperties = computeds.portalDisplayPropertiesOnCard(
    hgObject.objectType,
  );

  return (
    <div className="divide-y divide-slate-200">
      {_.map(displayProperties, (displayProperty) => {
        const value = hgObject.properties?.[displayProperty.name] || null;

        if (value === null) {
          return null;
        }

        return (
          <PropertyRow
            key={displayProperty.name}
            hgObject={hgObject}
            propertyName={displayProperty.name}
            propertyValue={value}
          />
        );
      })}
    </div>
  );
});
