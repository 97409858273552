import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import { makeAutoObservable, runInAction } from "mobx";
import { INITIAL_URL_STATE } from "../config";
import * as domain from "../domain";
import { makeNewInitialDocument } from "../domain/document";
import _ from "lodash";
import shortid from "shortid";
import { makeHubSpotAssociationsSyncQueue } from "../hubspot-associations-sync-queue";
import * as data from "../data";
import type { HGStore, HGApp } from "./types";

const store: HGStore = {
  id: shortid(),
  initialized: false,
  initializedEnoughForMap: false,
  loadingMultiplayer: false,

  devMenuVisible: false,

  privacyMode: false,

  user: undefined,

  cardSizeCache: {},

  currentRelationshipMap: { canUndo: false, canRedo: false },

  currentPoint: [0, 0, 0],

  auth: {
    queryToken: INITIAL_URL_STATE.queryToken,
    authCode: INITIAL_URL_STATE.authCode,
    userId: INITIAL_URL_STATE.userId,
    impersonation: INITIAL_URL_STATE.impersonation,
    scopes: [],
  },

  hgProperties: {},
  hgPropertyGroups: {},

  hgObjects: {},

  // new connection model
  hgConnections: {},
  hgLabelPairs: {},
  // end new connection model

  // subscription definitions
  hgObjectRefSubscriptions: {},
  valueResolvingSubscriptions: {},

  customObjectsSupported: false,
  hgObjectSchemas: {},

  hgObjectsFetchingAssociations: {},

  portalId: INITIAL_URL_STATE.portalId,
  userEmail: INITIAL_URL_STATE.userEmail,

  portal: undefined,
  hgAccountDetails: undefined,

  initialObject: {
    objectType: INITIAL_URL_STATE.objectType,
    objectId: INITIAL_URL_STATE.objectId,
  },

  focusedObject: undefined,

  displayPropertiesConfigSession: undefined,

  documentForceUpdate: 0,
  documentNotifyUpdate: 0,
  // document: initialDocument,

  draftAddingObjectToChart: undefined,
  draftAddingAssociatedToChart: undefined,
  connectionEditingSession: undefined,

  switchingRelationshipMap: false,
};

let hsAssociationsSyncQueue = makeHubSpotAssociationsSyncQueue({
  existingLabelPairsFn: () => {
    return Object.values(store.hgLabelPairs);
  },
  getAuthStateFn: () => {
    return store.auth.accessToken
      ? {
          accessToken: store.auth.accessToken,
        }
      : undefined;
  },
  propertiesToFetchForObjectTypesFn: () => {
    return domain.calculatePropertiesToFetchForAllObjectTypes({
      displayProperties: app.store.portal?.["hg-display-properties"] || [],
      hgObjectSchemas: Object.values(app.store.hgObjectSchemas),
    });
  },
  existingHGSchemasFn: () => {
    return Object.values(store.hgObjectSchemas);
  },
});

export const app: HGApp = {
  id: shortid(),
  store: store,
  tlApp: new TDDraw.TldrawApp(),
  hsAssociationsSyncQueue,
};

app.tlApp.onPointerMoveEvent = (e) => {
  // console.log("pointer move", e);
  runInAction(() => {
    store.currentPoint = e.currentPoint;
  });
};

app.tlApp.setSetting("showBindingHandles", false);
app.tlApp.setSetting("showCloneHandles", false);
app.tlApp.setSetting("showGrid", true);

app.tlApp.loadDocument(makeNewInitialDocument("initial_document"));
makeAutoObservable(app.store);

// for debugging
// @ts-ignore
window.hgApp = app;

// stateful
let dataReactionsDispose = data.createReactions({ store });
if (import.meta.hot) {
  import.meta.hot.on("vite:beforeUpdate", (e) =>
    console.log("before update mobx-store 1", e),
  );

  import.meta.hot.accept("/src/data.ts", (newModule) => {
    console.log("accepting data.ts", newModule);
    if (newModule) {
      dataReactionsDispose();
      dataReactionsDispose = newModule.createReactions({ store });
    }
  });

  import.meta.hot.accept(
    "/src/hubspot-associations-sync-queue.ts",
    (newModule) => {
      console.log("accepting hubspot-associations-sync-queue.ts", newModule);
      hsAssociationsSyncQueue = makeHubSpotAssociationsSyncQueue({
        existingLabelPairsFn: () => {
          return Object.values(store.hgLabelPairs);
        },
        getAuthStateFn: () => {
          return store.auth.accessToken
            ? {
                accessToken: store.auth.accessToken,
              }
            : undefined;
        },
        propertiesToFetchForObjectTypesFn: () => {
          return domain.calculatePropertiesToFetchForAllObjectTypes({
            displayProperties:
              app.store.portal?.["hg-display-properties"] || [],
            hgObjectSchemas: Object.values(app.store.hgObjectSchemas),
          });
        },
        existingHGSchemasFn: () => {
          return Object.values(store.hgObjectSchemas);
        },
      });
    },
  );
}

console.log(
  `mobx-store ${shortid()}`,
  _.cloneDeep(app.tlApp.document.pages.page_1.shapes),
);
